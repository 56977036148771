import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useEffect, useState} from "react";
import labels from "../../constants/Coupon";
import configuration from '../../config';
import { toast } from 'react-toastify';
import {each} from 'underscore';

// For Edit State
let Id = "";
function Edit({history}) {
  const [languages, setLanguages] = useState([])
  const [cityOption, setCityOption] = useState([])
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const typeOpt = [
    { id: 1, title: "Percentage", value: "percentage" },
    { id: 2, title: "Amount", value: "amount" },
  ];
  const [fieldArrData, setFieldArrData] = useState([])
  const fieldArr = [
    { id: 21, title: "Discount Type", name: "discount_type", type: "Dropdown", options: typeOpt },
    { id: 22, title: "Value (% OR ₹)", name: "value", type: "TextInput", is_number:true },
    { id: 20, title: "Coupon Code", name: "coupon_code", type: "CopyInput", is_read_only:true },
    { id: 23, title: "Total Number of Usage", name: "total_usage", type: "TextInput", is_number:true },
    { id: 24, title: "From  Date", name: "start_date", type: "DatePicker", disablePast:true },
    { id: 25, title: "To Date", name: "end_date", type: "DatePicker", disablePast:true },
    { id: 26, title: "Minimum Order Amount Value", name: "minimum_amount_value", type: "TextInput", is_number:true },
    { id: 27, title: "Maximum Coupon Discount Value", name: "maximum_discount_value", type: "TextInput", is_number:true },
    { id: 29, title: "One Time Use", name: "is_one_time_use", type: "SwitchInput" },
    { id: 28, title: "Status", name: "status", type: "Dropdown", options: statusOpt },
    {
      id: 4,
      title: "Apply Cities",
      name: "city_id",
      type: "MultiSelect",
      options: cityOption,
    }
  ];
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [fields, setFields] = useState({
    start_date:null,
    end_date:null,
    city_id:[]
  });
  function handleValidation() {
    let flag = true;
    let error = {}
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
      if (!fields['desc_' + lang.code]) {
        error['desc_' + lang.code] = "Please enter description in " + lang.title
        flag = false
      }
      if (!fields['msg_' + lang.code]) {
        error['msg_' + lang.code] = "Please enter message in " + lang.title
        flag = false
      }
    })
    if(!fields["coupon_code"]){
        flag = false;
        error["coupon_code"] = "Please enter coupon code.";
    }
    if(!fields["total_usage"]){
        flag = false;
        error["total_usage"] = "Please enter total usage.";
    }
    if (fields["total_usage"] < 1) {
        flag = false;
        error["total_usage"] = "Please enter total number of usage(only positive number allowed).";
    }
    if(!fields['minimum_amount_value']){
        flag = false;
        error["minimum_amount_value"] = "Please enter minimum amount value.";
    }
    if(fields['minimum_amount_value'] < 5){
        flag = false;
        error["minimum_amount_value"] = "Please enter minimum amount value greater then four.";
    }
    if(fields["discount_type"] == 'percentage'){
        if(!fields['maximum_discount_value']){
            flag = false;
            error["maximum_discount_value"] = "Please enter maximum discount value.";
        }
        if (fields["maximum_discount_value"] < 1) {
            flag = false;
            error["maximum_discount_value"] = "Please enter maximum discount value(only positive number allowed).";
        }
    }
    if(!fields["discount_type"]){
        flag = false;
        error["discount_type"] = "Please select discount type.";
    }
    if(!fields["value"]){
        flag = false;
        error["value"] = "Please enter discount value.";
    }
    if (fields["value"] < 1) {
        flag = false;
        error["value"] = "Please enter value of discount(only positive number allowed).";
    }
    if(!fields["status"]){
        flag = false;
        error["status"] = "Please select status.";
    }
    if(!fields["start_date"]){
        flag = false;
        error["start_date"] = "Please select start date.";
    }
    if(!fields["end_date"]){
        flag = false;
        error["end_date"] = "Please select end date.";
    }
    if(fields['start_date'] > fields['end_date']){
      flag = false;
      error["end_date"] = "Select End Time is Greater then Start Time.";
    }
    if(fields["city_id"].length <= 0){
      flag = false;
      error["city_id"] = "Please select city.";
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'coupon/update', params:fields}).then((data) => {
        if(data.status === 200){
          history.push('/coupon')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handleChange(field, e) {
    if(field === 'start_date' || field === 'end_date' || field === 'is_one_time_use' || field === 'city_id'){
      setFields({ ...fields, [field]: e })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }
  useEffect(() => {
    // console.log(props.location)
    configuration
      .getAPI({ url: "city/list", params: {status:'active'} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.label = `${element.name} - ${element.state_name} - ${element.country_name}`;
              element.id = element.city_id;
              return element;
            });
          }
          setCityOption(data.payload)
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let languages = data.payload;
        let fieldArr_data = [];
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 3 + index,
            title: `Title [${language.title}]`,
            name: `title_${language.code}`,
            type: "TextInput"
          });
        })
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 6 + index,
            title: `Coupon Description [${language.title}]`,
            name: `desc_${language.code}`,
            type: "TextInput"
          });
        })
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 8 + index,
            title: `Coupon Message [${language.title}]`,
            name: `msg_${language.code}`,
            type: "TextInput"
          });
        })
        // setFieldArr([...fieldArr_data, ...fieldArr])
        setFieldArrData(fieldArr_data)
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({url:'coupon/list', params:{coupon_id:Id}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setFields(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <>
      <Breadcrumb module={labels.editCoupon} />
      <AddEditSection title="Edit Coupon" path="/coupon" fields={[...fieldArrData, ...fieldArr]} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
