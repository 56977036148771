import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import React, {useEffect, useState} from "react";
import labels from "../../../constants/RentalPackage";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import {each} from 'underscore';

// For Edit State
let Id = "";
function Edit({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [languages, setLanguages] = useState([])
  const [fieldArr, setFieldArr] = useState([
    { id: 2, title: "Enter Hours ", name: "hr", type: "TextInput", is_number:true},
    { id: 3, title: "Enter KM ", name: "km", type: "TextInput", is_number:true},
    {
      id: 4,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    }, 
  ]);
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [fields, setFields] = useState({});
  function handleValidation() {
    let flag = true;
    let error = {}
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })
    if (!fields.hr) {
      error['hr'] = "Please enter hours"
      flag = false
    } else if(fields.hr <= 0) {
      error['hr'] = "Please enter valid hours"
      flag = false
    }

    if (!fields.km) {
      error['km'] = "Please enter kilometer"
      flag = false
    } else if(fields.km <= 0) {
      error['km'] = "Please enter valid kilometer"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'rental-package/update', params:fields}).then((data) => {
        if(data.status === 200){
          history.push('/rental-package')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }
  useEffect(() => {
    // console.log(props.location)
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let languages = data.payload;
        let fieldArr_data = [];
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 3 + index,
            title: `Title [${language.title}]`,
            name: `title_${language.code}`,
            type: "TextInput"
          });
        })
        setFieldArr([...fieldArr_data, ...fieldArr])
      }
    }).catch(error => {
      return toast.error(error.message)
    });

    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({url:'rental-package/list', params:{rental_package_id:Id}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setFields(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    // console.log(configuration.baseURL);
  }, []);
  return (
    <>
      <Breadcrumb module={labels.editFeature} />
      <AddEditSection title="Edit Rental Package" path="/rental-package" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
