import PropTypes from "prop-types";
import React from "react";

// For Text Area Element
function TextArea({ name, handleChange, value }) {
  return (
    <textarea
      id={name}
      name={name}
      value={value}
      onChange={(e)=>handleChange(name, e)}
      rows={3}
      className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseborder border-gray-300 rounded-md input_theme"
      defaultValue={""}
    />
  );
}

TextArea.propTypes = {
  name: PropTypes.string,
};

TextArea.defaultProps = {
  name: "",
};

export default TextArea;
