import React, { useEffect, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

let map;
let bounds = new window.google.maps.LatLngBounds();
let sub_area;
let coordinates = [];
let color = ['#b9201b'];

function Map({ name, handleChange, value }) {
    console.log("value at start of component", value)

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         options: [],
    //         selectedOptions: []
    //     }
    //     this._handleSearch = this._handleSearch.bind(this);
    //     this.renderCoordinate = this.renderCoordinate.bind(this);

    // }

    const [options, setOptions] = useState([])

    useEffect(() => {
        _initMap()
        console.log(value)
        if (value) {
            console.log()
            console.log("value", value)
            // renderCoordinate(value)
            renderToMaps(value)
        }
    }, [renderToMaps, value])
    // componentDidMount() {
    // }

    function _initMap() {
        map = new window.google.maps.Map(document.getElementById('map'), {
            center: { lat: 23.0225, lng: 72.5714 },
            zoom: 11,
            zoomControl: true,
            zoomControlOptions: {
                position: window.google.maps.ControlPosition.RIGHT_CENTER
            },
            scrollwheel: false,
            streetViewControl: false,
            mapTypeControl: false,
            mapTypeId: 'roadmap',
        });
    }

    function _handleSearch(query) {
        if (!query) {
            return;
        }
        fetch(`https://nominatim.openstreetmap.org/search.php?q=${query}&polygon_geojson=1&format=json`)
            .then(resp => resp.json())
            .then(data => {
                console.log()
                let filterGeoJsonType = data.filter(function (data) {
                    return data.geojson.type === "MultiPolygon" || data.geojson.type === "Polygon"
                });
                // this.setState({ options: filterGeoJsonType });
                setOptions(filterGeoJsonType)
                // setOptions(data)
            });
    }

    function renderCoordinate(paths) {
        console.log("paths", paths)
        coordinates = [];
        let position = 0;
        paths.map((location) => {
            if (position % 10 === 0) {
                coordinates.push({ "lat": location[1], "lng": location[0] });
                bounds.extend({ "lat": location[1], "lng": location[0] });
            }
            position++
            return true;
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    function renderToMaps(selectedOptions) {
        selectedOptions.forEach((option) => {
            console.log("option")
            console.log(option)
            if (option.geojson.type === "MultiPolygon") {
                renderCoordinate(option.geojson.coordinates[0][0]);
            } else if (option.geojson.type === "Polygon") {
                renderCoordinate(option.geojson.coordinates[0]);
            } else {
                alert('option.geojson.type: MultiPolygon & Polygon');
            }

            if (coordinates.length > 1) {
                sub_area = new window.google.maps.Polygon({
                    paths: coordinates,
                    strokeColor: color[0],
                    strokeOpacity: 1,
                    strokeWeight: 5,
                    fillColor: color[0],
                    fillOpacity: 0.5,
                    editable: true
                });

                sub_area.setMap(map);
                map.setOptions({ maxZoom: 15 });
                map.fitBounds(bounds);

                coordinates = [];
            }
        })
    }

    function _handleChange(option) {
        _initMap()
        // renderToMaps(option)
        handleChange(name, option)
    }

    // render() {
    return (
        <>
            <div className="container" style={{ height: `100%` }}>
                <AsyncTypeahead
                    isLoading={false}
                    id={name}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-base border-gray-300 rounded-md input_theme z-10"
                    align="justify"
                    multiple
                    labelKey="display_name"
                    onSearch={(query) => _handleSearch(query)}
                    onChange={(option) => _handleChange(option)}
                    options={options}
                    placeholder="Search city, ex: Ahmedabad or Mumbai"
                    renderMenuItemChildren={(option, props, index) => (
                        <div>
                            <span>{option.display_name}</span>
                        </div>
                    )} />
                <div className="maps" id="map"></div>
            </div>
        </>
    );
    // }
}

export default Map;
