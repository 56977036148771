import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import configuration from '../../config';
import database from '../../firebase';
import { onValue, ref, orderByChild, equalTo, query, child, get } from "firebase/database";
import { getAuth, signInAnonymously } from "firebase/auth";
import React, { useState, useRef, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import { toast } from "react-toastify";

const MapContainer = ({ google }) => {
  let defaultProps = {
    center: {
      lat: 26.163987369921628,
      lng: 91.7152404339864
    },
    zoom: 12,

  };
  const [drivers, setDrivers] = useState([]);
  const reference = useRef(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [intervalData, setIntervalData] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const notificationMethods = [
    { id: 'all', title: 'All Pilots' },
    { id: 'online', title: 'Online Pilots' },
    { id: 'offline', title: 'Offline Pilots' },
    { id: 'ongoing', title: 'Ongoing Pilots' },
  ]

  function OnMarkerClick(e, marker, element) {
    console.log(element.driver_id)
    // setSelectedElement(element);
    // setActiveMarker(marker);
    configuration
      .getAPI({ url: "passenger/detail", params: { user_id: element.driver_id } })
      .then((data) => {
        if (data.status === 200) {
          setSelectedElement(data.payload);
          setActiveMarker(marker);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  };
  useEffect(() => {
    const auth = getAuth();
    signInAnonymously(auth)
      .then((userCredential) => {
        setDriverInterval('online')
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
  }, []);

  function setDriverInterval(type) {
    if(type === 'ongoing'){
      configuration
        .getAPI({ url: "driver/ongoing", params: {} })
        .then((data) => {
          if (data.status === 200) {
            setIntervalData(setInterval(() => {
              getOngoingDriver(data.payload)
            }, 3000))
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    } else {
      setIntervalData(setInterval(() => {
        getLiveData(type)
      }, 3000))
    }
  }
  function getLiveData(type) {
    let recentPostsRef = ref(database, "drivers");
    if (type === 'online' || type === 'offline') {
      recentPostsRef = query(ref(database, 'drivers'), orderByChild('status'), equalTo(type));
    }
    return onValue(recentPostsRef, (snapshot) => {
      // console.log(snapshot)
      let finalData = [];
      const markers = snapshot.val();
      if (snapshot.exists()) {
        for (let a in markers) {
          let data = markers[a];
          if (data.status && data.latitude && data.longitude && data.name && data.vehicle_type) {
            let innerObj = {
              driver_id: a,
              lat: parseFloat(data.latitude),
              lng: parseFloat(data.longitude),
              status: data.status,
              name: data.name,
              vehicle_type: data.vehicle_type
            };
            finalData.push(innerObj);
          }
        }
        setDrivers(finalData)
        // console.log(finalData)
      }
    }, {
      onlyOnce: true
    });
  }
  function getOngoingDriver(keys) {
    var promises = keys.map(function(key) {
      let recentPostsRef = ref(database, "drivers");
      return get(child(recentPostsRef, `${key}`)).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          let innerObj = {
            driver_id: key,
            lat: parseFloat(data.latitude),
            lng: parseFloat(data.longitude),
            status: data.status,
            name: data.name,
            vehicle_type: data.vehicle_type
          };
          return innerObj;
        } else {
          // console.log("No data available");
        }
      }).catch((error) => {
        // console.error(error);
      });
    });
    Promise.all(promises).then(function(snapshots) {
      setDrivers(snapshots)
    });
  }
  function changeDriver(e, type) {
    clearInterval(intervalData);
    setDriverInterval(type)
  }
  return (
    <div className="bg-gray-50 flex flex-col justify-center">
      <div className="grid grid-cols-1 md:grid-cols-1 ">
        <div className=" py-4 px-4 shadow-lg bg-white" >
          <div>
            <fieldset className="mt-4">
              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                {notificationMethods.map((a) => (
                  <div key={a.id} className="flex items-center">
                    <input
                      id={a.id}
                      name="notification-method"
                      type="radio"
                      onClick={(e) => changeDriver(e, a.id)}
                      defaultChecked={a.id === 'online'}
                      className="focus:ring-indigo-600 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor={a.id} className="ml-3 block text-basefont-medium text-gray-700">
                      {a.title}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>

        </div>
        <div className="relative h-screen w-full">
          <Map
            google={google}
            initialCenter={{
              lat: defaultProps.center.lat,
              lng: defaultProps.center.lng
            }}
            zoom={defaultProps.zoom}
          >
            {/* set markers for orders */}
            {drivers?.map((element, index) => {
              return (
                <Marker
                  key={index}
                  ref={reference}
                  title={element.name}
                  position={{
                    lat: element?.lat,
                    lng: element?.lng
                  }}
                  icon={{
                    url: (element.status === 'online') ? (element.vehicle_type === 'bike') ? "image/onBike.png" : "image/onpin.png" : (element.vehicle_type === 'bike') ? "image/offBike.png" : "image/offpin.png",
                    anchor: new google.maps.Point(30, 45),
                    scaledSize: new google.maps.Size(30, 45)
                  }}
                  onClick={(props, marker) => OnMarkerClick(props, marker, element)}
                />
              );
            })}

            <InfoWindow
              visible={true}
              marker={activeMarker}
              onCloseClick={() => {
                setSelectedElement(null);
              }}
            >
              <div style={{ minWidth: '250px' }}>
                <div className="tooltip-hd tooltip_red">{selectedElement?.first_name} {selectedElement?.last_name}</div>
                <div>
                  <p style={{ paddingBottom: '5px' }}>DriverID : {selectedElement?.user_id}</p>
                  <p style={{ paddingBottom: '5px' }}>Email : {selectedElement?.email}</p>
                  <p style={{ paddingBottom: '5px' }}>Mobile : {selectedElement?.mobile_country_code}{selectedElement?.mobile}</p>
                </div>
                {/* <div className="tooltip_row">
                  <span>Luggage : {selectedElement?.luggage}</span>
                </div>
                <div className="tooltip-hd tooltip_green">Available Seats : {selectedElement?.available_seats}</div>
                <div className="tooltip-hd">Vehicle Detail</div>
                <div className="tooltip_row">
                  <span>{selectedElement?.ride_option}</span>
                  <span><strong>{selectedElement?.plat_no}</strong></span>
                  <span>{selectedElement?.vehicle}</span>
                </div> */}
              </div>
            </InfoWindow>
          </Map>
        </div>
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: configuration.google_key
})(MapContainer);
