import React from "react";
import TrackerMenu from "../parts/TrackerMenu";
import PropTypes from "prop-types";
import 'react-toastify/dist/ReactToastify.css';
import { Route } from "react-router-dom";

// Route For Tracker
function TrackerRoute({ component: Component, ...rest }) {
  return (
    <>
      <TrackerMenu />
      <Route {...rest} render={(props) => <Component {...props} />} />
    </>
  );
}
TrackerRoute.propTypes = {
  component: PropTypes.string,
};
TrackerRoute.defaultProps = {
  component: "",
};

export default TrackerRoute;
