import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../parts/Breadcrumb";
import _ from "underscore";
import labels from "../../../constants/Setting";
import Label from "../../../components/Form/Label";
import { Link } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { findWhere, union } from 'underscore';

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// List Settings
const Tabs = ({ color }) => {
  const [currTime, setCurrTime] = useState(new Date());
  const [tab, setTab] = useState(0)
  const [timeSlots, setTimeSlots] = useState([])
  const [fields, setFields] = useState({
    driver_earning_type:'trip'
  })
  const [errors, setErrors] = useState(0)
  function getTimeSlots(type){
    let sendData = {status:'active', type}
    configuration.getAPI({url:'commission/slots', params:sendData}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setTimeSlots(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  useEffect(() => {
    console.log('TimeSlot')
    getTimeSlots('trip')
  }, []);
  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }
  function handleChangeWeek(id, day, e) {
    console.log(day)
    setOpenTab(id);
  }
  const [openTab, setOpenTab] = React.useState(1);
  const handleSubmit = () => {
  }


  return (
    <>
      <div className="px-6 sm:py-10 col-span-10">
        <div className="pb-7">
          <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Time Slot Wise</h2>
          <p className="mt-1 text-base text-gray-500 text_theme">
            This information will be displayed publicly so be careful what you share.
          </p>
        </div>
        <form action="#" method="POST">
          <div className="step px-4 py-4 shadow-md mt-5 bg-gray-50 rounded-md">
            <div className="">
              <label htmlFor="app_name" className="block text-base font-medium text-gray-700 text_theme">
                Type
              </label>
              <select
                id="country3"
                name="country3"
                value={fields.default_country3_id}
                onChange={(e) => handleChange('default_country3_id', e)}
                autoComplete="country3-name"
                className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
              >
                <option value="Trip">Trip Wise</option>
                <option value="Per KM">Per KM </option>
              </select>
              {errors.app_name ? <Label title='app_name' fieldError={errors.app_name} /> : null}
            </div>
            <ul
              className="flex mb-0 list-none flex-wrap pt-3 flex-row border-b border-gray-200 font-medium"
              role="tablist"
            >
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center ">
                <a
                  className={
                    "text-lg text-base px-5 py-3 block " +
                    (openTab === 1
                      ? "text-red-600 border-b-2 border-red-600 bg-" + color + "-600"
                      : "text-" + color + "-600")
                  }
                  onClick={e => handleChangeWeek(1, 'monday', e)}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Monday
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-lg text-base px-5 py-3 block " +
                    (openTab === 2
                      ? "text-red-600 border-b-2 border-red-600 bg-" + color + "-600"
                      : "text-" + color + "-600")
                  }
                  onClick={e => handleChangeWeek(2, 'tuesday', e)}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Tuesday
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-lg text-base px-5 py-3 block " +
                    (openTab === 3
                      ? "text-red-600 border-b-2 border-red-600 bg-" + color + "-600"
                      : "text-" + color + "-600")
                  }
                  onClick={e => handleChangeWeek(3, 'wednesday', e)}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Wednesday
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-lg text-base px-5 py-3 block " +
                    (openTab === 4
                      ? "text-red-600 border-b-2 border-red-600 bg-" + color + "-600"
                      : "text-" + color + "-600")
                  }
                  onClick={e => handleChangeWeek(4, 'thursday', e)}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Thursday
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-lg text-base px-5 py-3 block " +
                    (openTab === 5
                      ? "text-red-600 border-b-2 border-red-600 bg-" + color + "-600"
                      : "text-" + color + "-600")
                  }
                  onClick={e => handleChangeWeek(5, 'friday', e)}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Friday
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-lg text-base px-5 py-3 block " +
                    (openTab === 6
                      ? "text-red-600 border-b-2 border-red-600 bg-" + color + "-600"
                      : "text-" + color + "-600")
                  }
                  onClick={e => handleChangeWeek(6, 'saturday', e)}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Saturday
                </a>
              </li>
              <li className="-mb-px mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-lg text-base px-5 py-3 block " +
                    (openTab === 7
                      ? "text-red-600 border-b-2 border-red-600 bg-" + color + "-600"
                      : "text-" + color + "-600")
                  }
                  onClick={e => handleChangeWeek(7, 'sunday', e)}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Sunday
                </a>
              </li>
            </ul>

            <div className={openTab === 1 ? "block" : "hidden"} id="link1">
              <div className='ride mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Ride
                    </label>
                  </div>
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='Rental mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Rental
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='outstation mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Outstation
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
            <div className={openTab === 2 ? "block" : "hidden"} id="link1">
              <div className='ride mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Ride
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='Rental mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Rental
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='outstation mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Outstation
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
            <div className={openTab === 3 ? "block" : "hidden"} id="link1">
              <div className='ride mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Ride
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='Rental mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Rental
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='outstation mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Outstation
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
            <div className={openTab === 4 ? "block" : "hidden"} id="link1">
              <div className='ride mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Ride
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='Rental mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Rental
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='outstation mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Outstation
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
            <div className={openTab === 5 ? "block" : "hidden"} id="link1">
              <div className='ride mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Ride
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='Rental mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Rental
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='outstation mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Outstation
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
            <div className={openTab === 6 ? "block" : "hidden"} id="link1">
              <div className='ride mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Ride
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='Rental mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Rental
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='outstation mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Outstation
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>
            <div className={openTab === 7 ? "block" : "hidden"} id="link1">
              <div className='ride mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Ride
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='Rental mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Rental
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
              <div className='outstation mb-5 bg-gray-100 px-4 py-4 mt-5 rounded-md'>
                <div className="grid grid-cols-2 gap-6">
                  <div className="col-span-2">
                    <label htmlFor="app_name" className="block text-xl font-medium text-gray-900 text_theme">
                      Outstation
                    </label>
                  </div>
                  
                  
                </div>
                <div className="pt-0  grid md:grid-cols-2 grid-cols-1 gap-4 mt-5">
                  {timeSlots.map((slot, index)=>{
                    return(<div className='lg:flex'>
                      <div className='lg:w-80 w-full'>
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Time Slot
                        </p>
                        <div class="timeslot_check">
                          <label><input type="checkbox" value="TMS1578567286XJM58678" /><span>{slot.title}</span></label>
                        </div>
                      </div>
                      <div className="w-full">
                        <p htmlFor="copy_right_text" className="block w-full text-base font-medium text-gray-700 text_theme">
                          Commisions Value (%)
                        </p>
                        <input
                          type="text"
                          name="copy_right_text"
                          id="copy_right_text"
                          value=''
                          onChange={(e) => handleChange('copy_right_text', e)}
                          autoComplete="copy_right_text"
                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                        />
                        {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                      </div>
                    </div>)
                  })}
                </div>
              </div>
            </div>

          </div>



          <div className="pt-5 mb-5">
            <div className="flex justify-end">
              {/* <button
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Cancel
                </button> */}
              <button
                type="button"
                onClick={(e) => handleSubmit(e)}
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}


export default function TabsRender() {
  return (
    <>
      <Tabs color="white" />
    </>
  );
}