import React, { useEffect, useState } from 'react';
// import ReactMapboxGl from "react-mapbox-gl";
// import DrawControl from "react-mapbox-gl-draw";
// import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

// let mapRef;
// const Map = ReactMapboxGl({
//   accessToken:
//     "pk.eyJ1IjoiZmFrZXVzZXJnaXRodWIiLCJhIjoiY2pwOGlneGI4MDNnaDN1c2J0eW5zb2ZiNyJ9.mALv0tCpbYUPtzT7YysA2g"
// });

function MapData({ mapRef, mapDidLoad, onDrawCreate, onDrawUpdate, onDrawDelete }) {
    const drawControlRef = React.createRef();
    let controls={
        polygon:true,
        trash:true
    }
    return (
        <>
        
        {/* <Map
            style="mapbox://styles/mapbox/streets-v9" // eslint-disable-line
            containerStyle={{
                height: "75vh",
                //width: "75vw",
            }}
            ref={ mapRef }
            movingMethod="flyTo"
            id="map"
            //center={this.state.mapConfig}
            onStyleLoad={mapDidLoad}
        >  
            <DrawControl
                position="top-left"
                onDrawCreate={onDrawCreate}
                onDrawUpdate={onDrawUpdate}
                onDrawDelete={onDrawDelete}
                displayControlsDefault={false}
                ref={drawControlRef}
                controls={controls}
                //styles={styles}
            />
        </Map> */}
        </>
    );
}

export default MapData;
