import {MailIcon, DeviceMobileIcon} from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import DataTable from "../../../components/MDataTable";
import { toast } from 'react-toastify';

// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

let Id = "";
// List Settings
function Summary() {
    const [actions, setActions] = useState([]);
    useEffect(() => {
        var url = window.location.href;
        Id = url.substring(url.lastIndexOf("/") + 1);
        configuration.getAPI({url:'passenger/list-favourite-drivers', params:{user_id:Id}}).then((data) => {
          if(data.status === 200){
            console.log(data.payload)
            setActions(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
    }, []);
    return (
       <div className="md:pl-8 sm:py-7 col-span-10">
          <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                      <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                          Favourite Drivers
                      </h2>
                      <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show passenger favourite drivers.</p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  {(actions.length>0)?
                      <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
                          {actions.map((action, actionIdx) => (
                              <div
                                  key={action.email}
                                  className={classNames(
                                      actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                                      actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                                      actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
                                      actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                                      'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500'
                                  )}
                              >
                                  <div className=""> 
                                  {console.log("actions.photo",action.photo)}
                                      <img className="mb-3" className="h-16 w-16 rounded-full" src={(action.photo)?action.photo:"/image/avatar.png"}></img>
                                      <h3 className="text-lg font-bold">
                                          <span className="absolute inset-0" aria-hidden="true" />
                                          {action.first_name} {action.last_name}
                                      </h3>
                                      <p class="mt-3 flex items-center text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize py-1">
                                          <DeviceMobileIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                          />
                                          {action.mobile_country_code} {action.mobile}</p>
                                      <p class="mt-3 flex items-center text-base text-gray-500 font-medium sm:mr-6 sm:mt-0 py-1">
                                          <MailIcon
                                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                              aria-hidden="true"
                                          />
                                          {action.email}</p>
                                  </div>
                              </div>
                          ))}
                      </div>:
                        <div className="overflow-hidden divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-1 sm:gap-px">
                            This passenger has no favourite drivers.
                        </div>
                      }
                  </div>
              </div>
          </section>
      </div>
    );
}

export default Summary;
