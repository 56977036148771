  import {
  AnnotationIcon,
  BellIcon,
  BookmarkAltIcon,
  ChartBarIcon,
  CogIcon,
  LogoutIcon,
  KeyIcon,
  CursorClickIcon,
  DesktopComputerIcon,
  DotsVerticalIcon,
  GlobeAltIcon,
  MenuIcon,
  ShieldCheckIcon,
  TrashIcon,
  UserGroupIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Dialog, Menu, Popover, Transition } from "@headlessui/react";
import { Link, useHistory } from "react-router-dom";
import React, { Fragment, useState, useEffect } from "react";
import {reactLocalStorage} from 'reactjs-localstorage';
import configuration from '../config';
import common from "../constants/Common";

// Multiple classes
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// App header
function TrackerMenu() {
  const [activeTab, setActiveTab] = useState("normal");
  const history = useHistory();
  
  return (
    <>
      <Popover className="relative bg-white shadow-sm bg-gray-900 header_bg">
        {({ open }) => (
          <>
            <div className="flex justify-between items-center px-4 py-1 sm:px-6 md:justify-start md:space-x-10">
              <div>
                <Link to="/dashboard" className="flex">
                  <span className="sr-only">Workflow</span>
                  <img
                    className="h-8 w-auto sm:h-10"
                    src={common.logo_url}
                    alt=""
                  />
                </Link>
              </div>
              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-white hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
                <Popover.Group
                  as="nav"
                  className="flex space-x-10 xl-menu-style"
                >
                  <Link
                    to="#"
                    onClick={()=>setActiveTab('normal')}
                    className={(activeTab === 'normal')?"text-base font-semibold text-white hover:text-white hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700":"text-base font-semibold text-white hover:text-white hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                  >
                    Normal
                  </Link>
                  <Link
                    to="#"
                    onClick={()=>setActiveTab('heatmap')}
                    className={(activeTab === 'heatmap')?"text-base font-semibold text-white hover:text-white hover:bg-gray-700 px-3 py-2 rounded-md text_theme bg-gray-700":"text-base font-semibold text-white hover:text-white hover:bg-gray-700 px-3 py-2 rounded-md text_theme"}
                  >
                    Heatmap
                  </Link>
                </Popover.Group>
              </div>
            </div>
          </>
        )}
      </Popover>
    </>
  );
}

export default TrackerMenu;
