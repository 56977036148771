import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useEffect, useState } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/Module";
import _ from 'underscore'
// For Edit Module
let Id = "";

function Edit({ history }) {
  const [fieldArr, setFieldArr] = useState([
    { id: 0, title: "Title", name: "title", type: "TextInput" },
    { id: 1, title: "Code", name: "code", type: "TextInput",is_read_only: true  },
  ]);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    code: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please enter module title"
      flag = false
    }

    if (!fields.code) {
      error['code'] = "Please enter module code"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }

  useEffect(() => {
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration
      .getAPI({ url: "module/list", params: { _id: Id } })
      .then((data) => {
        if (data.status === 200) {
          setFields(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }, []);

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }

  const handleSubmit = () => {
    if (handleValidation()) {

    configuration
      .postAPI({ url: "module/update", params: fields })
      .then((data) => {
        if (data.status === 200) {
          history.push("/module");
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
    }
  };
  return (
    <>
      <Breadcrumb module={labels.editModule} />
      <AddEditSection
        title="Edit Module"
        path="/module"
        fields={fieldArr}
        fieldError={errors}

        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Edit;
