export default {
  Trips: "Trips",
  NewTrips: "NewTrips",
  AcceptedTrips: "AcceptedTrips",
  CompletedTrips: "CompletedTrips",
  CancelledTrips: "CancelledTrips",
  ScheduledTrips: "ScheduledTrips",
  StartedTrips: "StartedTrips",
  TripsDetail: "TripsDetail",
  TripsInvoice: "TripsInvoice",
};
