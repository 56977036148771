import React, { useState, useEffect } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import { pluck } from "underscore";
import ListSection from "../../components/ListSection";
import labels from "../../constants/NotificationLog";
import configuration from '../../config';
import { toast } from 'react-toastify';
import DeleteModel from "../../components/DeleteModel";

// List all roles
function View({location}) {
  const columns = [
    { title: "ID", field: "notification_log_id" },
    {
      title: "Name",
      field: "first_name",
      render: (rowData) => (
        <Link
          className="text-indigo-600 hover:text-indigo-900"
          to={`/passenger/profile/${rowData.user_id}`}
        >
          {rowData.first_name} {rowData.last_name}
        </Link>
      ),
    },
    { title: "Title", field: "title" },
    { title: "Date", field: "created_at" },
    {
      title: "User Type",
      field: "user_type",
      
    },
  ];

  const btnGroups = [
    { id: 1, title: "Send Notification", type: "addNew", path: "/notification/send" },
    /*{ id: 5, title: "Delete", type: "delete", path: "/notification" },*/
  ];
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const tableRef = React.createRef();

  useEffect(() => {
    // console.log(props.location)
    if (location.state && location.state.is_added) {
      setShow(true)
      setTimeout(
        () => setShow(false),
        3000
      );
    }
    // getDatas();
    // console.log(configuration.baseURL);
  }, [location.state]);

  // function getDatas(){
  //   configuration.getAPI({url:'notification/list', params:{}}).then((data) => {
  //     // console.log(data)
  //     if(data.status === 200){
  //       setData(data.payload)
  //     }
  //   }).catch(error => {
  //     // console.log(error)
  //     return toast.error(error.message)
  //   });
  // }
  function handleAction(type){
    if (ids.length < 1) {
      return toast.error('Please select at least one record')
    }
    else if (type === 'delete') {
      setShowModal(true);
      return;
    }
    const sendData={
      ids,
      type:(type === 'makeActive')?'active':(type === 'makeInactive')?'inactive':type
    }
    configuration.postAPI({url:'ride-option/action', params:sendData}).then((data) => {
      if(data.status === 200){
        // getDatas()
        setIds([]);
        return toast.success((type === 'delete') ?'Record deleted successfully':'Record update successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  const onSelectionChange = (e) => {
    let idArr = pluck(e, 'notification_log_id');
    setIds(idArr)
  }


  function handleDelete() {
    const sendData = {
      ids,
      type: 'delete'
    }
    configuration.postAPI({ url: 'ride-option/action', params: sendData }).then((data) => {
      if (data.status === 200) {
        // getDatas()
        setIds([]);
        setShowModal(false);
        return toast.success('Record deleted successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleCancel() {
    setShowModal(false);
  }
  return (
    <>
      <Breadcrumb module={labels.NotificationLog} />
      <Alert
        show={show}
        setShow={setShow}
        bgColorClass="green"
        caption="Success"
        message="Record added successfully"
      />
      <DeleteModel
        mode={showModal}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
      />
      <ListSection
        captionTitle="Notification Log"
        columns={columns}
        // data={data}
        btnGroups={btnGroups}
        handleAction={handleAction}
        onSelectionChange={onSelectionChange}
        disabledCheckbox={true}
        is_pagenation={true}
        apiURL='notification/list-sort'
      />
    </>
  );
}

export default View;
