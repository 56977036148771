import React, { useState, useEffect } from "react";
import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import { pluck,each } from "underscore";
import labels from "../../../constants/OutStationPrice";
import configuration from '../../../config';
import { toast } from 'react-toastify';

// List all users
function Add({history}) {
  const [countryOpt, setCurrencyOpt] = useState([]);
  const [rideArr, setRideArr] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const fieldArr = [
    {
      id: 0,
      title: "Select Vehicle",
      name: "vehicle_ride_option_id",
      type: "Dropdown",
      options: rideArr,
    },
    {
      id: 1,
      title: "Country",
      name: "country_id",
      type: "Dropdown",
      options: countryOpt,
    },
    {
      id: 2,
      title: "State",
      name: "state_id",
      type: "Dropdown",
      options: stateOpt,
    },
    {
      id: 3,
      title: "From City",
      name: "from_city_id",
      type: "Dropdown",
      options: cityOpt,
    },
    {
      id: 4,
      title: "To City",
      name: "to_city_id",
      type: "Dropdown",
      options: cityOpt,
    },
    { id: 5, title: "Base Fare for Package ", name: "base_fare", type: "TextInput", is_number:true},
    { id: 7, title: "Additional Fare Per Km", name: "additional_fare_per_km", type: "TextInput", is_number:true},
    { id: 8, title: "Base Distance (KM) ", name: "additional_fare_per_min", type: "TextInput", is_number:true},
  ];
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  
  function getRideOption() {
    configuration
      .getAPI({ url: "ride-option/list", params: {status:'active'} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.title;
              element.value = element.vehicle_ride_option_id;
              element.id = element.vehicle_ride_option_id;

              return element;
            });
          }
          setRideArr(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getCountryDatas() {
    configuration
      .getAPI({ url: "country/list", params: {status:'active'} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.country_id;
              element.id = element.country_id;

              return element;
            });
          }
          setCurrencyOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getStateDatas(params) {
    configuration
      .getAPI({ url: "state/list", params })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.state_id;
              element.id = element.state_id;

              return element;
            });
          }
          setStateOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function getCities(params) {
    configuration
      .getAPI({ url: "city/list", params })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.city_id;
              element.id = element.city_id;

              return element;
            });
          }
          setCityOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  useEffect(() => {
    getCountryDatas();
    getRideOption();
  }, []);

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.vehicle_ride_option_id) {
      error['vehicle_ride_option_id'] = "Please select vehicle ride"
      flag = false
    }
    if (!fields.country_id) {
      error['country_id'] = "Please enter hours"
      flag = false
    }

    if (!fields.state_id) {
      error['state_id'] = "Please enter kilometer"
      flag = false
    }

    if (!fields.from_city_id) {
      error['from_city_id'] = "Please enter kilometer"
      flag = false
    }

    if (!fields.to_city_id) {
      error['to_city_id'] = "Please enter kilometer"
      flag = false
    } 

    if(fields.from_city_id === fields.to_city_id){
      error['to_city_id'] = "Please select different city"
      flag = false
    }

    if (!fields.base_fare) {
      error['base_fare'] = "Please enter base fare"
      flag = false
    } else if(fields.base_fare <= 0) {
      error['base_fare'] = "Please enter valid base fare"
      flag = false
    }

    if (!fields.additional_fare_per_km) {
      error['additional_fare_per_km'] = "Please enter additional fare per km"
      flag = false
    } else if(fields.additional_fare_per_km < 0) {
      error['additional_fare_per_km'] = "Please enter valid additional fare per km"
      flag = false
    }

    if (!fields.additional_fare_per_min) {
      error['additional_fare_per_min'] = "Please enter additional fare per min"
      flag = false
    } else if(fields.additional_fare_per_min < 0) {
      error['additional_fare_per_min'] = "Please enter valid additional fare per min"
      flag = false
    }

    setErrors({ ...error })
    return flag;
  }

  function handleChange(field, e) {
    if (field === "country_id" && e.target.value) {
      setStateOpt([]);
      setCityOpt([]);
      getStateDatas({ country_id: e.target.value, status:'active' });
      setFields({ ...fields, ["city_id"]: '', ["state_id"]: '', [field]: e.target.value });
    } else if (field == "state_id" && e.target.value) {
      setCityOpt([]);
      getCities({ state_id: e.target.value, status:'active' });
      setFields({ ...fields, ["city_id"]: '', [field]: e.target.value });
    } else if (field === "state_id" && !e.target.value) {
      setCityOpt([]);
      setFields({ ...fields, [field]: e.target.value })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  

  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'rental-price/create', params:{...fields, service_type:'OUT_STATION'}}).then((data) => {
        if(data.status === 200){
          history.push('/out-station-price')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  return (
    <>
      <Breadcrumb module={labels.AddOutStation} />
      <AddEditSection title="Add Out Station Price" path="/out-station-price" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
