import React, { useEffect, useState } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import ColumnHeader from "../../components/AccessRight/ColumnHeader";
import ColumnRow from "../../components/AccessRight/ColumnRow";
import Label from "../../components/AccessRight/Label";
import labels from "../../constants/AccessRight";
import configuration from '../../config';
import { each } from 'underscore';
import { toast } from 'react-toastify';
import Dropdown from "../../components/Form/Dropdown";
import Loader from "../../components/Loader/Loader";

// List Access Rights
function View() {
  const [columnHeaders, setColumn] = useState([{ title: "View", value: 'is_view', selectAll: false },
  // { title: "Add", value: 'is_add', selectAll: false },
  // { title: "Edit", value: 'is_edit', selectAll: false },
  // { title: "Delete", value: 'is_delete', selectAll: false }
]);
  const [role, setRole] = useState([]);
  const [module, setModule] = useState([]);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({})
  const [isValid, setIsValid] = useState(true)
  const [authorisation, setAuthorisation] = useState()
  useEffect(() => {
    configuration.getAPI({ url: 'role/list', params: { status: 'active' } }).then((data) => {
      // console.log(data)
      if (data.payload) {
        each(data.payload, single => {
          single.value = single.role_id
        })
        setRole(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });

  }, []);

  function changeStatus(field, e) {
    setFields({ ...fields, 'roleID': e.target.value })
    if (e.target.value && e.target.value !== '') {
      configuration.getAPI({ url: 'common/list-access', params: { roleID: e.target.value } }).then((data) => {
        // console.log(data)
        if (data.payload) {
          setModule(data.payload)
          each(columnHeaders, single => {
            single.selectAll = false;
          })
          setColumn([...columnHeaders])
        } else {
          setModule([]);
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }
  function checkBoxChange(code, e) {
    each(module, single => {
      if (single.code === code) {
        single.data[e.target.value] = e.target.checked;
      }
    })
    // console.log(module);
    setModule([...module]);
  }
  function allCheckBoxChange(code, e) {
    each(module, single => {
      single.data[e.target.value] = e.target.checked;
    })
    each(columnHeaders, single => {
      if (single.value === e.target.value) {
        single.selectAll = e.target.checked;
      }
    })
    setModule([...module]);
    setColumn([...columnHeaders])
  }
  function handleSubmit() {
    console.log(fields)
    console.log(module)
    setErrors({})
    if (!fields.roleID) {
      let error = {};
      error['roleID'] = "Please select role"
      setErrors({ ...error })
      return false;
    }
    configuration.postAPI({ url: 'common/update-permission', params: { ...fields, accessData: module } }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        return toast.success('Updated Successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  return (
    <> {isValid ? <><Breadcrumb module={labels.permission} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                        Permissions
                      </h2>
                    </div>
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="lg:gap-y-0 lg:gap-x-8">
                        <div className="space-y-8 divide-y divide-gray-200 sm:border-b border-bottom-theme">
                          <div className="sm:p-6">
                            <div className="gap-y-6 gap-x-4 sm:grid-cols-12">
                              <div className="mt-1 text-right">
                              <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="mr-3 mb-1 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                >
                                  Save Changes
                                </button>
                              </div>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                              <div
                                className="sm:col-span-3 inline"
                              >
                                <Label title="Role" />
                                <div className="mt-1">
                                  <Dropdown
                                    id="roleID"
                                    name="roleID"
                                    title="Role"
                                    value={fields.roleID}
                                    status={role}
                                    handleChange={(field, e) => changeStatus(field, e)}
                                  />
                                  {errors.roleID ? <Label title='roleID' fieldError={errors.roleID} /> : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      <div className="flex flex-col">
                        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg border-bottom-theme">
                              <table className="min-w-full divide-y divide-gray-200" style={{ borderCollapse: "inherit" }}>
                                <thead className="bg-gray-50 permissions_data_heading">
                                  <tr>
                                    <th
                                      scope="col"
                                      className="relative px-6 py-3 text-left text_theme"
                                    >
                                      Module Name
                                    </th>
                                    {columnHeaders.map((header) => {
                                      return (
                                        <ColumnHeader
                                          title={header.title}
                                          key={header.value}
                                          id={header.value}
                                          name={header.value}
                                          checked={header.selectAll}
                                          value={header.value}
                                          handleChange={(e) => allCheckBoxChange(header.value, e)}
                                        />
                                      );
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                  {module.map((person, personIdx) => (
                                    <tr
                                      key={person.code}
                                      className={
                                        personIdx % 2 === 0
                                          ? "bg-white box_bg"
                                          : "bg-gray-50 box_bg"
                                      }
                                    >
                                      <td className="px-6 py-4 whitespace-nowrap text-basefont-medium text-gray-900 text_theme">
                                        {person.title}
                                      </td>
                                      <ColumnRow
                                        key={person.title}
                                        id={person.title}
                                        name={person.title}
                                        checked={person.data?.is_view}
                                        value="is_view"
                                        handleChange={(e) => checkBoxChange(person.code, e)}
                                      />
                                      {/* <ColumnRow
                                        key={person.title}
                                        id={person.title}
                                        name={person.title}
                                        checked={person.data?.is_add}
                                        value="is_add"
                                        handleChange={(e) => checkBoxChange(person.code, e)}
                                      />
                                      <ColumnRow
                                        key={person.title}
                                        id={person.title}
                                        name={person.title}
                                        checked={person.data?.is_edit}
                                        value="is_edit"
                                        handleChange={(e) => checkBoxChange(person.code, e)}
                                      />
                                      <ColumnRow
                                        key={person.title}
                                        id={person.title}
                                        name={person.title}
                                        checked={person.data?.is_delete}
                                        value="is_delete"
                                        handleChange={(e) => checkBoxChange(person.code, e)}
                                      /> */}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div> </> : <> <Loader/> </>}

    </>
  )
}

export default View;
