import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, { useState, useEffect } from "react";
import configuration from "../../config";
import { toast } from "react-toastify";
import labels from "../../constants/Module";
import _ from 'underscore'
// For Add Module
function Add({ history }) {
  const [fieldArr, setFieldArr] = useState([
    { id: 0, title: "Title", name: "title", type: "TextInput" },
    { id: 1, title: "Code", name: "code", type: "TextInput" },
  ]);


  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    title: "",
    code: "",
  });


  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.title) {
      error['title'] = "Please enter module title"
      flag = false
    }

    if (!fields.code) {
      error['code'] = "Please enter module Code"
      flag = false
    }
    // console.log(error)
    setErrors({ ...error })
    return flag;
  }

  useEffect(() => {
  }, []);

  const handleSubmit = () => {
    if (handleValidation()) {

      configuration
        .postAPI({ url: "module/create", params: fields })
        .then((data) => {
          if (data.status === 200) {
            history.push("/module");

            // history.push({ pathname: "/module", state: { is_added: true } });
          } else if (data.error) {
            return toast.error(data.error.message);
          } else {
            return toast.error("Something went wrong");
          }
        })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  };

  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value });
  }

  return (
    <>
      <Breadcrumb module={labels.addModule} />

      <AddEditSection
        title="Add Module"
        path="/module"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Add;
