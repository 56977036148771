import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import StarsRating from 'stars-rating'
import { Switch } from '@headlessui/react'

// Dynamic class
function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}
let Id = "";
// List Settings
function Summary() {
    const [profile, setProfile] = useState({});
    const [availability_status, setavailability_status] = useState(false);

    useEffect(() => {
        // console.log(props.location)
        var url = window.location.href;
        Id = url.substring(url.lastIndexOf("/") + 1);
        configuration.getAPI({ url: 'passenger/detail', params: { user_id: Id } }).then((data) => {
            // console.log(data)
            if (data.status === 200) {
                let profileData = {
                    'First Name': data.payload.first_name,
                    'Last Name': data.payload.last_name,
                    'Email ': data.payload.email,
                    'Mobile': `${data.payload.mobile_country_code} ${data.payload.mobile}`,
                    'Reg Date': data.payload.created_at,
                    'Status': data.payload.status,
                    'Average Rating': <StarsRating
                        count={5}
                        value={Number(data.payload.average_ratings)}
                        half={true}
                        edit={false}
                        size={30}
                        color2={'text-indigo-600'} />
                }
                if (data.payload.device_details && data.payload.device_details.device_name) {
                    profileData['Device Name'] = data.payload.device_details.device_name
                }
                if (data.payload.device_details && data.payload.device_details.device_type) {
                    profileData['Device Type'] = data.payload.device_details.device_type
                }
                if (data.payload.device_details && data.payload.device_details.device_token) {
                    profileData['Device Token'] = data.payload.device_details.device_token
                }
                if (data.payload.device_details && data.payload.device_details.app_version) {
                    profileData['APP Version'] = data.payload.device_details.app_version
                }
                setavailability_status((data.payload.availability_status==='online'))
                setProfile(profileData)
            }
        }).catch(error => {
            // console.log(error)
            return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
    }, []);

    function onChangeSwitch(e) {
        configuration.postAPI({ url: 'driver/availability', params: { availability_status: (e) ? 'online' : 'offline', user_id: Id } }).then((data) => {
            if (data.status === 200) {
                setavailability_status(e)
                return toast.success('Update successfully')
            } else if (data.status === 404) {
                return toast.error(data.error.message)
            } else {
                return toast.error('Something went wrong')
            }
        }).catch(error => {
            return toast.error(error.message)
        });
    }

    return (
        <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <div className="flex justify-between">
                            <div >
                                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                    Summary
                                </h2>
                            </div>
                            <div className="flex items-center">
                                <label htmlFor="push-everything" className="text-medium leading-6 font-medium text-gray-900">
                                    Availability Status
                                </label> &nbsp;&nbsp;
                                <Switch
                                    checked={availability_status}
                                    onChange={(e) => onChangeSwitch(e)}
                                    className={classNames(
                                        availability_status ? 'bg-indigo-600' : 'bg-gray-200',
                                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                    )}
                                >
                                    <span className="sr-only">Use setting</span>
                                    <span
                                        aria-hidden="true"
                                        className={classNames(
                                            availability_status ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                        )}
                                    />
                                </Switch>
                            </div>
                        </div>
                        <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show driver summary.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                        < div className="">
                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                {Object.keys(profile).map((field) => (
                                    <div key={field} className="sm:col-span-1">
                                        <dt className="text-basefont-medium text-gray-500">{field}</dt>
                                        <dd className="mt-1 text-base text-gray-900 md:break-all">{profile[field]}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
