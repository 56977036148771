import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from "../../parts/Breadcrumb";
import Caption from "../../components/Caption";
import labels from "../../constants/Trips";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { last } from 'underscore';
import { PrinterIcon, DownloadIcon } from '@heroicons/react/solid'
import html2pdf from "html2pdf.js";
import ReactToPrint from "react-to-print";
import { useLocation } from 'react-router-dom'


// For Add User
function GenerateLoadingPass({ history }) {
  const location = useLocation();
  const path = location.state;
  const [currency, setCurrency] = useState('')
  const printInvoiceRef = useRef();
  const [tripDetail, setTripDetail] = useState({
    passenger_info:{},
    driver_info:{},
    vehicle_ride_info:{},
    service_info:{},
    requested_info:{},
    fare_info:{},
    rental_price_info:{},
    discount:0,
    drop_off:[],
    additional_charges:[],
    taxes:[],
  });
  useEffect(() => {
    var url = window.location.href;
    let Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({ url: 'trip/detail', params: {trip_id: Id} }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setTripDetail(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    configuration.getAPI({ url: 'common/get-settings', params: {} }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setCurrency(data.payload.currency)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);
  function printInvoice() {
      const element = document.getElementById("make_pdf");
      let fileName = tripDetail.invoice_no;
      var opt = {
          margin: 0.5,
          filename: fileName + '.pdf',
          image: { type: 'jpeg' },
          html2canvas: { scale: 5 },
          jsPDF: { unit: 'in', format: 'A4', orientation: 'landscape' }
      };
      html2pdf().from(element).set(opt).save();
  }

  return (
    <>
      <Breadcrumb module={labels.TripsInvoice} page={path}/>
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <Caption title="Invoice" />
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-300 sm:rounded-lg bg-white box_bg border-bottom-theme">
                    <div className="lg:gap-y-0 lg:gap-x-8 sm:p-6">
                      <form className="space-y-8 divide-y divide-gray-200">
                        <div className="space-y-8 divide-y divide-gray-200">
                          <div className="pt-1">
                            <div className="py-3 border-b flex justify-between">
                              <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                Booking ID: #{tripDetail.invoice_no}

                              </h2>
                              <div>
                                <ReactToPrint
                                    trigger={() => <button type="button" className="mr-3 py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                                      <span className="sr-only">Open options</span>
                                      <PrinterIcon className="h-5 w-5 inline" aria-hidden="true" /> Print
                                    </button>}
                                    content={() => printInvoiceRef.current}
                                />
                                
                                <button onClick={printInvoice} type="button" className="py-1 px-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn">
                                  <span className="sr-only">Open options</span>
                                  <DownloadIcon className="h-5 w-5 inline" aria-hidden="true" /> Download as PDF
                                </button>
                              </div>
                            </div>
                            <div className="mt-5" id="make_pdf" ref={printInvoiceRef}>
                              <div className="">

                                <div className="grid grid-cols-1">
                                  <div className="  flex items-center  justify-between bg-white shadow sm:rounded-lg mt-5 border py-3 px-3 min-h">
                                    <img className="md:mb-0 mb-3  w-12 " src="https://assets.xlcab.appsworld.work/logo.png" alt=""></img>
                                    <p className="justify-self-start text-xl font-bold">Invoice</p>
                                  </div>
                                  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">

                                    <div className="bg-white shadow sm:rounded-lg mt-5 border">
                                      <div className="px-4 py-5 sm:px-6">
                                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                          Passenger Info
                                        </h2>
                                      </div>
                                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Name</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.passenger_info.first_name} {tripDetail.passenger_info.last_name}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Email</dt>
                                            <dd className="mt-1 text-base text-gray-900 break-words">{tripDetail.passenger_info.email}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Mobile</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.passenger_info.mobile_country_code} {tripDetail.passenger_info.mobile}</dd>
                                          </div>
                                        </dl>
                                      </div>
                                    </div>
                                    <div className="bg-white shadow sm:rounded-lg mt-5 border">
                                      {(tripDetail.driver_info)?
                                        <>
                                        <div className="px-4 py-5 sm:px-6">
                                          <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                            Driver Info
                                          </h2>
                                        </div>
                                        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                            <div className="sm:col-span-1">
                                              <dt className="text-basefont-medium text-gray-500">Name</dt>
                                              <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.first_name} {tripDetail.driver_info.last_name}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                              <dt className="text-basefont-medium text-gray-500">Email</dt>
                                              <dd className="mt-1 text-base text-gray-900 break-words">{tripDetail.driver_info.email}</dd>
                                            </div>
                                            <div className="sm:col-span-1">
                                              <dt className="text-basefont-medium text-gray-500">Mobile</dt>
                                              <dd className="mt-1 text-base text-gray-900">{tripDetail.driver_info.mobile_country_code} {tripDetail.driver_info.mobile}</dd>
                                            </div>
                                            {/*<div className="sm:col-span-1">
                                              <dt className="text-basefont-medium text-gray-500">VAT Number</dt>
                                              <dd className="mt-1 text-base text-gray-900">45875</dd>
                                            </div>*/}
                                          </dl>
                                        </div></>:''
                                      }
                                    </div>
                                    {(tripDetail.requested_info)?
                                      <div className="bg-white shadow sm:rounded-lg mt-5 border">
                                          <div className="px-4 py-5 sm:px-6">
                                            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                              Dispatcher Info
                                            </h2>
                                          </div>
                                          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                              <div className="sm:col-span-1">
                                                <dt className="text-basefont-medium text-gray-500">Name</dt>
                                                <dd className="mt-1 text-base text-gray-900">{tripDetail.requested_info.first_name} {tripDetail.requested_info.last_name}</dd>
                                              </div>
                                              <div className="sm:col-span-1">
                                                <dt className="text-basefont-medium text-gray-500">Email</dt>
                                                <dd className="mt-1 text-base text-gray-900">{tripDetail.requested_info.email}</dd>
                                              </div>
                                              <div className="sm:col-span-1">
                                                <dt className="text-basefont-medium text-gray-500">Mobile</dt>
                                                <dd className="mt-1 text-base text-gray-900">{tripDetail.requested_info.mobile_country_code} {tripDetail.requested_info.mobile}</dd>
                                              </div>
                                              <div className="sm:col-span-1">
                                                <dt className="text-basefont-medium text-gray-500">Address</dt>
                                                <dd className="mt-1 text-base text-gray-900">{tripDetail.requested_info.address}</dd>
                                              </div>
                                            </dl>
                                          </div>
                                        
                                    </div>: ''
                                    }
                                  </div>
                                  

                                  <div className="">
                                    <dl className="  grid grid-cols-1 md:grid-cols-1 gap-x-6 mt-7 py-4 px-4 text-basebg-white shadow sm:rounded-lg mt-5 border">
                                      <div className="mb-5">
                                        <div className="mb-5 col-span-4">
                                          <dd className="mt-2 text-gray-700">
                                            <address className="not-italic">
                                              <span className="block text-base">Date & Time: <strong>{tripDetail.booking_date}</strong></span>
                                              <span className="block text-base">Total Distance: <strong>{tripDetail.formatted_total_distance}</strong></span>
                                              <span className="block text-base">Total Duration: <strong>{tripDetail.formatted_total_duration}</strong></span>
                                            </address>
                                          </dd>
                                        </div>


                                        <dt className="font-medium text-lg text-gray-900">Location</dt>
                                        {/*<div className="xi">
                                          <div className="">
                                            <input disabled="" name="pickup" title="" placeholder="From location" autocomplete="off" className="custom_input from_input" value="54, rue du Gue Jacquet" />
                                          </div>
                                          <div className="sn">
                                            <div className="">
                                              <input disabled="" name="destination" title="" placeholder="To Location" autocomplete="off" className="custom_input from_input" value="38, rue des Nations" />
                                            </div>
                                          </div>
                                          <div className=" xy xz y5 yc "></div>
                                        </div>*/}

                                        <div className="xi mt-4 py-4 px-4 shadow-lg bg-white rounded-md">
                                          <div className="relative ml-10">
                                            <span className="custom_input from_input bor">{tripDetail.pick_up}</span>
                                          </div>
                                          {                
                                            tripDetail.drop_off.map((e, key) => {
                                              let cssClass = (last(tripDetail.drop_off).location == e.location)?"sn":"sn centerdrop"
                                              return(
                                                <div className={cssClass}>
                                                  <div className="relative ml-10">
                                                     <span className="custom_input from_input bor">{e.location} </span>
                                                  </div>
                                                </div>
                                                )
                                            })
                                          }
                                          <div className=" xy xz y5 yc "></div>
                                        </div>
                                      </div>              
                                    </dl>
                                    <div className="bg-white shadow sm:rounded-lg mt-5 border">
                                    <div className="px-4 py-5 sm:px-6">
                                      <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                                        Fare Details
                                      </h2>
                                    </div>
                                    {(tripDetail.service_info.code === 'RENTAL' && tripDetail.rental_price_info)?
                                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Hours</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.rental_price_info.hr}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Kilo Meter</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.rental_price_info.km}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Base Fare for Package</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.rental_price_info.base_fare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Additional Fare Per Km</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.rental_price_info.additional_fare_per_km}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Additional Fare Per Min</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.rental_price_info.additional_fare_per_min}</dd>
                                          </div>
                                        </dl>
                                      </div> :
                                    (tripDetail.service_info.code === 'RIDE' && tripDetail.fare_info)?
                                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Min Base KM</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.fare_info.min_base_km}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Min Base Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.min_base_fare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Per KM Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.per_km_fare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Per Min Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.per_min_fare).toFixed(2)  }</dd>
                                          </div>
                                          {/* <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Wait Fees</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.wait_fees).toFixed(2)}</dd>
                                          </div> */}
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Base Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.base_fare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Total KM Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.total_km_fare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Total Min Fare</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.fare_info.total_min_fare).toFixed(2)}</dd>
                                          </div>
                                        </dl>
                                      </div>: 
                                    (tripDetail.service_info.code === 'OUT_STATION' && tripDetail.rental_price_info)?
                                      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                                        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Country</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.country}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">State</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.state}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">From City</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.from_city}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">To City</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.to_city}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Base Fare for Package</dt>
                                            <dd className="mt-1 text-base text-gray-900">{currency} {Number(tripDetail.rental_price_info.base_fare).toFixed(2)}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Additional Fare Per Km</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.rental_price_info.additional_fare_per_km}</dd>
                                          </div>
                                          <div className="sm:col-span-1">
                                            <dt className="text-basefont-medium text-gray-500">Additional Fare Per Min</dt>
                                            <dd className="mt-1 text-base text-gray-900">{tripDetail.rental_price_info.additional_fare_per_min}</dd>
                                          </div>
                                        </dl>
                                      </div>: ''
                                    }
                                  </div>
                                    <div className="mt-5 float-right	">
                                      <dl style={{ minWidth: '320px', }} className=" space-y-6 pb-5 pt-5">
                                        <div className="flex items-center justify-between">
                                          <dt className="text-sm">Subtotal</dt>
                                          <dd className="text-basefont-medium text-gray-900">{currency} {tripDetail.subtotal}</dd>
                                        </div>
                                        {(tripDetail.is_insurance_taken === 'yes')?
                                          <div className="flex items-center justify-between">
                                            <dt className="text-sm">Insurance</dt>
                                            <dd className="text-basefont-medium text-gray-900">{currency} {tripDetail.insurance}</dd>
                                          </div>:''
                                        }
                                        {(tripDetail.additional_charges.length > 0)?
                                          tripDetail.additional_charges.map((single,indx)=>{
                                            return(<div className="flex items-center justify-between">
                                              <dt className="text-sm">{single.title}</dt>
                                              <dd className="text-basefont-medium text-gray-900">{currency} {Number(single.price).toFixed(2)}</dd>
                                            </div>)
                                          }):''
                                        }
                                        {(tripDetail.waiting_fare > 0)?
                                          <div className="flex items-center justify-between">
                                            <dt className="text-sm">Waiting Charge</dt>
                                            <dd className="text-basefont-medium text-gray-900">{currency} {tripDetail.waiting_fare}</dd>
                                          </div>:''
                                        }
                                        {(tripDetail.taxes.length > 0)?
                                          tripDetail.taxes.map((single,indx)=>{
                                            return(<div className="flex items-center justify-between">
                                              <dt className="text-sm">{single.name} ({single.value}%)</dt>
                                              <dd className="text-basefont-medium text-gray-900">{currency} {Number(single.tax_amount).toFixed(2)}</dd>
                                            </div>)
                                          }):''
                                        }
                                        {(tripDetail.discount)?
                                          <div className="flex items-center justify-between">
                                            <dt className="text-sm">Discount</dt>
                                            <dd className="text-basefont-medium text-gray-900">{currency} {Number(tripDetail.discount).toFixed(2)}</dd>
                                          </div>:''
                                        }
                                        
                                        <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                                          <dt className="text-base font-medium">Total</dt>
                                          <dd className="text-base font-medium text-gray-900">{currency} {tripDetail.total}</dd>
                                        </div>
                                        {/*<div className="flex items-center justify-between">
                                          <dt className="text-sm">Subtotal</dt>
                                          <dd className="text-basefont-medium text-gray-900">₹64.00</dd>
                                        </div>
                                        <div className="flex items-center justify-between">
                                          <dt className="text-sm">Shipping</dt>
                                          <dd className="text-basefont-medium text-gray-900">₹5.00</dd>
                                        </div>
                                        <div className="flex items-center justify-between">
                                          <dt className="text-sm">Taxes</dt>
                                          <dd className="text-basefont-medium text-gray-900">₹5.52</dd>
                                        </div>
                                        <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                                          <dt className="text-base font-medium">Total</dt>
                                          <dd className="text-base font-medium text-gray-900">₹240.52</dd>
                                        </div>*/}
                                      </dl>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>


    </>
  );
}


export default GenerateLoadingPass;
