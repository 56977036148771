import React, { useState, useEffect } from "react";
import Alert from "../../../components/Alert";
import Breadcrumb from "../../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import { pluck } from "underscore";
import ListSection from "../../../components/ListSection";
import DeleteModel from "../../../components/DeleteModel";
import labels from "../../../constants/Driver";
import configuration from '../../../config';
import { toast } from 'react-toastify';

// List all Drivers
function View({location}) {
  const columns = [
    {
      title: "ID",
      field: "user_id",
      render: (rowData) => (
        <Link
          className="text-indigo-600 hover:text-indigo-900"
          to={{     
            pathname: `/driver/edit/${rowData.user_id}`,
            state:'approved-driver'
          }}
        >
          {rowData.user_id}
        </Link>
      ),
    },
    {
      title: "Name",
      field: "first_name",
      render: (rowData) => (
        <Link
          className="text-indigo-600 hover:text-indigo-900"
          // to={`/driver/profile/${rowData.user_id}`}
          to={{     
            pathname: `/driver/profile/${rowData.user_id}`,
            state:'approved-driver'
          }}
        >
          {rowData.first_name} {rowData.last_name}
        </Link>
      ),
    },
    { title: "Email", field: "email" },
    {
      title: "Phone",
      field: "mobile",
      render: (rowData) => (
        <span>
          {rowData.mobile_country_code} {rowData.mobile}
        </span>
      ),
    },
    { title: "Sign up date", field: "created_at" },
    { title: "Wallet Balance", field: "wallet_balance" }
  ];

  const btnGroups = [
    { id: 1, title: "Add New", type: "addNew", path: {     
      pathname: `/driver/add`,
      state:'approved-driver'
    } },
    { id: 4, title: "Approved", type: "makeApproved", path: "/driver" },
    { id: 5, title: "Unapproved", type: "makeUnapproved", path: "/driver" },
    { id: 2, title: "Make Active", type: "makeActive", path: "/driver" },
    { id: 3, title: "Make Inactive", type: "makeInactive", path: "/driver" },
    { id: 6, title: "Archive", type: "trashed", path: "/driver" },
    { id: 7, title: "Delete", type: "delete", path: "/driver" },
  ];
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  const tableRef = React.createRef();

  useEffect(() => {
    
    // getDatas();
  }, []);

  // function getDatas(){
  //   configuration.getAPI({url:'driver/list', params:{}}).then((data) => {
  //     // console.log(data)
  //     if(data.status === 200){
  //       setData(data.payload)
  //     }
  //   }).catch(error => {
  //     // console.log(error)
  //     return toast.error(error.message)
  //   });
  // }
  function handleAction(type){
    if (ids.length < 1) {
      return toast.error('Please select at least one record')
    } else if(type === 'delete'){
      setShowModal(true);
      return;
    }
    const sendData={
      ids,
      type:(type === 'makeActive')?'active':(type === 'makeInactive')?'inactive':(type === 'makeApproved')?'approved':(type === 'makeUnapproved')?'unapproved':type
    }
    configuration.postAPI({url:'driver/action', params:sendData}).then((data) => {
      if(data.status === 200){
        // getDatas({})
        tableRef.current.onQueryChange()
        setIds([]);
        return toast.success((type === 'delete') ?'Record deleted successfully':'Record update successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  const onSelectionChange = (e) => {
    let idArr = pluck(e, 'user_id');
    setIds(idArr)
  }
  function handleDelete() {
    const sendData={
      ids,
      type:'delete'
    }
    configuration.postAPI({url:'driver/action', params:sendData}).then((data) => {
      if(data.status === 200){
        // getDatas()
        tableRef.current.onQueryChange()
        setIds([]);
        setShowModal(false);
        return toast.success('Record deleted successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleCancel() {
    setShowModal(false);
  }
  return (
    <>
      <Breadcrumb module={labels.ApproveDriver} />
      <Alert
        show={show}
        setShow={setShow}
        bgColorClass="green"
        caption="Success"
        message="Record added successfully"
      />
      <DeleteModel 
        mode={showModal}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
      />
      <ListSection
        captionTitle="View Approved Drivers"
        columns={columns}
        tableRef={tableRef}
        // data={data}
        btnGroups={btnGroups}
        handleAction={handleAction}
        onSelectionChange={onSelectionChange}
        filterData={{status:'approved'}}
        is_pagenation={true}
        apiURL='driver/list-sort'
      />
    </>
  );
}

export default View;
