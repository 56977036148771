import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import React, {useEffect, useState} from "react";
import labels from "../../constants/Dispatcher";
import configuration from '../../config';
import CountryCode from '../../country_code';
import { toast } from 'react-toastify';

// For Edit State
let Id = "";
function Edit({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [countryOpt, setCurrencyOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const fieldArr = [
    { id: 0, title: "First Name ", name: "first_name", type: "TextInput" },
    { id: 0, title: "Last Name", name: "last_name", type: "TextInput" },
    { id: 0, title: "Country Code", name: "mobile_country_code", type: "Dropdown", options: CountryCode.countries },
    { id: 0, title: "Phone Number", name: "mobile", type: "TextInput" },
    { id: 0, title: "Email", name: "email", type: "TextInput" },    
    { id: 0, title: "Photo", name: "photo", type: "FileInput" },
    {
      id: 0,
      title: "Country",
      name: "country_id",
      type: "Dropdown",
      options: countryOpt,
    },
    {
      id: 1,
      title: "State",
      name: "state_id",
      type: "Dropdown",
      options: stateOpt,
    },
    {
      id: 1,
      title: "City",
      name: "city_id",
      type: "Dropdown",
      options: cityOpt,
    },
    {
      id: 1,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },   
    { id: 10, title: "Dispatcher Booking Service Charge in %", name: "service_charge", type: "TextInput", is_number:true },  
  ];
  const [errors, setErrors] = useState({
    title: "",
    description: "",
    status: "",
  });
  const [fields, setFields] = useState({});
  const [photo, setPhoto] = useState(null);
  function getCountryDatas() {
    configuration
      .getAPI({ url: "country/list", params: {} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.country_id;
              element.id = element.country_id;

              return element;
            });
          }
          setCurrencyOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getStateDatas(params) {
    configuration
      .getAPI({ url: "state/list", params })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.state_id;
              element.id = element.state_id;

              return element;
            });
          }
          setStateOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getCities(params) {
    configuration
      .getAPI({ url: "city/list", params })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.city_id;
              element.id = element.city_id;

              return element;
            });
          }
          setCityOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.first_name) {
      error['first_name'] = "Please enter first name"
      flag = false
    }

    if (!fields.last_name) {
      error['last_name'] = "Please enter last name"
      flag = false
    }

    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }

    if (!fields.country_id) {
      error['country_id'] = "Please enter country"
      flag = false
    }

    if (!fields.state_id) {
      error['state_id'] = "Please enter state"
      flag = false
    }

    if (!fields.city_id) {
      error['city_id'] = "Please enter city"
      flag = false
    }

    if (!fields.service_charge) {
      error['service_charge'] = "Please enter service charge"
      flag = false
    } else if(fields.service_charge < 0){
      error['service_charge'] = "Please enter valid service charge"
      flag = false
    }

    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      const formData = new FormData();
      if(photo){
        formData.append('photo', photo);
      }
      formData.append('fields', JSON.stringify({...fields, lang_code:'EN'}));
      configuration.postFormDataAPI({url:'sub-user/update', params:formData}).then((data) => {
        if(data.status === 200){
          history.push('/dispatcher')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }
  function handleChange(field, e) {
    if(field === 'photo'){
      setPhoto(e.target.files[0]);
      setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0])})
    } else if (field === "country_id" && e.target.value) {
      setStateOpt([]);
      setCityOpt([]);
      getStateDatas({ country_id: e.target.value, status:'active' });
      setFields({ ...fields, ["city_id"]: '', ["state_id"]: '', [field]: e.target.value });
    } else if (field === "state_id" && e.target.value) {
      setCityOpt([]);
      getCities({ state_id: e.target.value, status:'active' });
      setFields({ ...fields, ["city_id"]: '', [field]: e.target.value });
    }  else {
      setFields({ ...fields, [field]: e.target.value })
    }
    // setFields({ ...fields, [field]: e.target.value })
  }

  function cancleImage(field, e) {
    setPhoto(null);
    setFields({ ...fields, [field]: '' })
  }

  useEffect(() => {
    // console.log(props.location)
    var url = window.location.href;
    Id = url.substring(url.lastIndexOf("/") + 1);
    configuration.getAPI({url:'sub-user/list', params:{user_id:Id}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setFields(data.payload)
        getStateDatas({ country_id: data.payload.country_id, status:'active' });
        getCities({ state_id: data.payload.state_id, status:'active' });
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
    getCountryDatas();
    // console.log(configuration.baseURL);
  }, []);
  return (
    <>
      <Breadcrumb module={labels.editDispatcher} />
      <AddEditSection title="Edit Dispatcher" path="/dispatcher" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} cancleImage={cancleImage} handleSubmit={handleSubmit} />
    </>
  );
}

export default Edit;
