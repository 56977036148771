import React, { useState, useEffect } from "react";
// import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import ListSection from "../../components/ListSection";
import labels from "../../constants/LoginLogs";
import configuration from '../../config';
import { toast } from 'react-toastify';

// List all logs
function View() {
  const columns = [
    {
      title: "ID",
      field: "loginlog_id",
    },
    { title: "Name", field: "name" },
    { title: "Email", field: "email" },
    /* { title: "Role", field: "role" }, */
    { title: "Login", field: "login_date" },
    { title: "Logout", field: "logout_date" },
    { title: "IP", field: "ip" },
    /* { title: "Device", field: "device" }, */
  ];

  const btnGroups = [];
  const [data, setData] = useState([]);
  
  useEffect(() => {
    configuration.getAPI({url:'common/list-login-logs', params:{}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }, []);

  return (
    <>
      <Breadcrumb module={labels.loginLogs} />
      <ListSection
        captionTitle="View Login Log"
        columns={columns}
        data={data}
        disabledCheckbox={true}
        btnGroups={btnGroups}
      />
    </>
  );
}

export default View;
