import React, { useState, useEffect } from "react";
import configuration from '../../../config';
import DataTable from "../../../components/MDataTable";
import { toast } from 'react-toastify';

let Id = "";
// List Settings
function Summary() {
    // const [trips, setTrips] = useState([]);
    const columns = [
        { title: "ID", field: "contact_us_id"},
        {
            title: "Name",
            field: "first_name",
            render: (rowData) => (<span>{rowData.first_name} {rowData.last_name}</span>),
        },
        { title: "Email", field: "email" },
        { title: "Message", field: "message" },
        { title: "Date", field: "created_at" },
    ];
    const [data, setData] = useState([]);
    const onSelectionChange = (e) => {
        // let idArr = pluck(e, 'user_id');
        // setIds(idArr)
    }
    useEffect(() => {
        var url = window.location.href;
        Id = url.substring(url.lastIndexOf("/") + 1);
        configuration.getAPI({url:'common/list-ticket', params:{user_id:Id}}).then((data) => {
          if(data.status === 200){
            setData(data.payload)
          }
        }).catch(error => {
          return toast.error(error.message)
        });
        // console.log(configuration.baseURL);
    }, []);
    return (
       <div className="md:pl-8 sm:py-7 col-span-10">
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                            Tickets
                        </h2>
                        <p className="mt-1 max-w-2xl text-base text-gray-500 text_theme">This information is show driver tickets.</p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">                                                                                                                                                                                                                      
                        <DataTable
                        columns={columns}
                        data={data}
                        disabledCheckbox={true}
                        onSelectionChange={onSelectionChange}
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Summary;
