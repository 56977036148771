import PropTypes from "prop-types";
import React from "react";

// For Table Header Checkbox
function ColumnHeader({ title, id, name, handleChange, checked, value }) {
  return (
    <th scope="col" className="relative px-6 py-3 text-left text_theme">
      <input
        type="checkbox"
        aria-describedby="comments-description"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onClick={(e) => handleChange(e)}
        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />{" "}
      {title}
    </th>
  );
}

ColumnHeader.propTypes = {
  title: PropTypes.string,
};

ColumnHeader.defaultProps = {
  title: "",
};

export default ColumnHeader;
