import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";
// import { getAuth} from "firebase/auth";
import configuration from './config';
const firebaseConfig = {
    apiKey: configuration.webAPIKey,
    authDomain: "xlcab-21db9.firebaseapp.com",
    databaseURL: configuration.firebaseDBURL,
    projectId: 'xlcab-21db9',
    storageBucket: "xlcab-21db9.appspot.com",
    messagingSenderId: "297559489956",
    appId: "1:297559489956:web:92628e3a4675a99b159531",
    measurementId: "G-6KNL34ZE04"
}
// const firebaseConfig = {
//     apiKey: "AIzaSyB-n-cV916Jswrm4DLFz-oIsKxsbgt1sW8",
//     authDomain: "peindia-af604.firebaseapp.com",
//     databaseURL: "https://peindia-af604-default-rtdb.firebaseio.com",
//     projectId: "peindia-af604",
//     storageBucket: "peindia-af604.appspot.com",
//     messagingSenderId: "1007818613150",
//     appId: "1:1007818613150:web:b89ba82d1a2f7b4873768f",
//     measurementId: "G-32MQWBZYLH"
// };
const app = initializeApp(firebaseConfig);
const database = getDatabase(app)
// const auth = getAuth(app);
export default database;