import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import labels from "../../constants/Setting";
import configuration from '../../config';
import { findWhere } from "underscore";

// List Setting Menu
function Menu({history}) {
  const cards = [
    {
      id: 0,
      module: "General",
      code: 'SETTING',
      icon: "M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4",
      menu: [{ id: 0, title: "Settings", code: 'SETTING', redirect: "/personal-settings" }],
    },
    {
      id: 1,
      module: "Users & Control",
      code:'USER_&_CONTROL',
      icon: "M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z",
      menu: [
        { id: 0, title: "Roles", code: 'ROLE', redirect: "/role" },
        {
          id: 1,
          code: 'PERMISSION',
          title: "Permissions",
          redirect: "/permission",
        },
        { title: "Users", code: 'USERS', redirect: "/user" },
        { title: "Dispatcher", code: 'USERS', redirect: "/dispatcher" },
        { title: "Partner", code: 'USERS', redirect: "/partner" },
        { title: "Company", code: 'USERS', redirect: "/company" },
        { title: "Billing", code: 'USERS', redirect: "/billing" },
        { id: 3, title: "Login History", code: 'LOG_HISTORY', redirect: "/log" },
      ],
    },
    {
      id: 1,
      module: "Vehicles",
      icon: "M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0",
      code: 'VEHICLES',
      menu: [
        { id: 0, title: "Services", code: 'VEHICLES',redirect: "/services" },
        { id: 1, title: "Make", code: 'VEHICLES', redirect: "/vehiclemake" },
        { id: 2, title: "Model", code: 'VEHICLES', redirect: "/vehiclemodel" },
        { id: 3, title: "Year", code: 'VEHICLES', redirect: "/vehicleyear" },
        { id: 3, title: "Vehicle Management", code: 'VEHICLES', redirect: "/vehicle" },
        { id: 4, title: "Ride Options Features", code: 'VEHICLES', redirect: "/feature" },
        { id: 4, title: "Ride Options", code: 'VEHICLES', redirect: "/rideoption" },
        // { id: 5, title: "Mobility Options", code: 'VEHICLES', redirect: "/mobilityoption" },
      ],
    },
    {
      id: 1,
      module: "Price",
      icon: "M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z",
      code: 'PRICE',
      menu: [
        { id: 0, title: "Ride Price", code: 'PRICE', redirect: "/price" },
        { id: 1, title: "Rental Price", code: 'PRICE', redirect: "/rental-price" },
        { id: 2, title: "Out Station Price", code: 'PRICE', redirect: "/out-station-price" },
        // { id: 3, title: "Company Commission", code: 'PRICE', redirect: "/commission" },
        { id: 3, title: "Coupon Code", code: 'PRICE', redirect: "/coupon" },
      ],
    },
    {
      id: 2,
      module: "Content",
      code: 'CONTENT',
      icon: "M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z",
      menu: [
        { id: 0, title: "Static Pages", code: 'CONTENT', redirect: "/cms" },
        { id: 1, title: "Language Labels", code: 'CONTENT', redirect: "/label" },
        { id: 2, title: "Help Categories", code: 'CONTENT', redirect: "/help-category" },
        { id: 3, title: "Help", code: 'CONTENT', redirect: "/help" },
        { id: 4, title: "Vehicle Document", code: 'CONTENT', redirect: "/vehicle-document" },
        { id: 5, title: "Driver Document", code: 'CONTENT', redirect: "/driver-document" },
        // { id: 6, title: "Insurance", code: 'CONTENT', redirect: "/insurance" },
        { id: 7, title: "Rental Packages", code: 'CONTENT', redirect: "/rental-package" },
        {
          id: 7,
          title: "Rental Packages Description",
          code: 'CONTENT',
          redirect: "/rental-package-desc",
        },
        { id: 7, title: "Rating Criterias", code: 'CONTENT', redirect: "/rating-criterias" },
        {
          id: 7,
          title: "Cancellations Reasons Criterias",
          code: 'CONTENT',
          redirect: "/cancel-reason",
        },
        { id: 8, title: "Additional Charges", code: 'CONTENT', redirect: "/additional-charge" },
        { id: 8, title: "Banners", code: 'CONTENT', redirect: "/banner" },
        { id: 8, title: "Push Notifications", code: 'CONTENT', redirect: "/notification-log" },
      ],
    },
    {
      id: 3,
      module: "Localizations",
      code: 'LOCALIZATIONS',
      icon: "M9 20l-5.447-2.724A1 1 0 013 16.382V5.618a1 1 0 011.447-.894L9 7m0 13l6-3m-6 3V7m6 10l4.553 2.276A1 1 0 0021 18.382V7.618a1 1 0 00-.553-.894L15 4m0 13V4m0 0L9 7",
      menu: [
        { id: 0, title: "Language", code: 'LOCALIZATIONS', redirect: "/language" },
        { id: 1, title: "Currency", code: 'LOCALIZATIONS', redirect: "/currency" },
        { id: 2, title: "Tax", code: 'LOCALIZATIONS', redirect: "/tax" },
        { id: 3, title: "Country", code: 'LOCALIZATIONS', redirect: "/country" },
        { id: 4, title: "State", code: 'LOCALIZATIONS', redirect: "/state" },
        { id: 5, title: "City", code: 'LOCALIZATIONS', redirect: "/city" },
        // { id: 5, title: "Festival Area", code: 'LOCALIZATIONS', redirect: "/festival-area" },
        // { id: 6, title: "Zip Code", code: 'LOCALIZATIONS', redirect: "/zipcode" },
      ],
    },
    {
      id: 4,
      module: "Customizations",
      code: 'CUSTOMIZATIONS',
      icon: "M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z",
      menu: [
        { id: 0, title: "Email Templates", code: 'CUSTOMIZATIONS', redirect: "/email-template" },
        { id: 1, title: "Push Templates", code: 'CUSTOMIZATIONS', redirect: "/push-template" },
        { id: 2, title: "Sms Templates", code: 'CUSTOMIZATIONS', redirect: "/sms-template" },
      ],
    },
    {
      id: 5,
      module: "User Reports",
      code: 'USER_REPORTS',
      icon: "M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z",
      menu: [
        {
          id: 0,
          title: "Top Ride Passengers",
          code: 'USER_REPORTS',
          redirect: "/top-ride-passengers",
        },
        {
          id: 1,
          title: "Top Trip Accepting Drivers",
          code: 'USER_REPORTS',
          redirect: "/top-ride-drivers",
        },
        {
          id: 2,
          title: "Top Trip Booking Companies",
          code: 'USER_REPORTS',
          redirect: "/top-booking-company",
        },
        {
          id: 2,
          title: "Top Trip Booking Partners",
          code: 'USER_REPORTS',
          redirect: "/top-booking-partner",
        },
        {
          id: 2,
          title: "Top Trip Booking Dispatchers",
          code: 'USER_REPORTS',
          redirect: "/top-booking-dispatcher",
        },
      ],
    },
    {
      id: 6,
      module: "Financial Reports",
      code: 'FINANCIAL_REPORT',
      icon: "M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z",
      menu: [
        { id: 0, title: "Sales Revenue", code: 'FINANCIAL_REPORT', redirect: "/sales" },
        { id: 1, title: "Net Profits", code: 'FINANCIAL_REPORT', redirect: "/net-profit" },
        { id: 2, title: "Discount", code: 'FINANCIAL_REPORT', redirect: "/discount" },
        { id: 2, title: "Tax", code: 'FINANCIAL_REPORT', redirect: "/tax-report" },
        {
          id: 2,
          title: "Additional Charge",
          code: 'FINANCIAL_REPORT',
          redirect: "/additional-charge-report",
        },
      ],
    },
    // {
    //   id: 7,
    //   module: "Earning Reports",
    //   icon: "M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z",
    //   menu: [
    //     { id: 0, title: "Driver Earning", redirect: "/driver-earning" },
    //     { id: 1, title: "Company Earning", redirect: "/company-earning" },
    //     { id: 1, title: "Partner Earning", redirect: "/partner-earning" },
    //     { id: 1, title: "Dispatcher Earning", redirect: "/dispatcher-earning" },
    //   ],
    // },
    {
      id: 8,
      module: "Reviews & Trip Reports",
      code: 'REVIEW_REPORT',
      icon: "M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z",
      menu: [
        {
          id: 1,
          title: "Top Rate Passengers",
          code: 'REVIEW_REPORT',
          redirect: "/top-rate-passengers",
        },
        { id: 0, title: "Top Rate Drivers", code: 'REVIEW_REPORT', redirect: "/top-rate-drivers" },
        { id: 1, title: "Successful Trips", code: 'REVIEW_REPORT', redirect: "/success-trip" },
        { id: 1, title: "Discount Trips", code: 'REVIEW_REPORT', redirect: "/discount-trip" },
        { id: 1, title: "Cancelled Trips", code: 'REVIEW_REPORT', redirect: "/cancel-trip" },
      ],
    },
    {
      id: 9,
      module: "App Usage Reports",
      code: 'APP_REPORT',
      icon: "M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z",
      menu: [
        { id: 1, title: "Android Users", code: 'APP_REPORT', redirect: "/android-users" },
        { id: 0, title: "iOS Users", code: 'APP_REPORT', redirect: "/ios-users" },
      ],
    },
    // {
    //   module: "Data Administration",
    //   icon: "M4 7v10c0 2.21 3.582 4 8 4s8-1.79 8-4V7M4 7c0 2.21 3.582 4 8 4s8-1.79 8-4M4 7c0-2.21 3.582-4 8-4s8 1.79 8 4m0 5c0 2.21-3.582 4-8 4s-8-1.79-8-4",
    //   menu: [{ title: "Data Backup", redirect: "/data-backup" }],
    // },
  ];

  const [access, setAccess] = useState([]);

  useEffect(() => {
    var roleID = localStorage.getItem('role_id');
    if (roleID && roleID !== '') {
      configuration.getAPI({ url: 'common/list-access', params: { roleID } }).then((data) => {
        if (data.payload) {
          // console.log(data.payload)
          setAccess(data.payload);
        }
      }).catch(error => {
        console.log(error)
        // return toast.error(error.message)
      });
      // history.push("/dashboard")
    } else {
      // history.push("/")
    }
  }, []);

  return (
    <>
      <Breadcrumb module={labels.setting} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section
            className="mt-20 pb-32 px-4"
            aria-labelledby="contact-heading"
          >
            <div className="grid grid-cols-1 gap-y-20 lg:grid-cols-4 lg:gap-y-0 lg:gap-x-8">
              {cards.map((card) => {
                let accessData = findWhere(access, { code: card?.code });
                if (card.code ==='USER_&_CONTROL' || accessData?.data?.is_view) {
                  return (
                    <div
                      className="flex flex-col bg-white rounded-2xl shadow-xl mb-20 box_bg"
                      key={card.id}
                    >
                      <div className="flex-1 relative pt-16 px-6 pb-4 md:px-8">
                        <div className="absolute top-0 p-4 inline-block bg-indigo-600 rounded-xl shadow-lg transform -translate-y-1/2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-8 w-8 text-white"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d={card.icon}
                            />
                            {card.module === "Customizations" ? (
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d={card.icon2}
                              />
                            ) : null}
                          </svg>
                        </div>
                        <h3 className="text-xl font-medium text-gray-900 text_theme">
                          {card.module}
                        </h3>
                      </div>
                      {card.menu.map((submenu, index) => {
                        let submenuRow =
                          index % 2 === 0
                            ? "p-4 bg-gray-50 md:px-8 setting_menu_bg"
                            : "p-4 bg-white md:px-8 box_bg";
  
                        submenuRow += card.menu.length - 1 === index ? " rounded-bl-2xl rounded-br-2xl" : ''
                        let accessData = findWhere(access, { code: submenu.code });
                        if (accessData && accessData.data.is_view) {
                          return (
                            <div className={submenuRow} key={submenu.id}>
                              {submenu.redirect !== "/switch" && submenu.redirect !== '/color_theme' ?
                                <Link
                                  to={submenu.redirect}
                                  className="text-base font-medium text-indigo-700 hover:text-gray-900 setting_menu_link"
                                >
                                  {submenu.title}
                                  <span aria-hidden="true"> &rarr;</span>
                                </Link> : ""}
                            </div>
                          );
                        } else {
                          return '';
                        }
                      })}
                    </div>
                  );
                } else {
                  return '';
                }
                
              })}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Menu;
