import React, { useState, useEffect } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import { pluck } from "underscore";
import ListSection from "../../components/ListSection";
import labels from "../../constants/Company";
import configuration from '../../config';
import DeleteModel from "../../components/DeleteModel";
import { toast } from 'react-toastify';

// List all Drivers
function View({location}) {
  const columns = [
    {
      title: "ID",
      field: "id",
      render: (rowData) => (
        <Link
          className="text-indigo-600 hover:text-indigo-900"
          to={`/company/edit/${rowData.user_id}`}
        >
          {rowData.user_id}
        </Link>
      ),
    },
    {
      title: "Name",
      field: "first_name",
      render: (rowData) => (
        <span>
          {rowData.first_name} {rowData.last_name}
        </span>
      ),
    },
    { title: "Email", field: "email" },
    {
      title: "Phone",
      field: "mobile",
      render: (rowData) => (
        <span>
          {rowData.mobile_country_code} {rowData.mobile}
        </span>
      ),
    },
    { title: "Sign up date", field: "created_at" },
    {
      title: "Status",
      field: "status",
      render: (rowData) => (
        <span
          className={
            rowData.status === "active"
              ? "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-green-100 text-green-800"
              : "inline-flex items-center px-3 py-0.5 rounded-full text-basefont-medium bg-red-100 text-red-800"
          }
        >
          {" "}
          {rowData.status === "active" ? "Active" : "Inactive"}
        </span>
      ),
    },
  ];

  const btnGroups = [
    { id: 1, title: "Add New", type: "addNew", path: "/company/add" },
    { id: 2, title: "Make Active", type: "makeActive", path: "/company" },
    { id: 3, title: "Make Inactive", type: "makeInactive", path: "/company" },
    { id: 4, title: "Archive", type: "archive", path: "/company" },
    { id: 5, title: "Delete", type: "delete", path: "/company" },
  ];
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [ids, setIds] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    // console.log(props.location)
    if (location.state && location.state.is_added) {
      setShow(true)
      setTimeout(
        () => setShow(false),
        3000
      );
    }
    getDatas();
    // console.log(configuration.baseURL);
  }, [location.state]);

  function getDatas(){
    configuration.getAPI({url:'sub-user/list', params:{user_type:'company'}}).then((data) => {
      // console.log(data)
      if(data.status === 200){
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  function handleAction(type){
    if (ids.length < 1) {
      return toast.error('Please select at least one record')
    } else if (type === 'delete') {
      setShowModal(true);
      return;
    }
    const sendData={
      ids,
      type:(type === 'makeActive')?'active':(type === 'makeInactive')?'inactive':type
    }
    configuration.postAPI({url:'sub-user/action', params:sendData}).then((data) => {
      if(data.status === 200){
        getDatas()
        setIds([]);
        return toast.success((type === 'delete') ?'Record deleted successfully':'Record update successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  const onSelectionChange = (e) => {
    let idArr = pluck(e, 'user_id');
    setIds(idArr)
  }
  function handleDelete() {
    const sendData = {
      ids,
      type: 'delete'
    }
    configuration.postAPI({ url: 'passenger/action', params: sendData }).then((data) => {
      if (data.status === 200) {
        getDatas()
        setIds([]);
        setShowModal(false);
        return toast.success('Record deleted successfully')
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }
  function handleCancel() {
    setShowModal(false);
  }
  return (
    <>
      <Breadcrumb module={labels.Company} />
      <Alert
        show={show}
        setShow={setShow}
        bgColorClass="green"
        caption="Success"
        message="Record added successfully"
      />
      <DeleteModel
        mode={showModal}
        handleDelete={handleDelete}
        handleCancel={handleCancel}
      />
      <ListSection
        captionTitle="View Company"
        columns={columns}
        data={data}
        btnGroups={btnGroups}
        handleAction={handleAction}
        onSelectionChange={onSelectionChange}
      />
    </>
  );
}

export default View;
