import React, { Fragment } from 'react'
// import loader from "../../../public/image/loader.gif"


const Loader = () => {
    return (
        <Fragment>
            <div className="w-full mx-auto h-screen flex items-center justify-center">
                <img className="overflow-auto w-auto" src={`/image/loader.gif`} />
            </div>
        </ Fragment>
    )
}

export default Loader
