import React, { useState, useEffect } from "react";
import AddEditSection from "../../components/AddEditSection";
import Breadcrumb from "../../parts/Breadcrumb";
import labels from "../../constants/Label";
import configuration from '../../config';
import { toast } from 'react-toastify';
import commonConstant from "../../constants/Common";
// import { pluck } from 'underscore';
import UND from 'underscore'

// For Add Label
function Add({ history }) {
  const [fields, setFields] = useState({});
  const [languages, setLanguages] = useState([])
  const [errors, setErrors] = useState({});

  // const userTypesOpt = [
  //   { id: 0, value: "customer", label: "Customer" },
  //   { id: 2, value: "delivery_boy", label: "Delivery Boy" },
  // ];
  const [fieldArr, setFieldArr] = useState([
    { id: 0, title: "Title", name: "title", type: "TextInput" },
    { id: 1, title: "Code", name: "label_code", type: "TextInput" },
    {
      id: 2,
      title: "User Type",
      name: "user_type",
      type: "MultiDropdown",
      options: commonConstant.user_type,
    },
  ]);

  useEffect(() => {
    configuration.getAPI({ url: 'language/list', params: {status:'active'} }).then((data) => {
      if (data.status === 200) {
        setLanguages(data.payload)
        let languages = data.payload;
        let fieldArr_data = [];
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 4 + index,
            title: `Value [${language.title}]`,
            name: `value_${language.code}`,
            type: "TextInput"
          });
        })
        setFieldArr([...fieldArr, ...fieldArr_data])
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.user_type) {
      error['user_type'] = "Please select user type"
      flag = false
    }
    if (!fields.title) {
      error['title'] = "Please enter label title"
      flag = false
    }
    if (!fields.label_code) {
      error['label_code'] = "Please enter code"
      flag = false
    }
    UND.each(languages, (lang, ind) => {
      if (!fields['value_' + lang.code]) {
        error['value_' + lang.code] = "Please enter value in " + lang.title
        flag = false
      }
    })
    setErrors({ ...error })
    return flag;
  }
  
  const handleSubmit = () => {
    if (handleValidation()) {
      configuration.postAPI({ url: 'language-label/create', params: fields }).then((data) => {
        if (data.status === 200) {
          history.push('/label')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }

  }

  // function handleChange(field, e) {
  //   if (field == 'user_type') {
  //     let idArr = pluck(e, 'value');
  //     setFields({ ...fields, "selected_type": idArr })
  //   } else {
  //     setFields({ ...fields, [field]: e.target.value })
  //   }
  // }

  function handleChange(field, e) {
    if (field == 'user_type') {
      setFields({ ...fields, "user_type": e })
    } else {
      setFields({ ...fields, [field]: e.target.value })
    }
  }

  return (
    <>
      <Breadcrumb module={labels.addLabel} />
      <AddEditSection
        title="Add Label"
        path="/label"
        fields={fieldArr}
        fieldError={errors}
        fieldValue={fields}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default Add;
