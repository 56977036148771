export default {
  Driver: "Driver",
  ApproveDriver: "ApproveDriver",
  UnapprovedDriver: "UnapprovedDriver",
  EnrollmentDriver: "EnrollmentDriver",
  ActiveDriver: "ActiveDriver",
  InactiveDriver: "InactiveDriver",
  ArchiveDriver: "ArchiveDriver",
  OnlineDriver: "OnlineDriver",
  OfflineDriver: "OfflineDriver",
  AddDriver: "AddDriver",
  editDriver: "EditDriver",
  driverProfile: "DriverProfile",
};
