import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import React, {useState, useEffect} from "react";
import labels from "../../../constants/Insurance";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import {each} from 'underscore';

// For Add Role
function Add({history}) {
  const [languages, setLanguages] = useState([])
  const [fieldArr, setFieldArr] = useState([]);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    status: "",
  });
  useEffect(() => {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data1) => {
      if (data1.status === 200) {
        setLanguages(data1.payload)
        let languages = data1.payload;
        let fieldArr_data = [];
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 3 + index,
            title: `Title [${language.title}]`,
            name: `title_${language.code}`,
            type: "TextInput"
          });
        })
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 3 + index,
            title: `Benefit [${language.title}]`,
            name: `benefit_${language.code}`,
            type: "TextInput"
          });
        })
        setFieldArr([...fieldArr_data, ...fieldArr])
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  function handleValidation() {
    let flag = true;
    let error = {}
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
      if (!fields['benefit_' + lang.code]) {
        error['benefit_' + lang.code] = "Please enter benefit in " + lang.title
        flag = false
      }
    })
    // if (!fields.status) {
    //   error['status'] = "Please select status"
    //   flag = false
    // }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'insurance/create', params:fields}).then((data) => {
        if(data.status === 200){
          history.push('/insurance')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
}

function handleChange(field, e) {
  setFields({ ...fields, [field]: e.target.value })
}
  return (
    <>
      <Breadcrumb module={labels.AddInsurance} />
      <AddEditSection title="Add Insurance" path="/insurance" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
