import * as fs from 'fs';
import { CogIcon, UserCircleIcon, OfficeBuildingIcon, TranslateIcon, GlobeIcon, CreditCardIcon, AdjustmentsIcon, TicketIcon, TruckIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import { Switch, RadioGroup } from '@headlessui/react'
import Breadcrumb from "../../parts/Breadcrumb";
import _, { each } from "underscore";
import labels from "../../constants/Setting";
import common from "../../constants/Common";
import Label from "../../components/Form/Label";
import { Link } from "react-router-dom";
import { reactLocalStorage } from 'reactjs-localstorage';
import configuration from '../../config';
import { toast } from 'react-toastify';
import { findWhere, union } from 'underscore';

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const product = {
  colors: [
    { name: 'Black', bgColor: 'bg-gray-900', selectedColor: 'ring-gray-900', css_name: 'black' },
    { name: 'Red', bgColor: 'bg-red-700', selectedColor: 'ring-red-700', css_name: 'red' },
    { name: 'Orange', bgColor: 'bg-orange-600', selectedColor: 'ring-orange-600', css_name: 'orange' },
    { name: 'Green', bgColor: 'bg-green-900', selectedColor: 'ring-green-900', css_name: 'green' },
    { name: 'Blue', bgColor: 'bg-blue-900', selectedColor: 'ring-blue-900', css_name: 'blue' },
    { name: 'Purple', bgColor: 'bg-purple-900', selectedColor: 'ring-purple-900', css_name: 'purple' },
    { name: 'Pink', bgColor: 'bg-pink-500', selectedColor: 'ring-pink-500', css_name: 'pink' },
  ],
}

const product1 = {
  colors: [
    { name: 'BlackLight', bgColor: 'bg-gray-400', selectedColor: 'ring-gray-400', css_name: 'gray' },
    { name: 'RedLight', bgColor: 'bg-red-400', selectedColor: 'ring-red-400', css_name: 'red-light' },
    { name: 'OrangeLight', bgColor: 'bg-orange-400', selectedColor: 'ring-orange-400', css_name: 'orange-light' },
    { name: 'YellowLight', bgColor: 'bg-yellow-400', selectedColor: 'ring-yellow-400', css_name: 'yellow' },
    { name: 'BlueLight', bgColor: 'bg-blue-400', selectedColor: 'ring-blue-400', css_name: 'blue-light' },
    { name: 'PurpleLight', bgColor: 'bg-purple-400', selectedColor: 'ring-purple-400', css_name: 'purple-light' },
    { name: 'GreenLight', bgColor: 'bg-green-300', selectedColor: 'ring-green-400', css_name: 'green-light' },
  ],
}
// List Settings
function View() {
  const [subNavigation, setSubNavigation] = useState([
    { name: "General", href: "#", icon: UserCircleIcon, current: true },
    { name: "Company Info", href: "#", icon: OfficeBuildingIcon, current: false },
    { name: "Appearance", href: "#", icon: AdjustmentsIcon, current: false },
    { name: "Language & Region", href: "#", icon: TranslateIcon, current: false },
    /* { name: "Passnger Banner", href: "#", icon: PhotographIcon, current: false }, */
    // { name: "Passenger Referral", href: "#", icon: TicketIcon, current: false },
    // { name: "Driver Referral", href: "#", icon: TicketIcon, current: false },
    { name: "Trip Settings", href: "#", icon: TruckIcon, current: false },
    { name: "Other Settings", href: "#", icon: CogIcon, current: false },
    // { name: "Razorpay Settings", href: "#", icon: CreditCardIcon, current: false },
    // { name: "Firebase Settings", href: "#", icon: GlobeIcon, current: false },
    // { name: "Map My India Settings", href: "#", icon: GlobeIcon, current: false },
  ]);
  const [tab, setTab] = useState(0)
  const [logo, setLogo] = useState(null)
  const [favicon, setFavicon] = useState(null)
  const [banner, setBanner] = useState(null)
  const [fields, setFields] = useState({})
  const [country, setCountry] = useState([]);
  const [language, setLanguage] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [showKey, setShowKey] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [errors, setErrors] = useState(0)
  function setTabMenu(index) {
    setTab(index)
    const navigate = subNavigation;
    _.each(navigate, (element, i) => {
      let currentState = false;
      if (i === index) {
        currentState = true;
      }
      navigate[i].current = currentState;
    });
    setSubNavigation(navigate);
  }
  const [enabled, setEnabled] = useState(false)
  const [selectedColor, setSelectedColor] = useState(product.colors[0])
  useEffect(() => {
    const dark_mode = reactLocalStorage.get('dark_mode');
    setEnabled((dark_mode === 'yes') ? true : false);
    configuration.getAPI({ url: "language/list", params: { status: 'active' } }).then((data1) => {
      setLanguage(data1.payload)
      configuration.getAPI({ url: 'common/get-settings', params: {} }).then((data) => {
        if (data.status === 200) {
          let newFields = data.payload;
          newFields.logo = data.payload.logo;
          newFields.favicon = data.payload.favicon;
          if (newFields.passenger_free_ride) {
            newFields.inviter_discount_amount = newFields.passenger_free_ride.inviter_discount_amount;
            newFields.invitee_discount_amount = newFields.passenger_free_ride.invitee_discount_amount;
            each(data1.payload, single => {
              newFields[`app_caption_${single.code}`] = (newFields.passenger_free_ride) ? newFields.passenger_free_ride.app_caption[`${single.code}`] : '';
              newFields[`app_share_caption_${single.code}`] = (newFields.passenger_free_ride) ? newFields.passenger_free_ride.app_share_caption[`${single.code}`] : '';
            })
          }
          if (newFields.driver_refer) {
            newFields.inviter_bonus_amount = newFields.driver_refer.inviter_bonus_amount;
            newFields.invitee_bonus_amount = newFields.driver_refer.invitee_bonus_amount;
            newFields.no_of_rides_completion = newFields.driver_refer.no_of_rides_completion;
            each(data1.payload, single => {
              newFields[`driver_app_caption_${single.code}`] = (newFields.driver_refer) ? newFields.driver_refer.app_caption[`${single.code}`] : '';
              newFields[`driver_app_share_caption_${single.code}`] = (newFields.driver_refer) ? newFields.driver_refer.app_share_caption[`${single.code}`] : '';
            })
          }
          if (newFields.driver_timer) {
            newFields.driver_timer_type = newFields.driver_timer.type;
            newFields.driver_timer_value = newFields.driver_timer.value;
          }
          if (newFields.schedule_trip_time) {
            newFields.schedule_trip_time_type = newFields.schedule_trip_time.type;
            newFields.schedule_trip_time_value = newFields.schedule_trip_time.value;
          }
          setFields(newFields)
          document.title = data.payload.app_name
          const colorData = findWhere(union(product.colors, product1.colors), { css_name: data.payload.admin_color })
          console.log(colorData)
          if (colorData) {
            setSelectedColor(colorData)
          }
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }).catch((error) => {
      return toast.error(error.message);
    });
    configuration.getAPI({ url: "country/list", params: { status: 'active' } }).then((data) => {
      setCountry(data.payload)
    }).catch((error) => {
      return toast.error(error.message);
    });

    configuration.getAPI({ url: "currency/list", params: { status: 'active' } }).then((data) => {
      setCurrency(data.payload)
    }).catch((error) => {
      return toast.error(error.message);
    });
  }, []);
  function onChangeSwitch(e, type) {
    console.log(type);
    if (type === 'dark_mode') {
      const style = document.getElementById('style-direction');
      style.href = (e) ? '/css/tailwind-dark.css' : '/css/tailwind-light.css';
      reactLocalStorage.set('dark_mode', (e) ? "yes" : "no");
      setEnabled(e)
    } else {
      setFields({ ...fields, [type]: e })
    }
  }
  function onChangeColor(e) {
    reactLocalStorage.set('css_name', e.css_name);
    setSelectedColor(e)
    setFields({ ...fields, admin_color: e.css_name })
  }
  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }
  function validation() {
    let flag = true;
    let error = {}
    if (!fields.time_zone) {
      error['time_zone'] = "Please select time zone"
      flag = false
    }
    if (!fields.app_name) {
      error['app_name'] = "Please enter Platform name"
      flag = false
    }
    if (!fields.default_country_id) {
      error['default_country_id'] = "Please select default country"
      flag = false
    }
    if (!fields.default_country_id) {
      error['default_country_id'] = "Please select default country"
      flag = false
    }

    if (!fields.copy_right_text) {
      error['copy_right_text'] = "Please enter copy right text"
      flag = false
    }
    if (!fields.company_website) {
      error['company_website'] = "Please enter company website"
      flag = false
    }
    if (!fields.company_address) {
      error['company_address'] = "Please enter company address"
      flag = false
    }
    if (!fields.company_phone_number) {
      error['company_phone_number'] = "Please enter company phone number"
      flag = false
    }
    if (!fields.support_email) {
      error['support_email'] = "Please enter support email"
      flag = false
    }
    if (!fields.support_phone_number) {
      error['support_phone_number'] = "Please enter support phone number"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () => {
    if (validation()) {
      const formData = new FormData();
      if (logo) {
        formData.append('logo', logo);
      }
      if (favicon) {
        formData.append('favicon', favicon);
      }
      if (banner) {
        formData.append('banner', banner);
      }
      // delete fields.logo;
      // delete fields.favicon;
      delete fields.banner;
      // console.log("fields");
      console.log(fields);
      let app_caption = {}
      let app_share_caption = {}
      let driver_app_caption = {}
      let driver_app_share_caption = {}
      each(language, single => {
        app_caption[single.code] = (fields[`app_caption_${single.code}`]) ? fields[`app_caption_${single.code}`] : ''
        app_share_caption[single.code] = (fields[`app_share_caption_${single.code}`]) ? fields[`app_share_caption_${single.code}`] : ''
        driver_app_caption[single.code] = (fields[`driver_app_caption_${single.code}`]) ? fields[`driver_app_caption_${single.code}`] : ''
        driver_app_share_caption[single.code] = (fields[`driver_app_share_caption_${single.code}`]) ? fields[`driver_app_share_caption_${single.code}`] : ''
      })
      let driver_refer = fields.driver_refer;
      driver_refer.inviter_bonus_amount = fields.inviter_bonus_amount;
      driver_refer.invitee_bonus_amount = fields.invitee_bonus_amount;
      driver_refer.no_of_rides_completion = fields.no_of_rides_completion;
      driver_refer.app_caption = driver_app_caption;
      driver_refer.app_share_caption = driver_app_share_caption;

      let passenger_free_ride = fields.passenger_free_ride;
      passenger_free_ride.inviter_discount_amount = fields.inviter_discount_amount;
      passenger_free_ride.invitee_discount_amount = fields.invitee_discount_amount;
      passenger_free_ride.app_caption = app_caption;
      passenger_free_ride.app_share_caption = app_share_caption;

      let driver_timer = fields.driver_timer;
      driver_timer.type = fields.driver_timer_type;
      driver_timer.value = fields.driver_timer_value;

      let schedule_trip_time = fields.schedule_trip_time;
      schedule_trip_time.type = fields.schedule_trip_time_type;
      schedule_trip_time.value = fields.schedule_trip_time_value;

      fields.driver_refer = driver_refer;
      fields.passenger_free_ride = passenger_free_ride;
      fields.schedule_trip_time = schedule_trip_time;
      fields.driver_timer = driver_timer;

      formData.append('fields', JSON.stringify({ ...fields, lang_code: 'EN' }));
      configuration.postFormDataAPI({ url: 'common/update-settings', params: formData }).then((data) => {
        // console.log(data)
        if (data.status === 200) {
          const style = document.getElementById('style-color');
          style.href = `/css/${fields.admin_color}.css`;
          return toast.success('Setting Updated Successfully')
          // setData(data.payload)
        } else if (data.error) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        // console.log(error)
        return toast.error(error.message)
      });
    }
  }
  // function cancleImage(field, e) {
  //   if (field == 'favicon') {
  //     setFavicon(null);
  //   } else if (field == 'passenger_banner') {
  //     setBanner(null);
  //   } else {
  //     setLogo(null);
  //   }
  //   setFields({ ...fields, [field]: '' })
  // }
  // function handlePhoto(field, e) {
  //   console.log(field)
  //   if (field === 'favicon') {
  //     setFavicon(e.target.files[0]);
  //   } else if (field === 'passenger_banner') {
  //     setBanner(e.target.files[0]);
  //   } else {
  //     setLogo(e.target.files[0]);
  //   }
  //   setFields({ ...fields, [field]: URL.createObjectURL(e.target.files[0]) })
  // }

  // function handleClickEye(type, e){

  // }
  return (
    <>
      <Breadcrumb module={labels.personalSetting} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                        Personal Settings
                      </h2>
                    </div>
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white border-bottom-theme">
                    <main className="relative">
                      <div className="mx-auto">
                        <div className="bg-white rounded-lg shadow overflow-hidden box_bg">
                          <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                            <aside className="py-6 lg:col-span-2">
                              <nav className="space-y-1">
                                {subNavigation.map((item, index) => (
                                  <Link
                                    to="#"
                                    key={item.name}
                                    onClick={() => setTabMenu(index)}
                                    onKeyDown={() => setTabMenu(index)}
                                    className={classNames(
                                      item.current
                                        ? "bg-indigo-50 text-indigo-700 text-teal-700 hover:bg-teal-50 hover:text-teal-700 border-indigo-600 input_theme"
                                        : "border-transparent text-gray-500 hover:bg-gray-50 hover:text-gray-500 text_theme",
                                      "group border-l-4 px-3 py-2 flex items-center text-base xl-admin-btn"
                                    )}
                                    aria-current={item.current ? "page" : null}
                                  >
                                    <item.icon
                                      className={classNames(
                                        item.current
                                          ? "text-teal-500 group-hover:text-teal-500"
                                          : "text-gray-400 group-hover:text-gray-500",
                                        "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                                      )}
                                      aria-hidden="true"
                                    />
                                    <span className="truncate">
                                      {item.name}
                                    </span>
                                  </Link>
                                ))}
                              </nav>
                            </aside>
                            {tab === 0 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">General</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information will be displayed publicly so be careful what you share.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-8">
                                    <label htmlFor="app_name" className="block text-basefont-medium text-gray-700 text_theme">
                                      Platform Name
                                    </label>
                                    <input
                                      type="text"
                                      name="app_name"
                                      id="app_name"
                                      value={fields.app_name}
                                      onChange={(e) => handleChange('app_name', e)}
                                      autoComplete="given-name"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.app_name ? <Label title='app_name' fieldError={errors.app_name} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-8">
                                    <div className="flex items-center">
                                      <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme">
                                        Notification
                                      </label>
                                      <Switch
                                        checked={fields.is_backend_notification}
                                        onChange={(e) => onChangeSwitch(e, 'is_backend_notification')}
                                        className={classNames(
                                          fields.is_backend_notification ? 'bg-indigo-600' : 'bg-gray-200',
                                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                      >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            fields.is_backend_notification ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Default Country
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value={fields.default_country_id}
                                      onChange={(e) => handleChange('default_country_id', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      {country.map((data, key) => {
                                        return <option value={data.country_id} key={key}>{data.name}</option>
                                      })}
                                    </select>
                                    {errors.default_country_id ? <Label title='default_country_id' fieldError={errors.default_country_id} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      App Default Language
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value={fields.app_language_id}
                                      onChange={(e) => handleChange('app_language_id', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      {language.map((data, key) => {
                                        return <option value={data.language_id} key={key}>{data.title}</option>
                                      })}
                                    </select>
                                    {errors.app_language_id ? <Label title='app_language_id' fieldError={errors.app_language_id} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Default Currency
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value={fields.default_currency_id}
                                      onChange={(e) => handleChange('default_currency_id', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      {currency.map((data, key) => {
                                        return <option value={data.currency_id} key={key}>{data.name}</option>
                                      })}
                                    </select>
                                    {errors.default_currency_id ? <Label title='default_currency_id' fieldError={errors.default_currency_id} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="copy_right_text" className="block text-basefont-medium text-gray-700 text_theme">
                                      Copy Right Text
                                    </label>
                                    <input
                                      type="text"
                                      name="copy_right_text"
                                      id="copy_right_text"
                                      value={fields.copy_right_text}
                                      onChange={(e) => handleChange('copy_right_text', e)}
                                      autoComplete="copy_right_text"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.copy_right_text ? <Label title='copy_right_text' fieldError={errors.copy_right_text} /> : null}
                                  </div>

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 1 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Company Info</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  Enter your company information.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="first-name" className="block text-basefont-medium text-gray-700 text_theme">
                                      Company Website
                                    </label>
                                    <input
                                      type="text"
                                      name="company_website"
                                      id="company_website"
                                      value={fields.company_website}
                                      onChange={(e) => handleChange('company_website', e)}
                                      autoComplete="company_website"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.company_website ? <Label title='company_website' fieldError={errors.company_website} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="first-name" className="block text-basefont-medium text-gray-700 text_theme">
                                      Company Address
                                    </label>
                                    <input
                                      type="text"
                                      name="company_address"
                                      id="company_address"
                                      value={fields.company_address}
                                      onChange={(e) => handleChange('company_address', e)}
                                      autoComplete="company_address"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.company_address ? <Label title='company_address' fieldError={errors.company_address} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4 relative">
                                    <label htmlFor="email-address" className="block text-basefont-medium text-gray-700 text_theme">
                                      Company Phone
                                    </label>
                                    <input
                                      type="number"
                                      name="company_phone_number"
                                      id="company_phone_number"
                                      value={fields.company_phone_number}
                                      onChange={(e) => handleChange('company_phone_number', e)}
                                      autoComplete="company_phone_number"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.company_phone_number ? <Label title='company_phone_number' fieldError={errors.company_phone_number} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Support Email
                                    </label>
                                    <input
                                      type="text"
                                      name="support_email"
                                      id="support_email"
                                      value={fields.support_email}
                                      onChange={(e) => handleChange('support_email', e)}
                                      autoComplete="support_email"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.support_email ? <Label title='support_email' fieldError={errors.support_email} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Support Phone
                                    </label>
                                    <input
                                      type="number"
                                      name="support_phone_number"
                                      id="support_phone_number"
                                      value={fields.support_phone_number}
                                      onChange={(e) => handleChange('support_phone_number', e)}
                                      autoComplete="support_phone_number"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.support_phone_number ? <Label title='support_phone_number' fieldError={errors.support_phone_number} /> : null}
                                  </div>



                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Facebook URL
                                    </label>
                                    <input
                                      type="text"
                                      name="fb_url"
                                      id="fb_url"
                                      value={fields.fb_url}
                                      onChange={(e) => handleChange('fb_url', e)}
                                      autoComplete="fb_url"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.fb_url ? <Label title='fb_url' fieldError={errors.fb_url} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Twitter URL
                                    </label>
                                    <input
                                      type="text"
                                      name="twitter_url"
                                      id="twitter_url"
                                      value={fields.twitter_url}
                                      onChange={(e) => handleChange('twitter_url', e)}
                                      autoComplete="twitter_url"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.twitter_url ? <Label title='twitter_url' fieldError={errors.twitter_url} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Instagram URL
                                    </label>
                                    <input
                                      type="text"
                                      name="instagram_url"
                                      id="instagram_url"
                                      value={fields.instagram_url}
                                      onChange={(e) => handleChange('instagram_url', e)}
                                      autoComplete="instagram_url"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.instagram_url ? <Label title='instagram_url' fieldError={errors.instagram_url} /> : null}
                                  </div>

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 2 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Appearance</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  Choose appearance of backoffice.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-8">
                                    <label htmlFor="first-name" className="block text-basefont-medium text-gray-900 pb-4 text_theme">
                                      Choose Theme
                                    </label>
                                    <div className="mt-3 text-center sm:mt-0 sm:text-left">
                                      <h3 className="text-basefont-medium text-gray-700 text_theme">
                                        Dark Layout
                                      </h3>
                                      <div className="mt-2 mb-4">
                                        <form>
                                          <div>
                                            <RadioGroup value={selectedColor} onChange={(e) => onChangeColor(e)} className="mt-2">
                                              <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                                              <div className="flex items-center space-x-3">
                                                {product.colors.map((color) => (
                                                  <RadioGroup.Option
                                                    key={color.name}
                                                    value={color}
                                                    className={({ active, checked }) =>
                                                      classNames(
                                                        color.selectedColor,
                                                        active && checked ? 'ring ring-offset-1' : '',
                                                        !active && checked ? 'ring-2' : '',
                                                        '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                                                      )
                                                    }
                                                  >
                                                    <RadioGroup.Label as="p" className="sr-only">
                                                      {color.name}
                                                    </RadioGroup.Label>
                                                    <span
                                                      aria-hidden="true"
                                                      className={classNames(
                                                        color.bgColor,
                                                        'h-8 w-8 border border-black border-opacity-10 rounded-full'
                                                      )}
                                                    />
                                                  </RadioGroup.Option>
                                                ))}
                                              </div>
                                            </RadioGroup>
                                          </div>
                                        </form>
                                      </div>
                                      <h3 className="text-basefont-medium text-gray-700 text_theme">
                                        Light Layout
                                      </h3>
                                      <div className="mt-2">
                                        <form>
                                          <div>
                                            <RadioGroup value={selectedColor} onChange={(e) => onChangeColor(e)} className="mt-2">
                                              <RadioGroup.Label className="sr-only">Choose a color</RadioGroup.Label>
                                              <div className="flex items-center space-x-3">
                                                {product1.colors.map((color) => (
                                                  <RadioGroup.Option
                                                    key={color.name}
                                                    value={color}
                                                    className={({ active, checked }) =>
                                                      classNames(
                                                        color.selectedColor,
                                                        active && checked ? 'ring ring-offset-1' : '',
                                                        !active && checked ? 'ring-2' : '',
                                                        '-m-0.5 relative p-0.5 rounded-full flex items-center justify-center cursor-pointer focus:outline-none'
                                                      )
                                                    }
                                                  >
                                                    <RadioGroup.Label as="p" className="sr-only">
                                                      {color.name}
                                                    </RadioGroup.Label>
                                                    <span
                                                      aria-hidden="true"
                                                      className={classNames(
                                                        color.bgColor,
                                                        'h-8 w-8 border border-black border-opacity-10 rounded-full'
                                                      )}
                                                    />
                                                  </RadioGroup.Option>
                                                ))}
                                              </div>
                                            </RadioGroup>
                                          </div>
                                        </form>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <div className="flex items-center">
                                      <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-900 text_theme">
                                        Dark Mode
                                      </label>
                                      <Switch
                                        checked={enabled}
                                        onChange={(e) => onChangeSwitch(e, 'dark_mode')}
                                        className={classNames(
                                          enabled ? 'bg-indigo-600' : 'bg-gray-200',
                                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                      >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            enabled ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-basefont-medium text-gray-700 text_theme">
                                      Font
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value={fields.font_family}
                                      onChange={(e) => handleChange('font_family', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      <option value="Arial">Arial</option>
                                      <option value="Helvetica">Helvetica</option>
                                      <option value="Verdana">Verdana</option>
                                      <option value="serif">serif</option>
                                      <option value="FreeMono">FreeMono</option>
                                      <option value="Futara">Futara</option>
                                      <option value="Geneva">Geneva</option>
                                    </select>
                                    {errors.font_family ? <Label title='font_family' fieldError={errors.font_family} /> : null}
                                  </div>

                                  {/* <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-basefont-medium text-gray-700 text_theme">
                                      Upload Logo
                                    </label>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                      {(fields.logo && fields.logo !== '') ?
                                        <div className="space-y-1">
                                          <img name="logo" id="logo" src={fields.logo} width="100" height="100" />
                                          <button
                                            type="button"
                                            onClick={(e) => cancleImage('logo', e)}
                                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                          > Remove</button>
                                        </div> :
                                        <div className="space-y-1 text-center">
                                          <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                          <div className="flex text-base text-gray-600">
                                            <label
                                              htmlFor="file-upload"
                                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                            >
                                              <span>Upload a file</span>
                                              <input id="file-upload" name="file-upload" type="file" onChange={(e) => handlePhoto('logo', e)} accept="image/*" className="sr-only" />
                                            </label>
                                            <p className="pl-1 text_theme">or drag and drop</p>
                                          </div>
                                          <p className="text-xs text-gray-500 text_theme">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                      }
                                    </div>
                                  </div> */}

                                  {/* <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Upload Favicon
                                    </label>
                                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                      {(fields.favicon && fields.favicon !== '') ?
                                        <div className="space-y-1">
                                          <img name="favicon" id="favicon" src={fields.favicon} width="100" height="100" />
                                          <button
                                            type="button"
                                            onClick={(e) => cancleImage('favicon', e)}
                                            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                          > Remove</button>
                                        </div>
                                        : <div className="space-y-1 text-center">
                                          <svg
                                            className="mx-auto h-12 w-12 text-gray-400"
                                            stroke="currentColor"
                                            fill="none"
                                            viewBox="0 0 48 48"
                                            aria-hidden="true"
                                          >
                                            <path
                                              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                              strokeWidth={2}
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            />
                                          </svg>
                                          <div className="flex text-base text-gray-600">
                                            <label
                                              htmlFor="file-favicon"
                                              className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                            >
                                              <span>Upload a file</span>
                                              <input id="file-favicon" name="file-favicon" type="file" onChange={(e) => handlePhoto('favicon', e)} accept="image/*" className="sr-only" />
                                            </label>
                                            <p className="pl-1 text_theme">or drag and drop</p>
                                          </div>
                                          <p className="text-xs text-gray-500 text_theme">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                      }
                                    </div>
                                  </div> */}

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/*  <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 3 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Language & Region</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information will be displayed publicly so be careful what you share.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-basefont-medium text-gray-700 text_theme">
                                      TimeZone
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value={fields.time_zone}
                                      onChange={(e) => handleChange('time_zone', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      <option value="-12:00">(GMT -12:00) Eniwetok, Kwajalein</option>
                                      <option value="-11:00">(GMT -11:00) Midway Island, Samoa</option>
                                      <option value="-10:00">(GMT -10:00) Hawaii</option>
                                      <option value="-09:50">(GMT -9:30) Taiohae</option>
                                      <option value="-09:00">(GMT -9:00) Alaska</option>
                                      <option value="-08:00">(GMT -8:00) Pacific Time (US &amp; Canada)</option>
                                      <option value="-07:00">(GMT -7:00) Mountain Time (US &amp; Canada)</option>
                                      <option value="-06:00">(GMT -6:00) Central Time (US &amp; Canada), Mexico City</option>
                                      <option value="-05:00">(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima</option>
                                      <option value="-04:50">(GMT -4:30) Caracas</option>
                                      <option value="-04:00">(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz</option>
                                      <option value="-03:50">(GMT -3:30) Newfoundland</option>
                                      <option value="-03:00">(GMT -3:00) Brazil, Buenos Aires, Georgetown</option>
                                      <option value="-02:00">(GMT -2:00) Mid-Atlantic</option>
                                      <option value="-01:00">(GMT -1:00) Azores, Cape Verde Islands</option>
                                      <option value="+00:00">(GMT) Western Europe Time, London, Lisbon, Casablanca</option>
                                      <option value="+01:00">(GMT +1:00) Brussels, Copenhagen, Madrid, Paris</option>
                                      <option value="+02:00">(GMT +2:00) Kaliningrad, South Africa</option>
                                      <option value="+03:00">(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg</option>
                                      <option value="+03:50">(GMT +3:30) Tehran</option>
                                      <option value="+04:00">(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi</option>
                                      <option value="+04:50">(GMT +4:30) Kabul</option>
                                      <option value="+05:00">(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent</option>
                                      <option value="+05:50">(GMT +5:30) Bombay, Calcutta, Madras, New Delhi</option>
                                      <option value="+05:75">(GMT +5:45) Kathmandu, Pokhara</option>
                                      <option value="+06:00">(GMT +6:00) Almaty, Dhaka, Colombo</option>
                                      <option value="+06:50">(GMT +6:30) Yangon, Mandalay</option>
                                      <option value="+07:00">(GMT +7:00) Bangkok, Hanoi, Jakarta</option>
                                      <option value="+08:00">(GMT +8:00) Beijing, Perth, Singapore, Hong Kong</option>
                                      <option value="+08:75">(GMT +8:45) Eucla</option>
                                      <option value="+09:00">(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk</option>
                                      <option value="+09:50">(GMT +9:30) Adelaide, Darwin</option>
                                      <option value="+10:00">(GMT +10:00) Eastern Australia, Guam, Vladivostok</option>
                                      <option value="+10:50">(GMT +10:30) Lord Howe Island</option>
                                      <option value="+11:00">(GMT +11:00) Magadan, Solomon Islands, New Caledonia</option>
                                      <option value="+11:50">(GMT +11:30) Norfolk Island</option>
                                      <option value="+12:00">(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka</option>
                                      <option value="+12:75">(GMT +12:45) Chatham Islands</option>
                                      <option value="+13:00">(GMT +13:00) Apia, Nukualofa</option>
                                      <option value="+14:00">(GMT +14:00) Line Islands, Tokelau</option>
                                    </select>
                                    {errors.time_zone ? <Label title='time_zone' fieldError={errors.time_zone} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Language
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value={fields.default_language_id}
                                      onChange={(e) => handleChange('default_language_id', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      {language.map((data, key) => {
                                        return <option value={data.language_id} key={key}>{data.title}</option>
                                      })}
                                    </select>
                                    {errors.default_language_id ? <Label title='default_language_id' fieldError={errors.default_language_id} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-basefont-medium text-gray-700 text_theme">
                                      Date Format
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value={fields.date_format}
                                      onChange={(e) => handleChange('date_format', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      <option value="DD-MM-YYYY">DD-MM-YYYY [01-08-2019]</option>
                                      <option value="DD/MM/YYYY">DD/MM/YYYY [01/08/2019]</option>
                                      <option value="DD-MMM-YYYY">DD-MMM-YYYY [01-Aug-2019]</option>
                                      <option value="DD-MMM-YY">DD-MMM-YY [01-Aug-19]</option>
                                      <option value="YYYY-MM-DD">YYYY-MM-DD [2019-08-01]</option>
                                      <option value="MM-DD-YY">MM-DD-YY [08-01-19]</option>
                                      <option value="MMMM Do, YYYY">MMMM Do, YYYY [August 1st, 2019]</option>
                                      <option value="M/D/YYYY">M/D/YYYY [8/1/2019]</option>
                                    </select>
                                    {errors.date_format ? <Label title='date_format' fieldError={errors.date_format} /> : null}
                                  </div>
                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {/* {tab === 4 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Passenger Banner</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information will be displayed publicly so be careful what you share.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="col-span-6 sm:col-span-4">
                                      <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                        Upload Banner
                                      </label>
                                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                        {(fields.passenger_banner && fields.passenger_banner !== '')?
                                          <div className="space-y-1">
                                            <img name="passenger_banner" id="passenger_banner" src={fields.passenger_banner} width="400" height="100"/>
                                            <button 
                                              type="button" 
                                              onClick={(e)=>cancleImage('passenger_banner', e)}
                                              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 xl-admin-btn"
                                            > Remove</button>
                                          </div>
                                          :<div className="space-y-1 text-center">
                                            <svg
                                              className="mx-auto h-12 w-12 text-gray-400"
                                              stroke="currentColor"
                                              fill="none"
                                              viewBox="0 0 48 48"
                                              aria-hidden="true"
                                            >
                                              <path
                                                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                                strokeWidth={2}
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                            <div className="flex text-base text-gray-600">
                                              <label
                                                htmlFor="file-passenger_banner"
                                                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                              >
                                                <span>Upload a file</span>
                                                <input id="file-passenger_banner" name="file-passenger_banner" type="file" onChange={(e)=>handlePhoto('passenger_banner', e)} accept="image/*" className="sr-only" />
                                              </label>
                                              <p className="pl-1 text_theme">or drag and drop</p>
                                            </div>
                                            <p className="text-xs text-gray-500 text_theme">PNG, JPG, GIF up to 10MB</p>
                                          </div>
                                        }
                                      </div><br/>
                                      <div className="col-span-6 sm:col-span-8">
                                        <label htmlFor="app_name" className="block text-basefont-medium text-gray-700 text_theme">
                                          Banner Redirect URL
                                        </label>
                                        <input
                                          type="text"
                                          name="passenger_banner_url"
                                          id="passenger_banner_url"
                                          value = {fields.passenger_banner_url}
                                          onChange={(e)=>handleChange('passenger_banner_url', e)}
                                          autoComplete="given-name"
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                        {errors.passenger_banner_url ? <Label title='passenger_banner_url' fieldError={errors.passenger_banner_url} /> : null}
                                      </div>
                                    </div> <br/>
                                    <div className="flex justify-end">
                                      <button
                                        type="button"
                                        onClick={(e)=>handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''} */}
                            {/* {tab === 4 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Passenger Referral Settings</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information is show passenger referral settings.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-8">
                                    <div className="flex items-center">
                                      <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme">
                                        Passenger Free Ride
                                      </label>
                                      <Switch
                                        checked={fields.is_passenger_free_ride}
                                        onChange={(e) => onChangeSwitch(e, 'is_passenger_free_ride')}
                                        className={classNames(
                                          fields.is_passenger_free_ride ? 'bg-indigo-600' : 'bg-gray-200',
                                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                      >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            fields.is_passenger_free_ride ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="inviter_discount_amount" className="block text-basefont-medium text-gray-700 text_theme">
                                      Inviter Discount Amount
                                    </label>
                                    <input
                                      type="number"
                                      name="inviter_discount_amount"
                                      id="inviter_discount_amount"
                                      value={fields.inviter_discount_amount}
                                      onChange={(e) => handleChange('inviter_discount_amount', e)}
                                      autoComplete="inviter_discount_amount"
                                      disabled={!fields.is_passenger_free_ride}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.inviter_discount_amount ? <Label title='inviter_discount_amount' fieldError={errors.inviter_discount_amount} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="invitee_discount_amount" className="block text-basefont-medium text-gray-700 text_theme">
                                      Invitee Discount Amount
                                    </label>
                                    <input
                                      type="number"
                                      name="invitee_discount_amount"
                                      id="invitee_discount_amount"
                                      value={fields.invitee_discount_amount}
                                      onChange={(e) => handleChange('invitee_discount_amount', e)}
                                      autoComplete="invitee_discount_amount"
                                      disabled={!fields.is_passenger_free_ride}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.invitee_discount_amount ? <Label title='invitee_discount_amount' fieldError={errors.invitee_discount_amount} /> : null}
                                  </div>
                                  {language.map((single, index) => (
                                    <div className="col-span-6 sm:col-span-4">
                                      <label htmlFor={`app_caption_${single.code}`} className="block text-basefont-medium text-gray-700 text_theme">
                                        App Caption [{single.title}]
                                      </label>
                                      <input
                                        type="text"
                                        name={`app_caption_${single.code}`}
                                        id={`app_caption_${single.code}`}
                                        value={fields[`app_caption_${single.code}`]}
                                        onChange={(e) => handleChange(`app_caption_${single.code}`, e)}
                                        autoComplete={`app_caption_${single.code}`}
                                        disabled={!fields.is_passenger_free_ride}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                      />
                                    </div>
                                  ))}

                                  {language.map((single, index) => (
                                    <div className="col-span-6 sm:col-span-4">
                                      <label htmlFor={`app_share_caption_${single.code}`} className="block text-basefont-medium text-gray-700 text_theme">
                                        App Share Caption [{single.title}]
                                      </label>
                                      <input
                                        type="text"
                                        name={`app_share_caption_${single.code}`}
                                        id={`app_share_caption_${single.code}`}
                                        value={fields[`app_share_caption_${single.code}`]}
                                        onChange={(e) => handleChange(`app_share_caption_${single.code}`, e)}
                                        autoComplete={`app_share_caption_${single.code}`}
                                        disabled={!fields.is_passenger_free_ride}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                      />
                                    </div>
                                  ))}

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> 
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 5 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Driver Referral Settings</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information is show driver referral settings.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-8">
                                    <div className="flex items-center">
                                      <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme">
                                        Driver Refer
                                      </label>
                                      <Switch
                                        checked={fields.is_driver_refer}
                                        onChange={(e) => onChangeSwitch(e, 'is_driver_refer')}
                                        className={classNames(
                                          fields.is_driver_refer ? 'bg-indigo-600' : 'bg-gray-200',
                                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                      >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            fields.is_driver_refer ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="inviter_bonus_amount" className="block text-basefont-medium text-gray-700 text_theme">
                                      Inviter Bonus Amount
                                    </label>
                                    <input
                                      type="number"
                                      name="inviter_bonus_amount"
                                      id="inviter_bonus_amount"
                                      value={fields.inviter_bonus_amount}
                                      onChange={(e) => handleChange('inviter_bonus_amount', e)}
                                      autoComplete="inviter_bonus_amount"
                                      disabled={!fields.is_driver_refer}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.inviter_bonus_amount ? <Label title='inviter_bonus_amount' fieldError={errors.inviter_bonus_amount} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="invitee_bonus_amount" className="block text-basefont-medium text-gray-700 text_theme">
                                      Invitee Bonus Amount
                                    </label>
                                    <input
                                      type="number"
                                      name="invitee_bonus_amount"
                                      id="invitee_bonus_amount"
                                      value={fields.invitee_bonus_amount}
                                      onChange={(e) => handleChange('invitee_bonus_amount', e)}
                                      autoComplete="invitee_bonus_amount"
                                      disabled={!fields.is_driver_refer}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.invitee_bonus_amount ? <Label title='invitee_bonus_amount' fieldError={errors.invitee_bonus_amount} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="no_of_rides_completion" className="block text-basefont-medium text-gray-700 text_theme">
                                      Number of Rides Completion
                                    </label>
                                    <input
                                      type="number"
                                      name="no_of_rides_completion"
                                      id="no_of_rides_completion"
                                      value={fields.no_of_rides_completion}
                                      onChange={(e) => handleChange('no_of_rides_completion', e)}
                                      autoComplete="no_of_rides_completion"
                                      disabled={!fields.is_driver_refer}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.no_of_rides_completion ? <Label title='no_of_rides_completion' fieldError={errors.no_of_rides_completion} /> : null}
                                  </div>
                                  {language.map((single, index) => (
                                    <div className="col-span-6 sm:col-span-4">
                                      <label htmlFor={`driver_app_caption_${single.code}`} className="block text-basefont-medium text-gray-700 text_theme">
                                        App Caption [{single.title}]
                                      </label>
                                      <input
                                        type="text"
                                        name={`driver_app_caption_${single.code}`}
                                        id={`driver_app_caption_${single.code}`}
                                        value={fields[`driver_app_caption_${single.code}`]}
                                        onChange={(e) => handleChange(`driver_app_caption_${single.code}`, e)}
                                        autoComplete={`driver_app_caption_${single.code}`}
                                        disabled={!fields.is_driver_refer}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                      />
                                    </div>
                                  ))}

                                  {language.map((single, index) => (
                                    <div className="col-span-6 sm:col-span-4">
                                      <label htmlFor={`driver_app_share_caption_${single.code}`} className="block text-basefont-medium text-gray-700 text_theme">
                                        App Share Caption [{single.title}]
                                      </label>
                                      <input
                                        type="text"
                                        name={`driver_app_share_caption_${single.code}`}
                                        id={`driver_app_share_caption_${single.code}`}
                                        value={fields[`driver_app_share_caption_${single.code}`]}
                                        onChange={(e) => handleChange(`driver_app_share_caption_${single.code}`, e)}
                                        autoComplete={`driver_app_share_caption_${single.code}`}
                                        disabled={!fields.is_driver_refer}
                                        className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                      />
                                    </div>
                                  ))}

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> 
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''} */}
                            {tab === 4 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Trip Settings</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information is show trip settings.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">


                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-basefont-medium text-gray-700 text_theme">
                                      Driver Timer Type
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value={fields.driver_timer_type}
                                      onChange={(e) => handleChange('driver_timer_type', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      <option value="minutes">Minutes</option>
                                      <option value="seconds">Seconds</option>
                                    </select>
                                    {errors.driver_timer_type ? <Label title='driver_timer_type' fieldError={errors.driver_timer_type} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="driver_timer_value" className="block text-basefont-medium text-gray-700 text_theme">
                                      Driver Timer value
                                    </label>
                                    <input
                                      type="number"
                                      name="driver_timer_value"
                                      id="driver_timer_value"
                                      value={fields.driver_timer_value}
                                      onChange={(e) => handleChange('driver_timer_value', e)}
                                      autoComplete="driver_timer_value"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.driver_timer_value ? <Label title='driver_timer_value' fieldError={errors.driver_timer_value} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="email-address" className="block text-basefont-medium text-gray-700 text_theme">
                                      Schedule Trip Timer Type
                                    </label>
                                    <select
                                      id="country"
                                      name="country"
                                      value={fields.schedule_trip_time_type}
                                      onChange={(e) => handleChange('schedule_trip_time_type', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      <option value="minutes">Minutes</option>
                                      <option value="seconds">Seconds</option>
                                    </select>
                                    {errors.schedule_trip_time_type ? <Label title='schedule_trip_time_type' fieldError={errors.schedule_trip_time_type} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="schedule_trip_time_value" className="block text-basefont-medium text-gray-700 text_theme">
                                      Schedule Trip Time value
                                    </label>
                                    <input
                                      type="number"
                                      name="schedule_trip_time_value"
                                      id="schedule_trip_time_value"
                                      value={fields.schedule_trip_time_value}
                                      onChange={(e) => handleChange('schedule_trip_time_value', e)}
                                      autoComplete="schedule_trip_time_value"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.schedule_trip_time_value ? <Label title='schedule_trip_time_value' fieldError={errors.schedule_trip_time_value} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="radius" className="block text-basefont-medium text-gray-700 text_theme">
                                      Trip Radius
                                    </label>
                                    <input
                                      type="number"
                                      name="radius"
                                      id="radius"
                                      value={fields.radius}
                                      onChange={(e) => handleChange('radius', e)}
                                      autoComplete="radius"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.radius ? <Label title='radius' fieldError={errors.radius} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="radius" className="block text-basefont-medium text-gray-700 text_theme">
                                      Firebase Location Distance Filter (in meter)
                                    </label>
                                    <input
                                      type="number"
                                      name="distance_filter"
                                      id="distance_filter"
                                      value={fields.distance_filter}
                                      onChange={(e) => handleChange('distance_filter', e)}
                                      autoComplete="distance_filter"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.distance_filter ? <Label title='distance_filter' fieldError={errors.distance_filter} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="radius" className="block text-basefont-medium text-gray-700 text_theme">
                                      Firebase Time Interval Normal
                                    </label>
                                    <input
                                      type="number"
                                      name="firebase_time_interval_normal"
                                      id="firebase_time_interval_normal"
                                      value={fields.firebase_time_interval_normal}
                                      onChange={(e) => handleChange('firebase_time_interval_normal', e)}
                                      autoComplete="firebase_time_interval_normal"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.firebase_time_interval_normal ? <Label title='firebase_time_interval_normal' fieldError={errors.firebase_time_interval_normal} /> : null}
                                  </div>
                                  {/* <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="radius" className="block text-basefont-medium text-gray-700 text_theme">
                                      Firebase Time Interval in Running Trip
                                    </label>
                                    <input
                                      type="number"
                                      name="firebase_time_interval_in_trip"
                                      id="firebase_time_interval_in_trip"
                                      value={fields.firebase_time_interval_in_trip}
                                      onChange={(e) => handleChange('firebase_time_interval_in_trip', e)}
                                      autoComplete="firebase_time_interval_in_trip"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.firebase_time_interval_in_trip ? <Label title='firebase_time_interval_in_trip' fieldError={errors.firebase_time_interval_in_trip} /> : null}
                                  </div> */}
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="max_drivers_trip" className="block text-basefont-medium text-gray-700 text_theme">
                                      Maximum Drivers for single trip
                                    </label>
                                    <input
                                      type="number"
                                      name="max_drivers_trip"
                                      id="max_drivers_trip"
                                      value={fields.max_drivers_trip}
                                      onChange={(e) => handleChange('max_drivers_trip', e)}
                                      autoComplete="max_drivers_trip"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.max_drivers_trip ? <Label title='max_drivers_trip' fieldError={errors.max_drivers_trip} /> : null}
                                  </div>

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 5 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Other Settings</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information is show Other settings.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">

                                  <div className="col-span-6 sm:col-span-8">
                                    <div className="flex items-center">
                                      <label htmlFor="push-everything" className="mr-3 block text-basefont-medium text-gray-700 text_theme">
                                        OTP Auto Fill
                                      </label>
                                      <Switch
                                        checked={fields.is_otp_auto_fill}
                                        onChange={(e) => onChangeSwitch(e, 'is_otp_auto_fill')}
                                        className={classNames(
                                          fields.is_otp_auto_fill ? 'bg-indigo-600' : 'bg-gray-200',
                                          'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                                        )}
                                      >
                                        <span className="sr-only">Use setting</span>
                                        <span
                                          aria-hidden="true"
                                          className={classNames(
                                            fields.is_otp_auto_fill ? 'translate-x-5' : 'translate-x-0',
                                            'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                          )}
                                        />
                                      </Switch>
                                    </div>
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="passenger_allow_cancellation_time" className="block text-basefont-medium text-gray-700 text_theme">
                                      Allow cancellation time for passenger
                                    </label>
                                    <input
                                      type="number"
                                      name="passenger_allow_cancellation_time"
                                      id="passenger_allow_cancellation_time"
                                      value={fields.passenger_allow_cancellation_time}
                                      onChange={(e) => handleChange('passenger_allow_cancellation_time', e)}
                                      autoComplete="passenger_allow_cancellation_time"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.passenger_allow_cancellation_time ? <Label title='passenger_allow_cancellation_time' fieldError={errors.passenger_allow_cancellation_time} /> : null}
                                  </div>

                                  {/* <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="passenger_cancellation_fine" className="block text-basefont-medium text-gray-700 text_theme">
                                      Cancellation fine for passenger
                                    </label>
                                    <input
                                      type="number"
                                      name="passenger_cancellation_fine"
                                      id="passenger_cancellation_fine"
                                      value={fields.passenger_cancellation_fine}
                                      onChange={(e) => handleChange('passenger_cancellation_fine', e)}
                                      autoComplete="passenger_cancellation_fine"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.passenger_cancellation_fine ? <Label title='passenger_cancellation_fine' fieldError={errors.passenger_cancellation_fine} /> : null}
                                  </div> */}

                                  {/* <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="schedule_trip_time_value" className="block text-basefont-medium text-gray-700 text_theme">
                                      Cancellation fine for drivers when wallet is in Minus =
                                    </label>
                                    <input
                                      type="number"
                                      name="driver_cancellation_fine_for_wallet"
                                      id="driver_cancellation_fine_for_wallet"
                                      value={fields.driver_cancellation_fine_for_wallet}
                                      onChange={(e) => handleChange('driver_cancellation_fine_for_wallet', e)}
                                      autoComplete="driver_cancellation_fine_for_wallet"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.driver_cancellation_fine_for_wallet ? <Label title='driver_cancellation_fine_for_wallet' fieldError={errors.driver_cancellation_fine_for_wallet} /> : null}
                                  </div> */}

                                  {/* <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="driver_cancellation_fine" className="block text-basefont-medium text-gray-700 text_theme">
                                      Cancellation fine for drivers
                                    </label>
                                    <input
                                      type="number"
                                      name="driver_cancellation_fine"
                                      id="driver_cancellation_fine"
                                      value={fields.driver_cancellation_fine}
                                      onChange={(e) => handleChange('driver_cancellation_fine', e)}
                                      autoComplete="driver_cancellation_fine"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.driver_cancellation_fine ? <Label title='driver_cancellation_fine' fieldError={errors.driver_cancellation_fine} /> : null}
                                  </div> */}
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      SOS
                                    </label>
                                    <input
                                      type="number"
                                      name="sos"
                                      id="sos"
                                      value={fields.sos}
                                      onChange={(e) => handleChange('sos', e)}
                                      autoComplete="sos"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.sos ? <Label title='sos' fieldError={errors.sos} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="country" className="block text-basefont-medium text-gray-700 text_theme">
                                      Enforcement Number
                                    </label>
                                    <input
                                      type="number"
                                      name="enforcement_number"
                                      id="enforcement_number"
                                      value={fields.enforcement_number}
                                      onChange={(e) => handleChange('enforcement_number', e)}
                                      autoComplete="enforcement_number"
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.enforcement_number ? <Label title='enforcement_number' fieldError={errors.enforcement_number} /> : null}
                                  </div>
                                  {/* <div className="col-span-6 sm:col-span-4 relative">
                                    <label htmlFor="google_api_key" className="block text-basefont-medium text-gray-700 text_theme">
                                      Google API Key
                                    </label>
                                    <input
                                      type={(showKey) ? "text" : "password"}
                                      name="google_api_key"
                                      id="google_api_key"
                                      value={fields.google_api_key}
                                      onChange={(e) => handleChange('google_api_key', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    <span className="password__show cursor-pointer" onClick={() => setShowKey(!showKey)}>{showKey ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>}</span>
                                    {errors.google_api_key ? <Label title='google_api_key' fieldError={errors.google_api_key} /> : null}
                                  </div> */}
                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 8 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Razorpay Settings</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information is show Razorpay settings.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="razor_pay_platform" className="block text-basefont-medium text-gray-700 text_theme">
                                      Razorpay Platform
                                    </label>
                                    <select
                                      id="country"
                                      name="razor_pay_platform"
                                      value={fields.razor_pay_platform}
                                      onChange={(e) => handleChange('razor_pay_platform', e)}
                                      autoComplete="country-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      <option value="sandbox">Sandbox</option>
                                      <option value="production">Production</option>
                                    </select>
                                    {errors.razor_pay_platform ? <Label title='razor_pay_platform' fieldError={errors.razor_pay_platform} /> : null}
                                  </div>
                                  {(fields.razor_pay_platform === 'production') ?
                                    <>
                                      <div className="col-span-6 sm:col-span-4 relative">
                                        <label htmlFor="razor_pay_key_production" className="block text-basefont-medium text-gray-700 text_theme">
                                          Razorpay Key Production
                                        </label>
                                        <input
                                          type={(showKey) ? "text" : "password"}
                                          name="razor_pay_key_production"
                                          id="razor_pay_key_production"
                                          value={fields.razor_pay_key_production}
                                          onChange={(e) => handleChange('razor_pay_key_production', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                        <span className="password__show cursor-pointer" onClick={() => setShowKey(!showKey)}>{showKey ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                        </svg>}</span>
                                        {errors.razor_pay_key_production ? <Label title='razor_pay_key_production' fieldError={errors.razor_pay_key_production} /> : null}
                                      </div>
                                      <div className="col-span-6 sm:col-span-4 relative">
                                        <label htmlFor="razor_pay_key_secret_production" className="block text-basefont-medium text-gray-700 text_theme">
                                          Razorpay Key Secret Production
                                        </label>
                                        <input
                                          type={(showSecret) ? "text" : "password"}
                                          name="razor_pay_key_secret_production"
                                          id="razor_pay_key_secret_production"
                                          value={fields.razor_pay_key_secret_production}
                                          onChange={(e) => handleChange('razor_pay_key_secret_production', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                        <span className="password__show cursor-pointer" onClick={() => setShowSecret(!showSecret)}>{showSecret ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                        </svg>}</span>
                                        {errors.razor_pay_key_secret_production ? <Label title='razor_pay_key_secret_production' fieldError={errors.razor_pay_key_secret_production} /> : null}
                                      </div>
                                      <div className="col-span-6 sm:col-span-4">
                                        <label htmlFor="razor_pay_mode_production" className="block text-basefont-medium text-gray-700 text_theme">
                                          Razorpay Mode Production
                                        </label>
                                        <select
                                          id="country"
                                          name="razor_pay_mode_production"
                                          value={fields.razor_pay_mode_production}
                                          onChange={(e) => handleChange('razor_pay_mode_production', e)}
                                          autoComplete="country-name"
                                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                        >
                                          <option value="NEFT">NEFT</option>
                                          <option value="RTGS">RTGS</option>
                                          <option value="IMPS">IMPS</option>
                                        </select>
                                        {errors.razor_pay_mode_production ? <Label title='razor_pay_mode_production' fieldError={errors.razor_pay_mode_production} /> : null}
                                      </div>
                                      <div className="col-span-6 sm:col-span-4">
                                        <label htmlFor="razor_virtual_account_no_production" className="block text-basefont-medium text-gray-700 text_theme">
                                          Razor Virtual Account Number Production
                                        </label>
                                        <input
                                          type="number"
                                          name="razor_virtual_account_no_production"
                                          id="razor_virtual_account_no_production"
                                          value={fields.razor_virtual_account_no_production}
                                          onChange={(e) => handleChange('razor_virtual_account_no_production', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                        {errors.razor_virtual_account_no_production ? <Label title='razor_virtual_account_no_production' fieldError={errors.razor_virtual_account_no_production} /> : null}
                                      </div>
                                    </> : <>
                                      <div className="col-span-6 sm:col-span-4 relative">
                                        <label htmlFor="razor_pay_key" className="block text-basefont-medium text-gray-700 text_theme">
                                          Razorpay Key
                                        </label>
                                        <input
                                          type={(showKey) ? "text" : "password"}
                                          name="razor_pay_key"
                                          id="razor_pay_key"
                                          value={fields.razor_pay_key}
                                          onChange={(e) => handleChange('razor_pay_key', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                        <span className="password__show cursor-pointer" onClick={() => setShowKey(!showKey)}>{showKey ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                        </svg>}</span>
                                        {errors.razor_pay_key ? <Label title='razor_pay_key' fieldError={errors.razor_pay_key} /> : null}
                                      </div>
                                      <div className="col-span-6 sm:col-span-4 relative">
                                        <label htmlFor="razor_pay_key_secret" className="block text-basefont-medium text-gray-700 text_theme">
                                          Razorpay Key Secret
                                        </label>
                                        <input
                                          type={(showSecret) ? "text" : "password"}
                                          name="razor_pay_key_secret"
                                          id="razor_pay_key_secret"
                                          value={fields.razor_pay_key_secret}
                                          onChange={(e) => handleChange('razor_pay_key_secret', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                        <span className="password__show cursor-pointer" onClick={() => setShowSecret(!showSecret)}>{showSecret ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                          <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                        </svg>}</span>
                                        {errors.razor_pay_key_secret ? <Label title='razor_pay_key_secret' fieldError={errors.razor_pay_key_secret} /> : null}
                                      </div>
                                      <div className="col-span-6 sm:col-span-4">
                                        <label htmlFor="razor_pay_mode" className="block text-basefont-medium text-gray-700 text_theme">
                                          Razorpay Mode
                                        </label>
                                        <select
                                          id="country"
                                          name="razor_pay_mode"
                                          value={fields.razor_pay_mode}
                                          onChange={(e) => handleChange('razor_pay_mode', e)}
                                          autoComplete="country-name"
                                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                        >
                                          <option value="NEFT">NEFT</option>
                                          <option value="RTGS">RTGS</option>
                                          <option value="IMPS">IMPS</option>
                                        </select>
                                        {errors.razor_pay_mode ? <Label title='razor_pay_mode' fieldError={errors.razor_pay_mode} /> : null}
                                      </div>
                                      <div className="col-span-6 sm:col-span-4">
                                        <label htmlFor="razor_virtual_account_no" className="block text-basefont-medium text-gray-700 text_theme">
                                          Razor Virtual Account Number
                                        </label>
                                        <input
                                          type="number"
                                          name="razor_virtual_account_no"
                                          id="razor_virtual_account_no"
                                          value={fields.razor_virtual_account_no}
                                          onChange={(e) => handleChange('razor_virtual_account_no', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                        {errors.razor_virtual_account_no ? <Label title='razor_virtual_account_no' fieldError={errors.razor_virtual_account_no_production} /> : null}
                                      </div>
                                    </>
                                  }

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 9 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Firebase Settings</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information is show Google settings.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">
                                  <div className="col-span-6 sm:col-span-4 relative">
                                    <label htmlFor="google_api_key" className="block text-basefont-medium text-gray-700 text_theme">
                                      Google API Key
                                    </label>
                                    <input
                                      type={(showKey) ? "text" : "password"}
                                      name="google_api_key"
                                      id="google_api_key"
                                      value={fields.google_api_key}
                                      onChange={(e) => handleChange('google_api_key', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    <span className="password__show cursor-pointer" onClick={() => setShowKey(!showKey)}>{showKey ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>}</span>
                                    {errors.google_api_key ? <Label title='google_api_key' fieldError={errors.google_api_key} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4 relative">
                                    <label htmlFor="FIREBASE_API_KEY" className="block text-basefont-medium text-gray-700 text_theme">
                                      Firebase API Key
                                    </label>
                                    <input
                                      type={(showKey) ? "text" : "password"}
                                      name="FIREBASE_API_KEY"
                                      id="FIREBASE_API_KEY"
                                      value={fields.FIREBASE_API_KEY}
                                      onChange={(e) => handleChange('FIREBASE_API_KEY', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    <span className="password__show cursor-pointer" onClick={() => setShowKey(!showKey)}>{showKey ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>}</span>
                                    {errors.FIREBASE_API_KEY ? <Label title='FIREBASE_API_KEY' fieldError={errors.FIREBASE_API_KEY} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4 relative">
                                    <label htmlFor="CLIENT_ID" className="block text-basefont-medium text-gray-700 text_theme">
                                      Client ID
                                    </label>
                                    <input
                                      type="text"
                                      name="CLIENT_ID"
                                      id="CLIENT_ID"
                                      value={fields.CLIENT_ID}
                                      onChange={(e) => handleChange('CLIENT_ID', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.CLIENT_ID ? <Label title='CLIENT_ID' fieldError={errors.CLIENT_ID} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="GCM_SENDER_ID" className="block text-basefont-medium text-gray-700 text_theme">
                                      GCM Sender ID
                                    </label>
                                    <input
                                      type="number"
                                      name="GCM_SENDER_ID"
                                      id="GCM_SENDER_ID"
                                      value={fields.GCM_SENDER_ID}
                                      onChange={(e) => handleChange('GCM_SENDER_ID', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.GCM_SENDER_ID ? <Label title='GCM_SENDER_ID' fieldError={errors.GCM_SENDER_ID} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="BUNDLE_ID" className="block text-basefont-medium text-gray-700 text_theme">
                                      Passenger Bundle ID
                                    </label>
                                    <input
                                      type="text"
                                      name="BUNDLE_ID"
                                      id="BUNDLE_ID"
                                      value={fields.BUNDLE_ID}
                                      onChange={(e) => handleChange('BUNDLE_ID', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.BUNDLE_ID ? <Label title='BUNDLE_ID' fieldError={errors.BUNDLE_ID} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="DRIVER_BUNDLE_ID" className="block text-basefont-medium text-gray-700 text_theme">
                                      Driver Bundle ID
                                    </label>
                                    <input
                                      type="text"
                                      name="  "
                                      id="DRIVER_BUNDLE_ID"
                                      value={fields.DRIVER_BUNDLE_ID}
                                      onChange={(e) => handleChange('DRIVER_BUNDLE_ID', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.DRIVER_BUNDLE_ID ? <Label title='DRIVER_BUNDLE_ID' fieldError={errors.DRIVER_BUNDLE_ID} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="PROJECT_ID" className="block text-basefont-medium text-gray-700 text_theme">
                                      Project ID
                                    </label>
                                    <input
                                      type="text"
                                      name="PROJECT_ID"
                                      id="PROJECT_ID"
                                      value={fields.PROJECT_ID}
                                      onChange={(e) => handleChange('PROJECT_ID', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.PROJECT_ID ? <Label title='PROJECT_ID' fieldError={errors.PROJECT_ID} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="STORAGE_BUCKET" className="block text-basefont-medium text-gray-700 text_theme">
                                      Storage Bucket
                                    </label>
                                    <input
                                      type="text"
                                      name="STORAGE_BUCKET"
                                      id="STORAGE_BUCKET"
                                      value={fields.STORAGE_BUCKET}
                                      onChange={(e) => handleChange('STORAGE_BUCKET', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.STORAGE_BUCKET ? <Label title='STORAGE_BUCKET' fieldError={errors.STORAGE_BUCKET} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="GOOGLE_APP_ID" className="block text-basefont-medium text-gray-700 text_theme">
                                      Google App ID
                                    </label>
                                    <input
                                      type="text"
                                      name="GOOGLE_APP_ID"
                                      id="GOOGLE_APP_ID"
                                      value={fields.GOOGLE_APP_ID}
                                      onChange={(e) => handleChange('GOOGLE_APP_ID', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.GOOGLE_APP_ID ? <Label title='GOOGLE_APP_ID' fieldError={errors.GOOGLE_APP_ID} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="DATABASE_URL" className="block text-basefont-medium text-gray-700 text_theme">
                                      Database URL
                                    </label>
                                    <input
                                      type="text"
                                      name="DATABASE_URL"
                                      id="DATABASE_URL"
                                      value={fields.DATABASE_URL}
                                      onChange={(e) => handleChange('DATABASE_URL', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.DATABASE_URL ? <Label title='DATABASE_URL' fieldError={errors.DATABASE_URL} /> : null}
                                  </div>

                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                            {tab === 10 ? <div className="pl-6 sm:py-10 col-span-10">
                              <div className="pb-7">
                                <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Map My India Settings</h2>
                                <p className="mt-1 text-base text-gray-500 text_theme">
                                  This information is show Map My India settings.
                                </p>
                              </div>
                              <form action="#" method="POST">
                                <div className="grid grid-cols-8 gap-6">


                                  <div className="col-span-6 sm:col-span-4 relative">
                                    <label htmlFor="map_my_india_client_id" className="block text-basefont-medium text-gray-700 text_theme">
                                      Client ID
                                    </label>
                                    <input
                                      type="text"
                                      name="map_my_india_client_id"
                                      id="map_my_india_client_id"
                                      value={fields.map_my_india_client_id}
                                      onChange={(e) => handleChange('map_my_india_client_id', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.map_my_india_client_id ? <Label title='map_my_india_client_id' fieldError={errors.map_my_india_client_id} /> : null}
                                  </div>

                                  <div className="col-span-6 sm:col-span-4">
                                    <label htmlFor="map_my_india_client_secret" className="block text-basefont-medium text-gray-700 text_theme">
                                      Client Secret
                                    </label>
                                    <input
                                      type="text"
                                      name="map_my_india_client_secret"
                                      id="map_my_india_client_secret"
                                      value={fields.map_my_india_client_secret}
                                      onChange={(e) => handleChange('map_my_india_client_secret', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    {errors.map_my_india_client_secret ? <Label title='map_my_india_client_secret' fieldError={errors.map_my_india_client_secret} /> : null}
                                  </div>
                                  <div className="col-span-6 sm:col-span-4 relative">
                                    <label htmlFor="map_my_india_map_key" className="block text-basefont-medium text-gray-700 text_theme">
                                      Map Key
                                    </label>
                                    <input
                                      type={(showKey) ? "text" : "password"}
                                      name="map_my_india_map_key"
                                      id="map_my_india_map_key"
                                      value={fields.map_my_india_map_key}
                                      onChange={(e) => handleChange('map_my_india_map_key', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                    <span className="password__show cursor-pointer" onClick={() => setShowKey(!showKey)}>{showKey ? <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>}</span>
                                    {errors.map_my_india_map_key ? <Label title='map_my_india_map_key' fieldError={errors.map_my_india_map_key} /> : null}
                                  </div>
                                  <div className="pt-5 col-span-9 pr-6">
                                    <div className="flex justify-end">
                                      {/* <button
                                        type="button"
                                        className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-basefont-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Cancel
                                      </button> */}
                                      <button
                                        type="button"
                                        onClick={(e) => handleSubmit(e)}
                                        className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-basefont-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div> : ''}
                          </div>
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default View;
