import React, { useState, useEffect } from "react";
import Alert from "../../components/Alert";
import Breadcrumb from "../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import { pluck, reject, contains } from "underscore";
import ListSection from "../../components/ListSection";
import DeleteModel from "../../components/DeleteModel";
import labels from "../../constants/Trips";
import configuration from '../../config';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom'

// List all users
function View() {
  const columns = [
    { title: "ID", field: "trip_id" },
    { title: "Passenger", field: "passenger_info" },
    { title: "Driver", field: "driver_info" },
    { title: "Book Date", field: "booking_date" },
    {
      title: "Status",
      field: "status",
      // lookup: { 'started': 'Started Trip', 'no_service': 'No Service' },
      render: (rowData) => {
        let status = rowData.status?.split('_').join(' ');
        return(<span className="capitalize">{status}</span>
      )},
    },
    {
      title: "Total", field: "total",
      render: (rowData) => (
        <>
          <div className="relative mt-20 md:mt-0 " onMouseEnter={() => setTooltipStatus(rowData.trip_id)} onMouseLeave={() => setTooltipStatus(0)}>
            <div className="mr-2 cursor-pointer flex items-center">
            {rowData.total}
              <svg aria-haspopup="true" xmlns="http://www.w3.org/2000/svg" className="ml-2 icon icon-tabler icon-tabler-info-circle" width={25} height={25} viewBox="0 0 24 24" strokeWidth="1.5" stroke="#A0AEC0" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" />
                <circle cx={12} cy={12} r={9} />
                <line x1={12} y1={8} x2="12.01" y2={8} />
                <polyline points="11 12 12 12 12 16 13 16" />
              </svg>
            </div>
            {tooltipStatus === rowData.trip_id && (
              <div role="tooltip" className="z-20 -mt-10 w-64 absolute transition duration-150 ease-in-out right-36 ml-8 shadow-lg bg-white p-4 rounded">
                <div className="">
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">Service Type</p>
                    <p className="text-xs leading-4 text-gray-600 pb-3"><b>{rowData.service_info}</b></p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">Subtotal</p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">{rowData.subtotal}</p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">Additional Charge</p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">{rowData.additional_fare}</p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">Insurance</p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">{rowData.insurance}</p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">Waiting Charge</p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">{rowData.waiting_fare}</p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">Tax</p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">{rowData.tax}</p>
                  </div>
                  <div className="justify-between flex">
                    <p className="text-sm font-bold text-gray-800 pb-1">Commission</p>
                    <p className="text-xs leading-4 text-gray-600 pb-3">{rowData.commission}</p>
                  </div>
                </div>
                <div className="">
                  <div className="flex items-center justify-between">
                    <Link
                      className="text-indigo-600 hover:text-indigo-900"
                      to={`/tripsdetail/${rowData.trip_id}`}
                    >
                      View Detail
                    </Link>
                    <Link to={`/invoice/${rowData.trip_id}`}
                      className= "inline-flex items-center px-3 py-0.5 rounded-md text-basefont-medium bg-indigo-600 text-white capitalize">          
                      Invoice
                    </Link>
                  </div>
                </div>
              </div>
            )}{" "}
          </div>
        </>
      ),
    },
  ];
  const [data, setData] = useState([]);

  const btnGroups = [
    { id: 2, title: "Export CSV", type: "exportCSV", path: "/user", fileName: "Trips" },
    
  ];
  const location = useLocation();
  const status = location.state;
  const [show, setShow] = useState(false);
  const [tooltipStatus, setTooltipStatus] = useState(0);
  const fieldArr = [
      { id: 3, title: "From  Date", name: "from_date", type: "DatePicker", disablePast:false },
      { id: 4, title: "To Date", name: "to_date", type: "DatePicker", disablePast:false },
    ]
  const [fieldsFilter, setFieldsFilter] = useState({
    from_date: null,
    to_date: null
  });
  const [errorsFilter, setErrorsFilter] = useState({
    from_date: '',
    to_date: ''
  });

  const [ids, setIds] = useState([]);

  useEffect(() => {
    // console.log(status)
    getDatas({});
  }, []);

  function getDatas(filterData) {
    configuration.getAPI({ url: 'trip/list', params: {filterData: JSON.stringify(filterData)} }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  const onSelectionChange = (e) => {
    let idArr = pluck(e, 'user_id');
    setIds(idArr)
  }

  function handleApplyFilter() {
    if (handleFilterValidation()) {
      getDatas(fieldsFilter)
      // getItemAvailability(fieldsFilter);
    }
  }
  function handleClearFilter() {
    setFieldsFilter({ status: [], from_date: null, to_date: null})
    getDatas({})
    // getItemAvailability({});
  }
  function handleChangeFilter(field, e) {
    if (field === 'status') {
      console.log(field)
      console.log(e.target.value)
      let sessionData = (fieldsFilter.status) ? fieldsFilter.status : []
      if (!contains(sessionData, e.target.value)) {
        sessionData.push(e.target.value)
      } else {
        sessionData = reject(sessionData, function (num) { return num === e.target.value; })
      }
      console.log(sessionData)
      setFieldsFilter({ ...fieldsFilter, [field]: sessionData })
      if (sessionData && sessionData.length <= 0) {
        delete fieldsFilter.status;
        getDatas({fieldsFilter})
      } else {
        getDatas({ ...fieldsFilter, [field]: sessionData })
      }
    } else {
      setFieldsFilter({ ...fieldsFilter, [field]: e })
    }
  }
  function handleFilterValidation() {
    let flag = true;
    let error = {}
    if (fieldsFilter.to_date && !fieldsFilter.from_date) {
      error['from_date'] = "Please select from date"
      flag = false
    }
    if (fieldsFilter.from_date && !fieldsFilter.to_date) {
      error['to_date'] = "Please select to date"
      flag = false
    }
    if (fieldsFilter.from_date && fieldsFilter.to_date) {
      if (fieldsFilter.to_date < fieldsFilter.from_date) {
        flag = false;
        error["to_date"] = "Please select end date greater than to start date.";
      }
    }
    if (fieldsFilter.status && fieldsFilter.status.length <= 0) {
      delete fieldsFilter.status;
      setFieldsFilter(fieldsFilter)
    }
    setErrorsFilter({ ...error })
    return flag;
  }

  return (
    <>
      <Breadcrumb module={labels.Trips} />
      <Alert
        show={show}
        setShow={setShow}
        bgColorClass="green"
        caption="Success"
        message="Record added successfully"
      />
      <ListSection
        captionTitle="View Trips"
        columns={columns}
        data={data}
        btnGroups={btnGroups}
        filters={false}
        fieldArr={fieldArr}
        disabledCheckbox={true}
        onSelectionChange={onSelectionChange}
        handleApplyFilter={handleApplyFilter}
        handleClearFilter={handleClearFilter}
        handleChangeFilter={handleChangeFilter}
        fieldsFilter={fieldsFilter}
        errorsFilter={errorsFilter}
        // is_pagenation={true}
        // apiURL='trip/list-sort'
      />
    </>
  );
}

export default View;
