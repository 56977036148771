import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import React, {useState, useEffect} from "react";
import labels from "../../../constants/AdditionalCharge";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import {each} from 'underscore';

// For Add Role
function Add({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [languages, setLanguages] = useState([])
  const [fieldArr, setFieldArr] = useState([  
    { id: 2, title: "Price", name: "price", type: "TextInput", is_number:true},
    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },   
  ]);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    status: "",
  });
  useEffect(() => {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data1) => {
      if (data1.status === 200) {
        setLanguages(data1.payload)
        let languages = data1.payload;
        let fieldArr_data = [];
        languages.map((language, index) => {
          fieldArr_data.push({
            id: 3 + index,
            title: `Title [${language.title}]`,
            name: `title_${language.code}`,
            type: "TextInput"
          });
        })
        configuration.getAPI({ url: 'common/list-order-no', params: { lang_code: "EN", collectionName: 'additional_charges' } }).then((data) => {
          if (data.status === 200) {
            let orderNoArr = []
            each(data.payload, (singleRec) => {
              orderNoArr.push({ 'title': singleRec.title, 'value': singleRec.order_no, 'id': singleRec.order_no })
            })
            const orderNoObj = {
              id: 1,
              title: "Order No",
              name: "order_no",
              type: "Dropdown",
              options: orderNoArr,
            }
            fieldArr_data.push(orderNoObj)
            setFieldArr([...fieldArr_data, ...fieldArr])
          }
        }).catch(error => {
          return toast.error(error.message)
        });
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  function handleValidation() {
    let flag = true;
    let error = {}
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'additional-charge/create', params:fields}).then((data) => {
        if(data.status === 200){
          history.push('/additional-charge')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
}

function handleChange(field, e) {
  setFields({ ...fields, [field]: e.target.value })
}
  return (
    <>
      <Breadcrumb module={labels.AddAdditionalCharge} />
      <AddEditSection title="Add Additional Charge" path="/additional-charge" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
