import React, { useState, useEffect } from "react";
import Alert from "../../../components/Alert";
import Breadcrumb from "../../../parts/Breadcrumb";
import { Link } from "react-router-dom";
import { pluck, reject, contains } from "underscore";
import ListSection from "../../../components/ListSection";
import DeleteModel from "../../../components/DeleteModel";
import labels from "../../../constants/Reports";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import { each } from 'underscore';

// List all users
function View({ location }) {
  const columns = [
    {
      title: "ID",
      field: "trip_id"
    },
    { title: "Passenger", field: "passenger_info" },
    { title: "Driver", field: "driver_info" },
    { title: "Vehicle", field: "vehicle_ride_option_info" },
    { title: "Service", field: "service_info" },
    { title: "Cancel By", field: "by_whom"},
    { title: "Reason", field: "reason"},
    { title: "Book Date", field: "booking_date" },
    {
      title: "Status", field: "status", render: (rowData) => {
        let status = rowData.status;
        status = status.split('_').join(' ');
        return <span className="capitalize">
          {status}
        </span >
      }
    },
  ];
  const [data, setData] = useState([]);

  const btnGroups = [
    { id: 2, title: "Export CSV", type: "exportCSV", path: "/user", fileName: "Trips" },
    
  ];
  const fieldArr = [
      { id: 3, title: "From  Date", name: "from_date", type: "DatePicker", disableFuture:true },
      { id: 4, title: "To Date", name: "to_date", type: "DatePicker", disableFuture:true },
    ]
  const [fieldsFilter, setFieldsFilter] = useState({
    from_date: new Date(),
    to_date: new Date()
  });
  const [errorsFilter, setErrorsFilter] = useState({
    from_date: '',
    to_date: ''
  });
  const [csvData, setCsvData] = useState([]);

  const [ids, setIds] = useState([]);

  useEffect(() => {
    getDatas(fieldsFilter);
  }, []);

  function getDatas(filterData) {
    configuration.getAPI({ url: 'trip/list', params: {filterData: JSON.stringify({...filterData, status:['cancelled']})} }).then((data) => {
      if (data.status === 200) {
        let CSV = [];
        each(data.payload, single=>{
          CSV.push({
            ID:single.trip_id,
            Passenger:single.passenger_info,
            Driver:single.driver_info,
            Vehicle:single.vehicle_ride_option_info,
            Service:single.service_info,
            "Cancel By":single.by_whom,
            Reason:single.reason,
            "Book Date":single.booking_date,
            "Status":single.status,
          })
        })
        setCsvData(CSV)
        setData(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  const onSelectionChange = (e) => {
    let idArr = pluck(e, 'user_id');
    setIds(idArr)
  }

  function handleApplyFilter() {
    if (handleFilterValidation()) {
      getDatas(fieldsFilter)
    }
  }
  function handleClearFilter() {
    setFieldsFilter({ status: [], from_date: null, to_date: null})
    getDatas({})
  }
  function handleChangeFilter(field, e) {
    if (field === 'status') {
      console.log(field)
      console.log(e.target.value)
      let sessionData = (fieldsFilter.status) ? fieldsFilter.status : []
      if (!contains(sessionData, e.target.value)) {
        sessionData.push(e.target.value)
      } else {
        sessionData = reject(sessionData, function (num) { return num === e.target.value; })
      }
      console.log(sessionData)
      setFieldsFilter({ ...fieldsFilter, [field]: sessionData })
      if (sessionData && sessionData.length <= 0) {
        delete fieldsFilter.status;
        getDatas({fieldsFilter})
      } else {
        getDatas({ ...fieldsFilter, [field]: sessionData })
      }
    } else {
      setFieldsFilter({ ...fieldsFilter, [field]: e })
    }
  }
  function handleFilterValidation() {
    let flag = true;
    let error = {}
    if (fieldsFilter.to_date && !fieldsFilter.from_date) {
      error['from_date'] = "Please select from date"
      flag = false
    }
    if (fieldsFilter.from_date && !fieldsFilter.to_date) {
      error['to_date'] = "Please select to date"
      flag = false
    }
    if (fieldsFilter.from_date && fieldsFilter.to_date) {
      if (fieldsFilter.to_date < fieldsFilter.from_date) {
        flag = false;
        error["to_date"] = "Please select end date greater than to start date.";
      }
    }
    if (fieldsFilter.status && fieldsFilter.status.length <= 0) {
      delete fieldsFilter.status;
      setFieldsFilter(fieldsFilter)
    }
    setErrorsFilter({ ...error })
    return flag;
  }

  return (
    <>
      <Breadcrumb module={labels.CancelTrips} />
      <ListSection
        captionTitle="Cancelled Trips"
        columns={columns}
        data={data}
        btnGroups={btnGroups}
        filters={true}
        status={false}
        fieldArr={fieldArr}
        disabledCheckbox={true}
        onSelectionChange={onSelectionChange}
        handleApplyFilter={handleApplyFilter}
        handleClearFilter={handleClearFilter}
        handleChangeFilter={handleChangeFilter}
        fieldsFilter={fieldsFilter}
        errorsFilter={errorsFilter}
        csvTitle={"Cancelled_Trips_"}
        csvData={csvData}
      />
    </>
  );
}

export default View;
