import { CheckCircleIcon, XCircleIcon, XIcon } from "@heroicons/react/outline";
import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Transition } from "@headlessui/react";

// Alert Box
function Alert({ show, bgColorClass, caption, message, setShow }) {
  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 z-50 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      style={{ display: show ? "block" : "none" }}
    >
      <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
        <Transition
          show={show}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={`max-w-sm w-full bg-${bgColorClass}-700 shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden`}
          >
            <div className="p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {caption === "Success" ? (
                    <CheckCircleIcon
                      className={`h-6 w-6 text-${bgColorClass}-400`}
                      aria-hidden="true"
                    />
                  ) : (
                    <XCircleIcon
                      className={`h-6 w-6 text-${bgColorClass}-400`}
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p
                    className={`text-base text-gray-900 text-${bgColorClass}-100`}
                  >
                    {caption}
                  </p>
                  <p className={`mt-1 text-base text-${bgColorClass}-100`}>
                    {message}
                  </p>
                </div>
                <div className="ml-4 flex-shrink-0 flex">
                  <button
                    type="button"
                    className={`bg-${bgColorClass}-700 rounded-md inline-flex text-${bgColorClass}-100 hover:text-${bgColorClass}-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
  );
}

Alert.propTypes = {
  caption: PropTypes.string,
  bgColorClass: PropTypes.string,
  show: PropTypes.bool,
  message: PropTypes.string,
  setShow: PropTypes.func,
};

Alert.defaultProps = {
  caption: "",
  bgColorClass: "",
  show: false,
  message: "",
  setShow: () => {
     // do nothing.
  },
};

export default Alert;
