import PropTypes from "prop-types";
import React, { useState, } from "react";
import Caption from "./Caption";
import ActionButtonPopUp from "./ActionButtonPopUp";
import DataTable from "./MDataTable";
import DataTablePagenation from "./MDataTablePagenation";
import DatePicker from "./Form/DatePicker";
import Label from "./Form/Label";
import {contains} from 'underscore';

function ListSection({
  captionTitle,
  tableMsg,
  btnGroups,
  columns,
  filters,
  status=true,
  balance,
  data,
  onSelectionChange,
  disabledCheckbox,
  csvData,
  csvTitle = '',
  handleAction,
  fieldArr = [],
  handleApplyFilter,
  handleClearFilter,
  handleChangeFilter,
  fieldsFilter = {},
  filterData = {},
  errorsFilter,
  apiURL,
  tableRef,
  is_pagenation = false,
}) {
  const projects2 = [
    {
      name: 'Available Balance',
      imageUrl: '../../image/coin.png',
      href: '#',
      members: '$13,500',
      bgColor: 'bg-gray-100'
    },
  ]

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

  return (
    <div className="py-6 sm:px-6">
      <div className="relative">
        <section className="px-4" aria-labelledby="contact-heading">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                  <Caption title={captionTitle} />
                  {btnGroups.length > 0 ? (
                    <ActionButtonPopUp btnGroups={btnGroups} csvTitle={csvTitle} csvData={(csvData && csvData.length>0)?csvData:data} handleAction={handleAction} />
                  ) : null}
                </div>
                {(balance) ?
                  <>
                    <ul role="list" className="mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-2">
                      {projects2.map((project) => (
                        <li key={project.name} className="col-span-1 flex shadow-sm rounded-md">
                          <div
                            className={classNames(
                              project.bgColor,
                              'flex-shrink-0 flex items-center justify-center w-16 text-white border text-basefont-medium rounded-l-md'
                            )}
                          >
                            <img className="h-5 w-5" src={project.imageUrl} alt="" />
                          </div>
                          <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                            <div className="flex-1 px-4 py-2 text-basetruncate">
                              <p className="text-gray-600 font-medium hover:text-gray-600">
                                {project.name}
                              </p>
                              <p className="text-gray-900 text-lg">{project.members}</p>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>

                  </>
                  : ""
                }
                {(filters) ?
                  <>
                    {(status)?
                      <p className="relative z-0  mb-5">
                        <button
                            type="button"
                            value="pending"
                            onClick={(e) => handleChangeFilter('status', e)}
                            className={classNames(
                              (contains(fieldsFilter.status, 'pending')) ? "border-indigo-600 bg-indigo-600 text-white" : "border-gray-300 bg-white text-gray-700",
                              "-ml-px relative inline-flex items-center px-4 py-2 rounded-l-md border text-basefont-medium  "
                            )}
                          >
                            New Trips
                          </button>
                        <button
                          type="button"
                          value="accepted"
                          onClick={(e) => handleChangeFilter('status', e)}
                          className={classNames(
                            (contains(fieldsFilter.status, 'accepted')) ? "border-indigo-600 bg-indigo-600 text-white" : "border-gray-300 bg-white text-gray-700",
                            "-ml-px relative inline-flex items-center px-4 py-2 border text-basefont-medium  "
                          )}
                        >
                          Accepted Trips
                        </button>
                        <button
                          type="button"
                          value="started"
                          onClick={(e) => handleChangeFilter('status', e)}
                          className={classNames(
                            (contains(fieldsFilter.status, 'started')) ? "border-indigo-600 bg-indigo-600 text-white" : "border-gray-300 bg-white text-gray-700",
                            "-ml-px relative inline-flex items-center px-4 py-2 border text-basefont-medium  "
                          )}
                        >
                          Started Trips
                        </button>
                        <button
                          type="button"
                          value="completed"
                          onClick={(e) => handleChangeFilter('status', e)}
                          className={classNames(
                            (contains(fieldsFilter.status, 'completed')) ? "border-indigo-600 bg-indigo-600 text-white" : "border-gray-300 bg-white text-gray-700",
                            "-ml-px relative inline-flex items-center px-4 py-2 border text-basefont-medium  "
                          )}
                        >
                          Completed Trips
                        </button>
                        <button
                          type="button"
                          value="cancelled"
                          onClick={(e) => handleChangeFilter('status', e)}
                          className={classNames(
                            (contains(fieldsFilter.status, 'cancelled')) ? "border-indigo-600 bg-indigo-600 text-white" : "border-gray-300 bg-white text-gray-700",
                            "-ml-px relative inline-flex items-center px-4 py-2 border text-basefont-medium  "
                          )}
                        >
                          Cancelled Trips
                        </button>
                        <button
                          type="button"
                          value="scheduled"
                          onClick={(e) => handleChangeFilter('status', e)}
                          className={classNames(
                            (contains(fieldsFilter.status, 'scheduled')) ? "border-indigo-600 bg-indigo-600 text-white" : "border-gray-300 bg-white text-gray-700",
                            "-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border text-basefont-medium  "
                          )}
                        >
                          Schedule Trips
                        </button>
                      </p>:''
                    }


                    {/*<p className="relative z-0   mb-5 mt-5">
                      <button
                        type="button"
                        className="relative inline-flex items-center px-4 py-2 rounded-l-md border border-green-600 bg-green-600 text-basefont-medium text-white  "
                      >
                        Today
                      </button>
                      <button
                        type="button"
                        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-basefont-medium text-gray-700 "
                      >
                        Yesterday
                      </button>
                      <button
                        type="button"
                        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-basefont-medium text-gray-700"
                      >
                        This Week
                      </button>
                      <button
                        type="button"
                        className="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-basefont-medium text-gray-700"
                      >
                        Pask Week
                      </button>

                      <button
                        type="button"
                        className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-basefont-medium text-gray-700 "
                      >
                        Past Month
                      </button>
                    </p>*/}

                    <div className="grid grid-cols-1 gap-y-6 gap-x-4 lg:grid-cols-3 mt-5">
                      {fieldArr.map((field, index) => {
                        return (
                          <div className="" key={index}>
                            <Label title={field.title} />
                            <div className="mt-1 relative">
                              {field.type === "DatePicker" ? (
                                <DatePicker
                                  id={field.name}
                                  name={field.name}
                                  handleChange={(name, e) => handleChangeFilter(name, e)}
                                  value={fieldsFilter[field.name]}
                                  disablePast={field.disablePast}
                                  disableFuture={field.disableFuture}
                                />
                              ) : null}
                            </div>
                            {errorsFilter[field.name] ? <Label title={field.title} fieldError={errorsFilter[field.name]} /> : null}
                          </div>
                        );
                      })}
                    </div>
                    <div className="py-2">
                      <button
                        onClick={handleApplyFilter}
                        type="button"
                        className="mt-3 mr-3 inline-flex items-center px-3 py-2 border border-transparent text-baseleading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Apply
                      </button>
                      <button
                        onClick={handleClearFilter}
                        type="button"
                        className="mt-3 inline-flex items-center px-3 py-2 border border-1 text-baseleading-4 font-medium rounded-md shadow-sm text-indigo-600 bg-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Clear
                      </button>
                    </div>
                  </>
                  : ""
                }
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white border-bottom-theme">
                  <div className="lg:gap-y-0 lg:gap-x-8">
                  {(!is_pagenation)?
                    <DataTable
                      tableMsg={tableMsg || 'record'}
                      columns={columns}
                      data={data}
                      disabledCheckbox={disabledCheckbox || false}
                      onSelectionChange={onSelectionChange}
                    />:<DataTablePagenation
                    tableMsg={tableMsg || 'record'}
                    columns={columns}
                    tableRef={tableRef}
                    filterData={filterData}
                    apiURL={apiURL}
                    disabledCheckbox={disabledCheckbox || false}
                    onSelectionChange={onSelectionChange}
                  />
                  }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

ListSection.propTypes = {
  // "btnGroups.length": PropTypes.number,
  captionTitle: PropTypes.string,
  btnGroups: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
  onSelectionChange: PropTypes.func,
};

ListSection.defaultProps = {
  // "btnGroups.length": 0,
  captionTitle: "",
  btnGroups: [],
  columns: [],
  data: [],
  onSelectionChange: () => {
    // do nothing.
  },
};

export default ListSection;
