import React, { useState, useEffect } from "react";
import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import { pluck,each } from "underscore";
import labels from "../../../constants/RentalsPrice";
import configuration from '../../../config';
import { toast } from 'react-toastify';

// List all users
function Add({history}) {
  const [rideArr, setRideArr] = useState([]);
  const [packageArr, setPackage] = useState([]);
  const fieldArr = [
    { id: 0, title: "Select Vehicle ", name: "vehicle_ride_option_id", type: "Dropdown", options: rideArr},
    { id: 2, title: "Select Rental Package ", name: "rental_package_id", type: "Dropdown", options: packageArr},
    { id: 4, title: "Base Fare for Package ", name: "base_fare", type: "TextInput", is_number:true},
    { id: 6, title: "Additional Fare Per Km", name: "additional_fare_per_km", type: "TextInput", is_number:true},
    { id: 7, title: "Additional Fare Per Min ", name: "additional_fare_per_min", type: "TextInput", is_number:true},
  ];
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({});
  useEffect(() => {
  	configuration.getAPI({url:'ride-option/list', params:{status:'active'}}).then((data) => {
      if(data.status === 200){
        const rideArr = [];
        each(data.payload, singleRec=>{
        	rideArr.push({ 'title': singleRec.title, 'value': singleRec.vehicle_ride_option_id, 'id': singleRec.vehicle_ride_option_id })
        })
        setRideArr(rideArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  	configuration.getAPI({url:'rental-package/list', params:{status:'active'}}).then((data) => {
      if(data.status === 200){
        const rideArr = [];
        each(data.payload, singleRec=>{
        	rideArr.push({ 'title': singleRec.title, 'value': singleRec.rental_package_id, 'id': singleRec.rental_package_id })
        })
        setPackage(rideArr)
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  function handleChange(field, e) {
    setFields({ ...fields, [field]: e.target.value })
  }

  function handleValidation() {
    let flag = true;
    let error = {}
    if (!fields.vehicle_ride_option_id) {
      error['vehicle_ride_option_id'] = "Please select vehicle ride"
      flag = false
    }

    if (!fields.base_fare) {
      error['base_fare'] = "Please enter base fare"
      flag = false
    } else if(fields.base_fare <= 0) {
      error['base_fare'] = "Please enter valid base fare"
      flag = false
    }

    if (!fields.additional_fare_per_km) {
      error['additional_fare_per_km'] = "Please enter additional fare per km"
      flag = false
    } else if(fields.additional_fare_per_km < 0) {
      error['additional_fare_per_km'] = "Please enter valid additional fare per km"
      flag = false
    }

    if (!fields.additional_fare_per_min) {
      error['additional_fare_per_min'] = "Please enter additional fare per min"
      flag = false
    } else if(fields.additional_fare_per_min < 0) {
      error['additional_fare_per_min'] = "Please enter valid additional fare per min"
      flag = false
    }

    setErrors({ ...error })
    return flag;
  }

  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'rental-price/create', params:{...fields, service_type:'RENTAL'}}).then((data) => {
        if(data.status === 200){
          history.push('/rental-price')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
  }

  return (
    <>
      <Breadcrumb module={labels.AddRentals} />
      <AddEditSection title="Add Rentals Price" path="/rental-price" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
