import AddEditSection from "../../../components/AddEditSection";
import Breadcrumb from "../../../parts/Breadcrumb";
import React, {useState, useEffect} from "react";
import labels from "../../../constants/VehicleModel";
import configuration from '../../../config';
import { toast } from 'react-toastify';
import {each} from 'underscore';

// For Add Role
function Add({history}) {
  const statusOpt = [
    { id: 1, title: "Active", value: "active" },
    { id: 2, title: "Inactive", value: "inactive" },
  ];
  const [languages, setLanguages] = useState([])
  const [fieldArr, setFieldArr] = useState([  
    {
      id: 3,
      title: "Status",
      name: "status",
      type: "Dropdown",
      options: statusOpt,
    },   
  ]);
  const [fields, setFields] = useState({});
  const [errors, setErrors] = useState({
    status: "",
  });
  useEffect(() => {
    configuration.getAPI({ url: 'language/list', params: { status: 'active' } }).then((data1) => {
      if (data1.status === 200) {
        setLanguages(data1.payload)
        let languages = data1.payload;
        let fieldArr_data = [];
        
        configuration.getAPI({ url: 'make/list', params: { status: "active" } }).then((data) => {
          if (data.status === 200) {
            let catArr = []
            each(data.payload, (singleRec) => {
              catArr.push({ 'title': singleRec.title, 'value': singleRec.vehicle_make_id, 'id': singleRec.vehicle_make_id })
            })
            const hlpCat = {
              id: 0,
              title: "Vehicle Make",
              name: "vehicle_make_id",
              type: "Dropdown",
              options: catArr,
            }
            // setFieldArr([...fieldArr, ...hlpCat])
            fieldArr_data.push(hlpCat)
            languages.map((language, index) => {
              fieldArr_data.push({
                id: 3 + index,
                title: `Title [${language.title}]`,
                name: `title_${language.code}`,
                type: "TextInput"
              });
            })
            setFieldArr([...fieldArr, ...fieldArr_data])
          }
        }).catch(error => {
          return toast.error(error.message)
        });
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  function handleValidation() {
    let flag = true;
    let error = {}
    each(languages, (lang, ind) => {
      if (!fields['title_' + lang.code]) {
        error['title_' + lang.code] = "Please enter title in " + lang.title
        flag = false
      }
    })
    if (!fields.vehicle_make_id) {
      error['vehicle_make_id'] = "Please select vehicle maker"
      flag = false
    }
    if (!fields.status) {
      error['status'] = "Please select status"
      flag = false
    }
    setErrors({ ...error })
    return flag;
  }
  const handleSubmit = () =>{
    if (handleValidation()) {
      configuration.postAPI({url:'model/create', params:fields}).then((data) => {
        if(data.status === 200){
          history.push('/vehiclemodel')
        } else if (data.status === 404) {
          return toast.error(data.error.message)
        } else {
          return toast.error('Something went wrong')
        }
      }).catch(error => {
        return toast.error(error.message)
      });
    }
}

function handleChange(field, e) {
  setFields({ ...fields, [field]: e.target.value })
}
  return (
    <>
      <Breadcrumb module={labels.AddVehicleModel} />
      <AddEditSection title="Add Vehicle Model" path="/vehiclemodel" fields={fieldArr} fieldValue={fields} fieldError={errors} handleChange={handleChange} handleSubmit={handleSubmit} />
    </>
  );
}

export default Add;
