import React, { useState, useEffect } from "react";
import Breadcrumb from "../../../parts/Breadcrumb";
import labels from "../../../constants/Setting";
import Label from "../../../components/Form/Label";
import { Link } from "react-router-dom";
import configuration from '../../../config';
import Dropdown from "../../../components/Form/Dropdown";
import TimeSlot from './timeSlot';
import { toast } from 'react-toastify';
import { each } from 'underscore';
import Select from "react-select";

// Dynamic class
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

// List Settings
const Tabs = ({ color }) => {
  const [subNavigation, setSubNavigation] = useState([
    { name: "Trip Wise", type: 'trip', href: "#", current: true },
    { name: "Per KM Wise", type: 'per_km', href: "#", current: false },
    { name: "Driver Earning Wise", type: 'driver_earning', href: "#", current: false },
    { name: "Festival Area Wise ", type: 'festival_earning', href: "#", current: false },
    { name: "Reward Commission ", type: 'reward_commission', href: "#", current: false },
  ]);
  const [countryOpt, setCurrencyOpt] = useState([]);
  const [stateOpt, setStateOpt] = useState([]);
  const [cityOpt, setCityOpt] = useState([]);
  const [tripRides, setTripRides] = useState([]);
  const [tripRentals, setTripRentals] = useState([]);
  const [tripOutstations, setTripOutstations] = useState([]);
  const [areas, setAreas] = useState([]);
  const [currTime, setCurrTime] = useState(new Date());
  const [tab, setTab] = useState(0)
  const [showPrice, setShowPrice] = useState(true);
  const [setting, setSetting] = useState({})
  const fieldArr = [
    {
      id: 0,
      title: "Country",
      name: "country_id",
      type: "Dropdown",
      options: countryOpt,
    },
    {
      id: 1,
      title: "State",
      name: "state_id",
      type: "Dropdown",
      options: stateOpt,
    },
    {
      id: 1,
      title: "City",
      name: "city_id",
      type: "Dropdown",
      options: cityOpt,
    }];
  const [fields, setFields] = useState({
    driver_earning_type: 'trip',
    festival_earning_type: 'trip'
  })
  const [errors, setErrors] = useState(0)

  function getCountryDatas() {
    configuration
      .getAPI({ url: "country/list", params: {} })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.country_id;
              element.id = element.country_id;

              return element;
            });
          }
          setCurrencyOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getStateDatas(params) {
    configuration
      .getAPI({ url: "state/list", params })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.state_id;
              element.id = element.state_id;

              return element;
            });
          }
          setStateOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }

  function getCities(params) {
    configuration
      .getAPI({ url: "city/list", params })
      .then((data) => {
        if (data.status === 200) {
          if (data.payload.length > 0) {
            data.payload.forEach((element) => {
              element.title = element.name;
              element.value = element.city_id;
              element.id = element.city_id;

              return element;
            });
          }
          setCityOpt(data.payload);
        }
      })
      .catch((error) => {
        return toast.error(error.message);
      });
  }
  function setTabMenu(index) {
    setTab(index)
    const navigate = subNavigation;
    each(navigate, (element, i) => {
      let currentState = false;
      if (i === index) {
        currentState = true;
      }
      navigate[i].current = currentState;
    });
    setSubNavigation(navigate);
  }
  function getRentalPrice(type, sub_type) {
    let sendData = { service_type: 'RENTAL', commission_type: type }
    if (sub_type !== '') {
      sendData.type = sub_type
    }
    configuration.getAPI({ url: 'commission/list-rental', params: sendData }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setTripRentals(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  function getOutstationsPrice(type, sub_type) {
    let sendData = { service_type: 'OUT_STATION', commission_type: type }
    if (sub_type !== '') {
      sendData.type = sub_type
    }
    configuration.getAPI({ url: 'commission/list-outStation', params: sendData }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setTripOutstations(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  function getRideOption(type, sub_type) {
    let sendData = { status: 'active', commission_type: type }
    if (sub_type !== '') {
      sendData.type = sub_type
    }
    configuration.getAPI({ url: 'commission/list-ride', params: sendData }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        setTripRides(data.payload)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  function getAreas() {
    configuration.getAPI({ url: 'festival-area/list', params: { status: 'active' } }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        let finalAreas = []
        each(data.payload, single => {
          finalAreas.push({ id: single.festival_area_id, value: single.festival_area_id, label: single.name })
        })
        setAreas(finalAreas)
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  useEffect(() => {
    getCountryDatas();
    getAreas();
    getRentalPrice('trip', '');
    getOutstationsPrice('trip', '');
    getRideOption('trip', '');
    configuration.getAPI({ url: 'common/get-settings', params: {} }).then((data) => {
      if (data.status === 200) {
        setSetting({ reward_no_of_rides: data.payload.reward_no_of_rides, reward_commission_percentage: data.payload.reward_commission_percentage })
      }
    }).catch(error => {
      return toast.error(error.message)
    });
  }, []);
  function handleChangeSetting(field, e) {
    setSetting({ ...setting, [field]: e.target.value })
  }
  function handleSubmitSetting() {
    const formData = new FormData();
    formData.append('fields', JSON.stringify({ ...setting, lang_code: 'EN' }));
    configuration.postFormDataAPI({ url: 'common/update-settings', params: formData }).then((data) => {
      // console.log(data)
      if (data.status === 200) {
        return toast.success('Setting Updated Successfully')
        // setData(data.payload)
      } else if (data.error) {
        return toast.error(data.error.message)
      } else {
        return toast.error('Something went wrong')
      }
    }).catch(error => {
      // console.log(error)
      return toast.error(error.message)
    });
  }
  function handleChangeTrip(type, index, field, e) {
    if (field === 'festival_area_id') {
      if (type === 'ride') {
        tripRides[index][field] = e;
        setTripRides(tripRides);
      } else if (type === 'rental') {
        tripRentals[index][field] = e
        setTripRentals(tripRentals);
      } else {
        tripOutstations[index][field] = e
        setTripOutstations(tripOutstations);
      }
    } else {
      if (type === 'ride') {
        tripRides[index][field] = (e.target.value === '') ? e.target.value : Number(e.target.value);
        setTripRides(tripRides);
      } else if (type === 'rental') {
        tripRentals[index][field] = (e.target.value === '') ? e.target.value : Number(e.target.value);
        setTripRentals(tripRentals);
      } else {
        tripOutstations[index][field] = (e.target.value === '') ? e.target.value : Number(e.target.value);
        setTripOutstations(tripOutstations);
      }
    }
    setCurrTime(new Date())
    // setTripRides
    // setTripRides({ ...tripRides, [field]: e.target.value })
  }
  function handleTabChange(type, index) {
    if (type !== 'time_slot') {
      let sub_type = ''
      if (type === 'driver_earning') {
        sub_type = fields.driver_earning_type;
      }
      if (type === 'festival_earning') {
        sub_type = fields.festival_earning_type;
      }
      getRentalPrice(type, sub_type);
      getOutstationsPrice(type, sub_type);
      getRideOption(type, sub_type);
    }
    setTabMenu(index)
  }
  function handleChangeType(type, field, e) {
    // console.log(type)
    // console.log(e.target.value)
    getRentalPrice(type, e.target.value);
    getOutstationsPrice(type, e.target.value);
    getRideOption(type, e.target.value);
    setFields({ ...fields, [field]: e.target.value })
  }
  function handleChange(field, e) {
    if (field === "country_id" && e.target.value) {
      setShowPrice(false)
      setStateOpt([]);
      setCityOpt([]);
      getStateDatas({ country_id: e.target.value, status: 'active' });
      setFields({ ...fields, city_id: '', state_id: '', [field]: e.target.value });
    } else if (field === "state_id" && e.target.value) {
      setShowPrice(false)
      setCityOpt([]);
      getCities({ state_id: e.target.value, status: 'active' });
      setFields({ ...fields, city_id: '', [field]: e.target.value });
    } else if (field === 'city_id') {
      setShowPrice(true)
      setFields({ ...fields, [field]: e.target.value })
      // getNormalPrice(fields.country_id, fields.state_id, e.target.value)
      // getAdvancedPrice(fields.country_id, fields.state_id, e.target.value)
    }
  }
  function validationTrip() {
    let flag = true;
    let error = {}
    setErrors({ ...error })
    return flag;
  }
  function validation() {
    let flag = true;
    let error = {}
    setErrors({ ...error })
    return flag;
  }
  const handleSubmitTrip = (type, e) => {
    if (validationTrip()) {
      let sendData = { commission_type: type, tripRides, tripRentals, tripOutstations }
      if (type === 'driver_earning') {
        console.log(fields.driver_earning_type)
        sendData.driver_earning_type = fields.driver_earning_type
      }
      if (type === 'festival_earning') {
        console.log(fields.festival_earning_type)
        sendData.festival_earning_type = fields.festival_earning_type
      }
      configuration.postAPI({ url: "commission/trip", params: sendData }).then((data) => {
        if (data.status === 200) {
          // applyAdvancedPrice()
          return toast.success('Commission value updated successfully');
        } else if (data.error) {
          return toast.error(data.error.message);
        } else {
          return toast.error("Something went wrong");
        }
      })
        .catch((error) => {
          return toast.error(error.message);
        });
    }
  }
  const handleSubmit = () => {
    if (validation()) {

    }
  }

  const [openTab, setOpenTab] = React.useState(1);
  return (
    <>
      <Breadcrumb module={labels.personalSetting} />
      <div className="py-6 sm:px-6">
        <div className="relative">
          <section className="px-4" aria-labelledby="contact-heading">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                  <div className="mx-auto py-8 lg:flex lg:items-center lg:justify-between">
                    <div className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                      <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 sm:text-4xl text_theme">
                        Commission
                      </h2>
                    </div>
                  </div>
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg bg-white border-bottom-theme">
                    <main className="relative">
                      <div className="mx-auto">
                        <div className="bg-white rounded-lg shadow overflow-hidden box_bg">
                          {/* <div className="space-y-8 divide-y divide-gray-200">
                            <div className="space-y-8 divide-y divide-gray-200">
                              <div className="pt-1">
                                <div className="pt-1">
                                  <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    {fieldArr.map((field) => {
                                      return (
                                        <div className="sm:col-span-3" key={field.id}>
                                          <Label title={field.title} />
                                          <div className="mt-1">
                                            {field.type === "Dropdown" ? (
                                              <Dropdown
                                                id={field.name}
                                                name={field.name}
                                                handleChange={handleChange}
                                                value={fields[field.name]}
                                                status={field.options || []}
                                              />
                                            ) : null}
                                          </div>
                                          {errors[field.name] ? <Label title={field.title} fieldError={errors[field.name]} /> : null}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> */}
                          {showPrice ?
                            <div className="divide-y divide-gray-200 lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
                              <aside className="py-6 lg:col-span-2">
                                <nav className="space-y-1">
                                  {subNavigation.map((item, index) => (
                                    <Link
                                      to="#"
                                      key={item.name}
                                      onClick={() => handleTabChange(item.type, index)}
                                      onKeyDown={() => setTabMenu(index)}
                                      className={classNames(
                                        item.current
                                          ? "bg-indigo-50 text-indigo-600  hover:bg-teal-50 hover:text-teal-700 border-indigo-600 input_theme"
                                          : "border-transparent text-gray-900 hover:bg-gray-50 hover:text-gray-500 text_theme",
                                        "group border-l-4 px-3 py-2 flex items-center font-medium text-base xl-admin-btn"
                                      )}
                                      aria-current={item.current ? "page" : null}
                                    >
                                      <span className="truncate">
                                        {item.name}
                                      </span>
                                    </Link>
                                  ))}
                                </nav>
                              </aside>

                              {tab === 0 ? <div className="px-6 sm:py-10 col-span-10">
                                <div className="pb-7">
                                  <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Trip Wise</h2>
                                  <p className="mt-1 text-base text-gray-500 text_theme">
                                    This information will be displayed publicly so be careful what you share.
                                  </p>
                                </div>
                                <table className='cus_table ride mb-5'>
                                  <tr>
                                    <th colSpan="3">Rides</th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripRides.map((ride, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{ride.title}</td>
                                      <td>
                                        <input
                                          type="text"
                                          name={ride.vehicle_ride_option_id}
                                          id={ride.vehicle_ride_option_id}
                                          value={ride.value}
                                          onChange={(e) => handleChangeTrip('ride', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <table className='cus_table renatal mb-5'>
                                  <tr>
                                    <th colSpan="3">Rentals</th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripRentals.map((rental, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{rental.hr} Hour / {rental.km} KM</td>
                                      <td>
                                        <input
                                          type="text"
                                          name={rental.rental_price_id}
                                          id={rental.rental_price_id}
                                          value={rental.value}
                                          onChange={(e) => handleChangeTrip('rental', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <table className='cus_table outstation mb-5'>
                                  <tr>
                                    <th colSpan="3">Outstations </th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripOutstations.map((outstation, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{outstation.from_city} to {outstation.to_city}</td>

                                      <td>
                                        <input
                                          type="text"
                                          name={outstation.rental_price_id}
                                          id={outstation.rental_price_id}
                                          value={outstation.value}
                                          onChange={(e) => handleChangeTrip('outstation', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <div className="pt-5 mb-5">
                                  <div className="flex justify-end">
                                    <button
                                      type="button"
                                      onClick={(e) => handleSubmitTrip('trip', e)}
                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div> : ''}
                              {tab === 1 ? <div className="px-6 sm:py-10 col-span-10">
                                <div className="pb-7">
                                  <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Per KM Wise</h2>
                                  <p className="mt-1 text-base text-gray-500 text_theme">
                                    This information will be displayed publicly so be careful what you share.
                                  </p>
                                </div>
                                <table className='cus_table ride mb-5'>
                                  <tr>
                                    <th colSpan="3">Rides</th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripRides.map((ride, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{ride.title}</td>
                                      <td>
                                        <input
                                          type="text"
                                          name={ride.vehicle_ride_option_id}
                                          id={ride.vehicle_ride_option_id}
                                          value={ride.value}
                                          onChange={(e) => handleChangeTrip('ride', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <table className='cus_table renatal mb-5'>
                                  <tr>
                                    <th colSpan="3">Rentals</th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripRentals.map((rental, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{rental.hr} Hour / {rental.km} KM</td>
                                      <td>
                                        <input
                                          type="text"
                                          name={rental.rental_price_id}
                                          id={rental.rental_price_id}
                                          value={rental.value}
                                          onChange={(e) => handleChangeTrip('rental', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <table className='cus_table outstation mb-5'>
                                  <tr>
                                    <th colSpan="3">Outstations </th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripOutstations.map((outstation, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{outstation.from_city} to {outstation.to_city}</td>

                                      <td>
                                        <input
                                          type="text"
                                          name={outstation.rental_price_id}
                                          id={outstation.rental_price_id}
                                          value={outstation.value}
                                          onChange={(e) => handleChangeTrip('outstation', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <div className="pt-5 mb-5">
                                  <div className="flex justify-end">
                                    <button
                                      type="button"
                                      onClick={(e) => handleSubmitTrip('per_km', e)}
                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div> : ''}
                              {tab === 2 ? <div className="px-6 sm:py-10 col-span-10">
                                <div className="pb-7">
                                  <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Per KM Wise</h2>
                                  <p className="mt-1 text-base text-gray-500 text_theme">
                                    This information will be displayed publicly so be careful what you share.
                                  </p>
                                </div>
                                <div className="mb-5 grid grid-cols-2">
                                  <div>
                                    <label htmlFor="app_name" className="block text-base font-medium text-gray-700 text_theme">
                                      Type
                                    </label>
                                    <select
                                      id="country3"
                                      name="country3"
                                      value={fields.driver_earning_type}
                                      onChange={(e) => handleChangeType('driver_earning', 'driver_earning_type', e)}
                                      autoComplete="country3-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      <option value="trip">Trip Wise</option>
                                      <option value="per_km">Per KM </option>
                                    </select>
                                    {errors.app_name ? <Label title='app_name' fieldError={errors.app_name} /> : null}
                                  </div>
                                </div>
                                <table className='cus_table ride mb-5'>
                                  <tr>
                                    <th colSpan="3">Rides</th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>From Earning</th>
                                    <th>To Earning</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripRides.map((ride, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{ride.title}</td>
                                      <td>
                                        <input
                                          type="text"
                                          name={ride.vehicle_ride_option_id}
                                          id={ride.vehicle_ride_option_id}
                                          value={ride.from_earning}
                                          onChange={(e) => handleChangeTrip('ride', index, 'from_earning', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name={ride.vehicle_ride_option_id}
                                          id={ride.vehicle_ride_option_id}
                                          value={ride.to_earning}
                                          onChange={(e) => handleChangeTrip('ride', index, 'to_earning', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name={ride.vehicle_ride_option_id}
                                          id={ride.vehicle_ride_option_id}
                                          value={ride.value}
                                          onChange={(e) => handleChangeTrip('ride', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <table className='cus_table renatal mb-5'>
                                  <tr>
                                    <th colSpan="3">Rentals</th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>From Earning</th>
                                    <th>To Earning</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripRentals.map((rental, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{rental.hr} Hour / {rental.km} KM</td>
                                      <td>
                                        <input
                                          type="text"
                                          name={rental.vehicle_ride_option_id}
                                          id={rental.vehicle_ride_option_id}
                                          value={rental.from_earning}
                                          onChange={(e) => handleChangeTrip('rental', index, 'from_earning', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name={rental.vehicle_ride_option_id}
                                          id={rental.vehicle_ride_option_id}
                                          value={rental.to_earning}
                                          onChange={(e) => handleChangeTrip('rental', index, 'to_earning', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name={rental.rental_price_id}
                                          id={rental.rental_price_id}
                                          value={rental.value}
                                          onChange={(e) => handleChangeTrip('rental', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <table className='cus_table outstation mb-5'>
                                  <tr>
                                    <th colSpan="3">Outstations </th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>From Earning</th>
                                    <th>To Earning</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripOutstations.map((outstation, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{outstation.from_city} to {outstation.to_city}</td>
                                      <td>
                                        <input
                                          type="text"
                                          name={outstation.vehicle_ride_option_id}
                                          id={outstation.vehicle_ride_option_id}
                                          value={outstation.from_earning}
                                          onChange={(e) => handleChangeTrip('outstation', index, 'from_earning', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name={outstation.vehicle_ride_option_id}
                                          id={outstation.vehicle_ride_option_id}
                                          value={outstation.to_earning}
                                          onChange={(e) => handleChangeTrip('outstation', index, 'to_earning', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name={outstation.rental_price_id}
                                          id={outstation.rental_price_id}
                                          value={outstation.value}
                                          onChange={(e) => handleChangeTrip('outstation', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <div className="pt-5 mb-5">
                                  <div className="flex justify-end">
                                    <button
                                      type="button"
                                      onClick={(e) => handleSubmitTrip('driver_earning', e)}
                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div> : ''}
                              {tab === 3 ? <div className="px-6 sm:py-10 col-span-10">
                                <div className="pb-7">
                                  <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Per KM Wise</h2>
                                  <p className="mt-1 text-base text-gray-500 text_theme">
                                    This information will be displayed publicly so be careful what you share.
                                  </p>
                                </div>
                                <div className="mb-5 grid grid-cols-2">
                                  <div>
                                    <label htmlFor="app_name" className="block text-base font-medium text-gray-700 text_theme">
                                      Type
                                    </label>
                                    <select
                                      id="country3"
                                      name="country3"
                                      value={fields.festival_earning_type}
                                      onChange={(e) => handleChangeType('festival_earning', 'festival_earning_type', e)}
                                      autoComplete="country3-name"
                                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-baseinput_theme"
                                    >
                                      <option value="trip">Trip Wise</option>
                                      <option value="per_km">Per KM </option>
                                    </select>
                                    {errors.festival_earning_type ? <Label title='festival_earning_type' fieldError={errors.festival_earning_type} /> : null}
                                  </div>
                                </div>
                                <table className='cus_table ride mb-5'>
                                  <tr>
                                    <th colSpan="3">Rides</th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>Festival</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripRides.map((ride, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{ride.title}</td>
                                      <td>
                                        <Select
                                          className="shadow-sm text-base"
                                          options={areas}
                                          value={ride.festival_area_id || []}
                                          onChange={(e) => handleChangeTrip('ride', index, 'festival_area_id', e)}
                                          labelledBy="Select"
                                          isMulti
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name={ride.vehicle_ride_option_id}
                                          id={ride.vehicle_ride_option_id}
                                          value={ride.value}
                                          onChange={(e) => handleChangeTrip('ride', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <table className='cus_table renatal mb-5'>
                                  <tr>
                                    <th colSpan="3">Rentals</th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>Festival</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripRentals.map((rental, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{rental.hr} Hour / {rental.km} KM</td>
                                      <td>
                                        <Select
                                          className="shadow-sm text-base"
                                          options={areas}
                                          value={rental.festival_area_id || []}
                                          onChange={(e) => handleChangeTrip('rental', index, 'festival_area_id', e)}
                                          labelledBy="Select"
                                          isMulti
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name={rental.rental_price_id}
                                          id={rental.rental_price_id}
                                          value={rental.value}
                                          onChange={(e) => handleChangeTrip('rental', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <table className='cus_table outstation mb-5'>
                                  <tr>
                                    <th colSpan="3">Outstations </th>
                                  </tr>
                                  <tr>
                                    <th>Type</th>
                                    <th>Festival</th>
                                    <th>Commision Value (%) </th>
                                  </tr>
                                  {tripOutstations.map((outstation, index) => {
                                    return (<tr>
                                      <td style={{ width: '200px' }}>{outstation.from_city} to {outstation.to_city}</td>
                                      <td>
                                        <Select
                                          className="shadow-sm text-base"
                                          options={areas}
                                          value={outstation.festival_area_id || []}
                                          onChange={(e) => handleChangeTrip('outstation', index, 'festival_area_id', e)}
                                          labelledBy="Select"
                                          isMulti
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name={outstation.rental_price_id}
                                          id={outstation.rental_price_id}
                                          value={outstation.value}
                                          onChange={(e) => handleChangeTrip('outstation', index, 'value', e)}
                                          className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                        />
                                      </td>
                                    </tr>)
                                  })}
                                </table>
                                <div className="pt-5 mb-5">
                                  <div className="flex justify-end">
                                    <button
                                      type="button"
                                      onClick={(e) => handleSubmitTrip('festival_earning', e)}
                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div> : ''}
                              {tab === 4 ? <div className="px-6 sm:py-10 col-span-10">
                                <div className="pb-7">
                                  <h2 className="text-lg leading-6 font-medium text-gray-900 text_theme">Reward Commision</h2>
                                  <p className="mt-1 text-base text-gray-500 text_theme">
                                    This information will be displayed publicly so be careful what you share.
                                  </p>
                                </div>
                                <div className="mb-5 grid grid-cols-2 gap-6">
                                  <div>
                                    <label htmlFor="app_name" className="block text-base font-medium text-gray-700 text_theme">
                                      Number of Rides
                                    </label>
                                    <input
                                      type="number"
                                      name={setting.reward_no_of_rides}
                                      id={setting.reward_no_of_rides}
                                      value={setting.reward_no_of_rides}
                                      onChange={(e) => handleChangeSetting('reward_no_of_rides', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                  </div>
                                  <div>
                                    <label htmlFor="app_name" className="block text-base font-medium text-gray-700 text_theme">
                                      Commission (%)
                                    </label>
                                    <input
                                      type="number"
                                      name={setting.reward_commission_percentage}
                                      id={setting.reward_commission_percentage}
                                      value={setting.reward_commission_percentage}
                                      onChange={(e) => handleChangeSetting('reward_commission_percentage', e)}
                                      className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-baseborder-gray-300 rounded-md input_theme"
                                    />
                                  </div>
                                </div>
                                <div className="pt-5 mb-5">
                                  <div className="flex justify-end">
                                    <button
                                      type="button"
                                      onClick={(e) => handleSubmitSetting(e)}
                                      className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                      Save
                                    </button>
                                  </div>
                                </div>
                              </div> : ''}

                              {tab === 5 ? <TimeSlot /> : ''}
                            </div> : ''
                          }
                        </div>
                      </div>
                    </main>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}


export default function TabsRender() {
  return (
    <>
      <Tabs color="white" />
    </>
  );
}